import React, { useEffect, useRef } from 'react';

// Ref: https://github.com/christo-pr/dangerously-set-html-content
export default function DangerouslySetHtmlContent({ html, ...props }) {
  const $div = useRef(null);

  useEffect(() => {
    const slotHtml = document.createRange().createContextualFragment(html);
    $div.current.innerHTML = '';
    $div.current.appendChild(slotHtml);
  }, [html, $div.current]);

  return <div {...props} ref={$div} />;
}
