import React from 'react';
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import useTranslation from 'components/hooks/useTranslation';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import { RIGHT_MENU_CLOSE_SUBMENU } from 'components/ui/right-menu/RightMenu';
import bimEventBus from 'BimEventBus';

export default function DashboardItemMenuMoveElement({ item, closeDropdown }) {
  const { t } = useTranslation();
  const moveItem = useDashboardPageCtx.cached(({ moveItem }) => moveItem);
  return (
    <li
      onClick={async () => {
        try {
          bimEventBus.emit(RIGHT_MENU_CLOSE_SUBMENU);
          await moveItem({ itemId: item.id });
          closeDropdown();
        } catch (e) {
          console.error(e);
        }
      }}
    >
      <DashboardItemMenuTitle title={t('select.dashboard.item')} icon="icon-bim-move" more={false} />
    </li>
  );
}
