import './DashLayoutAccordion.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Field, Form, Formik } from 'formik';

import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import Button from 'components/ui/Button';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { BngField } from 'components/bng/form/BngField';
import { BngInput } from 'components/bng/form/BngInput';
import BngImageUpload from 'components/bng/form/BngImageUpload';
import { BngSelect } from 'components/bng/form/BngSelect';
import { BngSlider } from 'components/bng/form/BngSlider';
import BngSwitch from 'components/bng/form/BngSwitch';
import Accordion from 'components/ui/Accordion';
import AccordionList from 'components/ui/AccordionList';
import HelpIcon from 'components/ui/common/HelpIcon';
import { bngYup } from 'components/bng/form/yup/BngYup';
import BngLineInputColor from 'components/bng/form/BngLineInputColor';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import useBimContext from 'components/hooks/useBimContext';
import useTranslation from 'components/hooks/useTranslation';

const HIGHLIGHT_COLORS = ['#6925e8', '#528de3', '#269c59', '#fec447', '#ffffff', '#eb354d'].map((color) => ({
  value: color,
  label: color,
}));

const LAYOUT_DIVISIONS = (context) => {
  return [12, 24, 48, 96].map((d) => ({ value: d, label: `${d} ${context.msg.t('divisions')}` }));
};

const THEMES = (context) => {
  return ['WHITE', 'BLACK', 'CORPORATIVE'].map((theme) => ({
    value: theme,
    label: context.msg.t(`css.background.theme.${theme.toLowerCase()}`),
  }));
};

const DashLayoutSchema = bngYup((yup) => {
  return yup.object().shape({
    backgroundTheme: yup.string().default('WHITE'),
    highlightColor: yup.string().default('#528de3'),
    backgroundImage: yup.mixed().nullable().default(null),
    backgroundColor: yup.string().default(''),
    divisions: yup.number().default(24),
    transparency: yup.number().default(0),
    itemsMargin: yup.boolean().default(true),
    margin: yup.number().min(0).max(10).default(6),
    containerMargin: yup.boolean().default(true),
    containerMarginValue: yup.number().min(0).max(10).default(6),
    itemsShadow: yup.boolean().default(false),
    allowTheme: yup.boolean().default(true),
    borderRadius: yup.boolean().default(true),
    migrateToFreeLayout: yup.boolean().default(false),
  });
});

function dashLayoutInitialValues(dash) {
  const defaultValues = DashLayoutSchema.default();
  if (!dash) {
    return defaultValues;
  }

  const {
    dashboard: { style },
  } = dash;
  return _.merge(defaultValues, {
    backgroundColor: style.background.color,
    backgroundTheme: style.backgroundTheme,
    highlightColor: style.highlightBoxColor.toUpperCase(),
    backgroundImage: style.backgroundImage,
    itemsMargin: style.allowMargin,
    margin: style.margin,
    containerMargin: style.allowContainerMargin,
    containerMarginValue: style.containerMargin,
    borderRadius: style.borderRadius,
    allowTheme: style.allowTheme,
    itemsShadow: style.allowBoxShadow,
    divisions: style.layout.divisions,
    transparency: style.itemTransparency,
  });
}

export function LabelHelper({ label, helpLabel }) {
  return (
    <>
      <span>{label}</span> <HelpIcon title={helpLabel} />
    </>
  );
}

function ThemeAccordion({ theme, setFieldValue, allowTheme }) {
  const context = useBimContext();
  //Handle allowTheme
  useEffect(() => {
    if (allowTheme && theme === 'NONE') {
      setFieldValue('backgroundTheme', 'WHITE');
      setFieldValue('margin', 6);
      setFieldValue('itemsMargin', true);
      setFieldValue('containerMarginValue', 6);
      setFieldValue('containerMargin', true);
      setFieldValue('borderRadius', true);
    }
  }, [allowTheme]);

  //Handle default theme schema
  const handleThemeChange = (event) => {
    switch (event.target.value) {
      case 'WHITE':
        setFieldValue('highlightColor', '#528de3');
        setFieldValue('itemsShadow', false);
        setFieldValue('transparency', 0);
        break;
      case 'BLACK':
        setFieldValue('highlightColor', '#6925e8');
        setFieldValue('itemsShadow', true);
        setFieldValue('transparency', 60);
        break;
      case 'CORPORATIVE':
        setFieldValue('highlightColor', '#6925e8');
        setFieldValue('itemsShadow', false);
        setFieldValue('transparency', 70);
        break;
    }
  };

  return (
    <Accordion title={context.msg.t('theme')} id="ThemeAccordion">
      <Field
        name="backgroundTheme"
        component={BngField}
        withLabel={false}
        inputComponent={BngSelect}
        emptyOption={false}
        options={THEMES(context)}
        onChange={handleThemeChange}
      />
      <Field
        label={context.msg.t('highlight')}
        name="highlightColor"
        component={BngField}
        rootClassName="HighlightColor"
        inputComponent={BngLineInputColor}
        enablePicker={false}
        defaultOptions={[]}
        fetchProjectColors={false}
        addTransparentOptions={false}
        options={HIGHLIGHT_COLORS}
      />
    </Accordion>
  );
}

function BackgroundAccordion() {
  const context = useBimContext();
  return (
    <Accordion startOpen={false} title={context.msg.t('background')} id="BackgroundAccordion">
      <Field
        label={context.msg.t('background.image')}
        name="backgroundImage"
        component={BngField}
        inputComponent={BngImageUpload}
        asProps={{ height: 150, width: '100%' }}
      />
    </Accordion>
  );
}

function ProportionsAccordion({ isFreeLayout, migrateToFreeLayout, setFieldValue, divisions }) {
  const context = useBimContext();
  const disableDivisions = !isFreeLayout && !migrateToFreeLayout;
  const [value, setValue] = useState(divisions);
  return (
    <Accordion startOpen={false} title={context.msg.t('proportions')} id="ProportionsAccordion">
      <label className="control-label" style={{ display: 'flex', alignItems: 'center' }}>
        <LabelHelper helpLabel={context.msg.t('dashboard.divisions.hint')} label={context.msg.t('divisions')} />
      </label>
      <Field
        name="divisions"
        disabled={disableDivisions}
        title={disableDivisions ? context.msg.t('dash.option.available.new.layout') : ''}
        component={BngField}
        withLabel={false}
        emptyOption={false}
        inputComponent={BngSelect}
        options={LAYOUT_DIVISIONS(context)}
        onChange={(event) => setValue(event.target.value)}
        value={value}
      />
      {disableDivisions && (
        <Button
          className="bng-button save"
          style={{ width: '100%', margin: '10px 0 0' }}
          onClick={() => setFieldValue('migrateToFreeLayout', true)}
        >
          {context.msg.t('dash.migrate.layout')}
        </Button>
      )}
    </Accordion>
  );
}

function AppearanceAccordion() {
  const context = useBimContext();
  return (
    <Accordion startOpen={false} title={context.msg.t('appearance')} id="AppearanceAccordion">
      <Field
        name="transparency"
        label={
          <LabelHelper helpLabel={context.msg.t('dashboard.transparency.hint')} label={context.msg.t('transparency')} />
        }
        component={BngField}
        inputComponent={BngSlider}
        rootClassName="TransparencyField"
      />
      <div className="CheckInputOption">
        <Field
          name="itemsMargin"
          label={
            <LabelHelper
              helpLabel={context.msg.t('dashboard.add.margin.hint')}
              label={context.msg.t('dashboard.add.margin')}
            />
          }
          component={BngCheckbox}
        />
        <Field name="margin" type="number" min={0} max={10} component={BngInput} />
      </div>
      <div className="CheckInputOption">
        <Field
          name="containerMargin"
          label={
            <LabelHelper
              context={context}
              helpLabel={context.msg.t('dash.container.margin.hint')}
              label={context.msg.t('dash.container.margin')}
            />
          }
          component={BngCheckbox}
        />
        <Field name="containerMarginValue" type="number" min={0} max={10} component={BngInput} />
      </div>
      <Field
        name="itemsShadow"
        className="DashItemShadow"
        label={
          <LabelHelper
            context={context}
            helpLabel={context.msg.t('dashboard.add.shadow.box.hint')}
            label={context.msg.t('dashboard.add.shadow.box')}
          />
        }
        component={BngCheckbox}
      />
      <Field name="borderRadius" label={context.msg.t('dash.border.rounded')} component={BngCheckbox} />
    </Accordion>
  );
}

function AdvancedAccordion({ allowTheme }) {
  const context = useBimContext();
  return (
    <Accordion title={context.msg.t('advanced')} id="AdvancedAccordion">
      <Field
        name="allowTheme"
        label={allowTheme ? context.msg.t('disable.themes') : context.msg.t('enable.themes')}
        component={BngSwitch}
      />
    </Accordion>
  );
}

function DashboardAccordion() {
  const context = useBimContext();
  return (
    <Accordion title={context.msg.t('dashboard')} id="DashboardAccordion">
      <Field
        label={context.msg.t('background.color')}
        name="backgroundColor"
        component={BngField}
        inputComponent={BngLineInputColor}
        fetchProjectColors={true}
        addTransparentOptions={false}
        showCustom
      />
    </Accordion>
  );
}

export default function DashLayoutAccordion() {
  const { t } = useTranslation();
  const { dash, addChange } = useDashboardPageCtx.cached(({ dash, addChange }) => ({ dash, addChange }));

  const applyDashLayout = async (values, formikProps) => {
    try {
      let submitValues = Object.assign({}, values);

      if (submitValues.backgroundImage && typeof submitValues.backgroundImage !== 'string') {
        const links = await Api.Upload.upload(submitValues.backgroundImage);
        submitValues.backgroundImage = links[0].link;
      }

      await addChange({
        type: 'UPDATE_STYLE',
        data: submitValues,
      });

      UiMsg.ok(t('dash.layout.applied.success'));
    } catch (e) {
      console.error('Error on applyDashLayout()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      formikProps.setSubmitting(false);
    }
  };

  const initialValues = useMemo(() => {
    return dashLayoutInitialValues(dash);
  }, [dash]);

  const isFreeLayout = true; // TODO prop don't exists anymore???? isFreeLayout={!dashboardStyle.layout.freeLayout}

  return (
    <Formik initialValues={initialValues} onSubmit={applyDashLayout} validationSchema={DashLayoutSchema}>
      {({ values, isSubmitting, setFieldValue, submitForm }) => {
        return (
          <Form>
            <AccordionList className="ObjectRightMenuAccordion DashboardLayoutAccordion HasFixedButton">
              {values.allowTheme && (
                <>
                  <ThemeAccordion
                    setFieldValue={setFieldValue}
                    allowTheme={values.allowTheme}
                    theme={values.backgroundTheme}
                    highlightColor={values.highlightColor}
                  />
                  <BackgroundAccordion />
                </>
              )}
              <ProportionsAccordion
                isFreeLayout={isFreeLayout}
                migrateToFreeLayout={values.migrateToFreeLayout}
                setFieldValue={setFieldValue}
                divisions={initialValues.divisions}
              />

              {values.allowTheme && <AppearanceAccordion />}

              {!values.allowTheme && (
                <>
                  <DashboardAccordion />
                  <AdvancedAccordion allowTheme={values.allowTheme} />
                </>
              )}
            </AccordionList>
            <Accordion className="ApplyDashLayout AccordionFixedButton" customTitle={() => null}>
              <Button
                className="bng-button save"
                style={{ margin: 0 }}
                disabled={_.isEqual(values, initialValues)}
                loading={isSubmitting}
                onClick={submitForm}
              >
                {t('apply')}
              </Button>
            </Accordion>
          </Form>
        );
      }}
    </Formik>
  );
}
