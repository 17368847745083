// Css imported on CommonCssImports.js
import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

import useBimContext from 'components/hooks/useBimContext';
import EchartsToolbar from 'components/bng/analysis/echarts/EchartsToolbar';
import EchartsUtils from 'components/bng/analysis/echarts/EchartsUtils';

const tooltipContent = (showDesc, desc, showValue, axisCaptions, captionX, captionY, captionZ) => {
  let descOutput = '';
  if (showDesc) {
    descOutput = `<div class='row-name'>${desc}</div>`;
    if (showValue) descOutput += `<hr/>`;
  }
  if (showValue) {
    let measures = '';
    if (axisCaptions[0]) {
      measures += `<i class='icon-eye-open'></i> ${axisCaptions[0]}: <b class='value'>${captionX}</b>`;
    }
    if (axisCaptions[1]) {
      measures += `<div class='measure'> ${axisCaptions[1]}: <b class='value'> ${captionY} </b></div>`;
    }
    if (axisCaptions[2]) {
      measures += `<div class='measure'> ${axisCaptions[2]}: <b class='value'> ${captionZ} </b></div>`;
    }
    descOutput += `
        <div class='measure'>
        ${measures}
        </div>`;
  }
  return descOutput;
};

export default function BubbleChart({
  className = '',
  queryResult,
  analysisData,
  backgroundTheme,
  toolbarContainer,
  highlightColor,
  hideToolbar = false,
}) {
  const context = useBimContext();

  const [processedResult, setProcessedResult] = useState({ memberArrays: [], axisCaptions: [] });

  const [chartInstance, setChartInstance] = useState(null);
  const [isZoomOutEnabled, setIsZoomOutEnabled] = useState(false);
  const [maxYAxisDataValue, setMaxYAxisDataValue] = useState(0);
  const [marginLeft, setMarginLeft] = useState('0' || 0);
  const [vertLabelGap, setVertLabelGap] = useState(0);

  const chartContainerRef = useRef(null);

  const {
    echartsConfig,
    axisRangeConfig,
    horizAxisLabel,
    vertAxisLabel,
    showDomainAxis,
    showLabel,
    axisFontName,
    axisFontStyle,
    axisTickFontName,
    axisTickFontStyle,
  } = analysisData;

  const showGridLines = echartsConfig.showGridLines;
  const axisPointer = echartsConfig.axis.pointer;
  const axisTickFontSize = echartsConfig.axis.axisTickFontSize;
  const axisFontSize = echartsConfig.axis.axisFontSize;
  const showRangeAxis = echartsConfig.axis.showRange;
  const labelOpacity = echartsConfig.label.opacity;
  const labelShowValue = echartsConfig.label.showValue;
  const labelShowDesc = echartsConfig.label.showDesc;
  const labelFontSize = echartsConfig.label.fontSize ?? 10;
  const labelFontStyle = echartsConfig.label.fontStyle ?? 1;

  const isDarkTheme = backgroundTheme === 'BLACK' || backgroundTheme === 'CORPORATIVE';

  const { fixedSizeDataPoints, minSizeDataPoints, maxSizeDataPoints, sizeDataPoints, itemColor } = echartsConfig.bubble;

  useEffect(() => {
    if (!queryResult) {
      return;
    }

    const { cells, rows, columns } = queryResult.result.data;
    const containsThirdMeasure = columns.nodes.length > 2;
    const distanceBetweenMembers = containsThirdMeasure ? 3 : 2;
    const memberArrays = rows.nodes.map((node, index) => {
      const member = node.caption;
      const baseDistance = index * distanceBetweenMembers;
      const [measureX = null, measureY = null, measureZ = null] = [
        cells[baseDistance]?.value,
        cells[baseDistance + 1]?.value,
        containsThirdMeasure ? cells[baseDistance + 2]?.value : null,
      ];
      const [captionX = null, captionY = null, captionZ = null] = [
        cells[baseDistance]?.caption,
        cells[baseDistance + 1]?.caption,
        containsThirdMeasure ? cells[baseDistance + 2]?.caption : null,
      ];

      if (measureX === undefined || measureY === undefined || (containsThirdMeasure && measureZ === undefined)) {
        return [null, null, null, member, captionX, captionY, captionZ];
      }

      return [measureX, measureY, measureZ, member, captionX, captionY, captionZ];
    });
    const groupedMembers = memberArrays.reduce((acc, member) => {
      const [x, y, z, description, captionX, captionY, captionZ] = member;
      const key = `${x},${y},${z}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(description);
      acc[key].captions = [captionX, captionY, captionZ];
      return acc;
    }, {});
    const groupedMemberArrays = Object.entries(groupedMembers)
      .map(([key, value]) => {
        const [x, y, z] = key.split(',');
        const [captionX, captionY, captionZ] = value.captions;
        return [x, y, z, value.length > 1 ? value : value[0], captionX, captionY, captionZ];
      })
      .filter(([x, y]) => ![x, y].includes('null')); // filters out arrays with null values

    const axisCaptions = columns.nodes.map((node) => {
      return node.caption;
    });

    setProcessedResult({
      memberArrays: groupedMemberArrays.map(([x = null, y = null, z = null, ...rest]) => [
        Number(x),
        Number(y),
        Number(z),
        ...rest,
      ]),
      axisCaptions,
    });
  }, [queryResult]);

  useEffect(() => {
    if (!showRangeAxis) {
      setMarginLeft('8%');
      return;
    }
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.font = `${axisTickFontSize}px ${axisTickFontName}`;
    const textMetrics = ctx.measureText(maxYAxisDataValue.toString());
    const textWidth = textMetrics.width;
    setMarginLeft(Number(textWidth) + 50);
    setVertLabelGap(Number(textWidth) + 20);
  }, [maxYAxisDataValue, showRangeAxis]);

  useEffect(() => {
    const chart = echarts.init(chartContainerRef.current);

    const { memberArrays, axisCaptions } = processedResult;
    const data = [memberArrays];
    const bubbleSizeArray = memberArrays.map((member) => member[2]).filter((v) => Number.isFinite(v));

    // doc: https://echarts.apache.org/en/option.html
    const option = {
      toolbox: {
        feature: {
          dataZoom: {
            show: true,
            title: null,
            icon: null,
          },
        },
      },
      tooltip: {
        confine: true,
        appendToBody: true,
        renderMode: 'html',
        show: showLabel,
        backgroundColor: '#393e42',
        borderColor: '#393e42',
        formatter: function (param) {
          const [x, y, z, description, captionX, captionY, captionZ] = param.data;
          let output = '';
          if (Array.isArray(description)) {
            output += `<div class="mb-2">${description.length} ${context.msg.t('members')}</div>`;
            output += description
              .map(
                (desc, idx) =>
                  `<div class="${description.length !== idx + 1 ? 'mb-3' : ''}">${tooltipContent(
                    labelShowDesc,
                    desc,
                    labelShowValue,
                    axisCaptions,
                    captionX,
                    captionY,
                    captionZ
                  )}</div>`
              )
              .join('');
          } else {
            output = tooltipContent(
              labelShowDesc,
              description,
              labelShowValue,
              axisCaptions,
              captionX,
              captionY,
              captionZ
            );
          }
          return `
            <div class='analysis-title' style="color: white">
              ${output}
            </div>
          `;
        },
        position: EchartsUtils.position,
        axisPointer: {
          type: 'cross',
        },
        textStyle: {
          fontSize: labelFontSize,
          fontWeight: labelFontStyle % 2 === 0 ? 'normal' : 'bold',
          fontStyle: labelFontStyle > 1 ? 'italic' : 'normal',
        },
      },
      backgroundColor: highlightColor
        ? highlightColor
        : backgroundTheme === 'BLACK'
        ? '#242424'
        : backgroundTheme === 'CORPORATIVE'
        ? '#1E1530'
        : '#fff',
      grid: {
        left: marginLeft,
        top: '8%',
        show: showGridLines,
      },
      xAxis: {
        splitLine: {
          show: showGridLines,
          lineStyle: {
            type: 'dashed',
            color: isDarkTheme ? '#565656' : '#ccc',
          },
        },
        splitNumber: 8, // number of split lines
        type: 'value',
        scale: axisRangeConfig.autoAdjust, // when mix and max are not set, scale will be true by default
        name: showDomainAxis ? horizAxisLabel || axisCaptions[0] : '',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: isDarkTheme ? '#fff' : '#323232',
          fontSize: axisFontSize,
          fontWeight: axisFontStyle % 2 === 0 ? 'normal' : 'bold',
          fontStyle: axisFontStyle > 1 ? 'italic' : 'normal',
          fontFamily: axisFontName,
        },
        min: function (value) {
          if (!axisRangeConfig.autoAdjust) return null;
          const maxAbsoluteDataPoint = Math.max(Math.abs(value.min), Math.abs(value.max));
          const offset = maxAbsoluteDataPoint * 0.1;
          return parseFloat((value.min - offset).toFixed(2));
        },
        max: function (value) {
          if (!axisRangeConfig.autoAdjust) return null;
          const maxAbsoluteDataPoint = Math.max(Math.abs(value.min), Math.abs(value.max));
          const offset = maxAbsoluteDataPoint * 0.1;
          return parseFloat((value.max + offset).toFixed(2));
        },
        axisLabel: {
          color: isDarkTheme ? '#fff' : '#323232',
          formatter: function (value) {
            return showDomainAxis ? value : '';
          },
          fontSize: axisTickFontSize,
          fontWeight: axisTickFontStyle % 2 === 0 ? 'normal' : 'bold',
          fontStyle: axisFontStyle > 1 ? 'italic' : 'normal',
          fontFamily: axisTickFontName,
        },
        axisPointer: {
          show: axisPointer, // show axis pointer (line when mouse is hovered around the graph)
        },
        axisTick: {
          show: showDomainAxis,
        },
      },
      yAxis: {
        splitLine: {
          show: showGridLines,
          lineStyle: {
            type: 'dashed',
            color: isDarkTheme ? '#565656' : '#ccc',
          },
        },
        splitNumber: 8, // number of split lines
        type: 'value',
        scale: axisRangeConfig.autoAdjust, // when mix and max are not set, scale will be true by default
        name: showRangeAxis ? vertAxisLabel || axisCaptions[1] : '',
        nameLocation: 'middle',
        nameGap: vertLabelGap,
        nameTextStyle: {
          color: isDarkTheme ? '#fff' : '#323232',
          fontSize: axisFontSize,
          fontWeight: axisFontStyle % 2 === 0 ? 'normal' : 'bold',
          fontStyle: axisFontStyle > 1 ? 'italic' : 'normal',
          fontFamily: axisFontName,
        },
        min: function (value) {
          if (!axisRangeConfig.autoAdjust) return null;
          const maxAbsoluteDataPoint = Math.max(Math.abs(value.min), Math.abs(value.max));
          const offset = maxAbsoluteDataPoint * 0.1;
          return parseFloat((value.min - offset).toFixed(2));
        },
        max: function (value) {
          if (!axisRangeConfig.autoAdjust) return null;
          const maxAbsoluteDataPoint = Math.max(Math.abs(value.min), Math.abs(value.max));
          const offset = maxAbsoluteDataPoint * 0.1;
          if (!maxYAxisDataValue) {
            setMaxYAxisDataValue(parseFloat((maxAbsoluteDataPoint * 1.1).toFixed(2)));
          }
          return parseFloat((value.max + offset).toFixed(2));
        },
        axisLabel: {
          color: isDarkTheme ? '#fff' : '#323232',
          formatter: function (value) {
            return showRangeAxis ? value : '';
          },
          fontSize: axisTickFontSize,
          fontWeight: axisTickFontStyle % 2 === 0 ? 'normal' : 'bold',
          fontStyle: axisFontStyle > 1 ? 'italic' : 'normal',
          fontFamily: axisTickFontName,
        },
        axisPointer: {
          show: axisPointer, // show axis pointer (line when mouse is hovered around the graph)
        },
        axisTick: {
          show: showRangeAxis,
        },
      },
      series: [
        {
          name: null, // name of the series (used for grouping - NOT IMPLEMENTED)
          colorBy: 'series', // color by 'series' (1 color per series) or 'data' (1 color per data point)
          symbol: 'circle', // symbol of the data point ('circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none')
          symbolSize: function (rowData) {
            if (fixedSizeDataPoints || bubbleSizeArray.length === 0) {
              return sizeDataPoints; // default size if no 3rd (z) measure or if fixedSizeDataPoints is true
            }

            // Calculate the mean and standard deviation of the sizes
            const mean = bubbleSizeArray.reduce((a, b) => a + b) / bubbleSizeArray.length;
            const standardDeviation = Math.sqrt(
              bubbleSizeArray.map((x) => (x - mean) ** 2).reduce((a, b) => a + b) / bubbleSizeArray.length
            );

            // Calculate the z-score of the data value
            const z = (rowData[2] - mean) / standardDeviation;

            // Map the z-score to the range of normalized sizes
            return ((z + 3) * (maxSizeDataPoints - minSizeDataPoints)) / 6 + minSizeDataPoints;
          },
          data: data[0],
          type: 'scatter',
          itemStyle: {
            opacity: 1 - labelOpacity / 100,
            color: itemColor, // add gradient support later
          },
        },
      ],
    };

    if (option) {
      chart.setOption(option);
    }

    chart.on('dataZoom', function (values) {
      if (values?.end === 100) {
        setIsZoomOutEnabled(false);
      } else {
        setIsZoomOutEnabled(true);
      }
    });

    setChartInstance(chart);

    return () => {
      try {
        echarts.dispose(chartContainerRef.current);
      } catch (e) {
        console.warn('Error while disposing BubbleChart', { echartsConfig, chartContainerRef }, e);
      }
    };
  }, [
    processedResult,
    analysisData,
    queryResult,
    chartContainerRef.current?.clientWidth,
    chartContainerRef.current?.clientHeight,
    marginLeft,
  ]);

  return (
    <React.Fragment>
      {!hideToolbar && chartInstance !== null && (
        <EchartsToolbar
          chartInstance={chartInstance}
          isZoomOutEnabled={isZoomOutEnabled}
          container={toolbarContainer}
        />
      )}
      <div className={`BubbleChart ${className}`} ref={chartContainerRef} />
    </React.Fragment>
  );
}
