import { Component, memo } from 'react';
import isEqual from 'react-fast-compare';

export { isEqual };

export function componentMemo(component) {
  return memo(component, isEqual);
}

export default class DeepEqualComponent extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }
}
