import { useRef } from 'react';

export default function zustandUseEquals(selector) {
  const prev = useRef();
  return (state) => {
    const next = selector(state);
    if (!_.isEqual(prev.current, next)) {
      prev.current = next;
    }
    return prev.current;
  };
}
