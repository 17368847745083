import React from 'react';
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import Utils from 'components/Utils';
import useTranslation from 'components/hooks/useTranslation';

export default function DashboardItemMenuAccessObject({ item }) {
  const { t } = useTranslation();
  return (
    <li
      onClick={async () => {
        await Utils.Object.openObject(item.path, item.filters);
      }}
    >
      <DashboardItemMenuTitle title={t('access.object')} icon="exit_to_app" more={false} />
    </li>
  );
}
