import ReactDOM from 'react-dom/client';

export const renderReactComponent = (component, container) => {
    const root = container.__renderReactComponentRoot ?? ReactDOM.createRoot(container);
    container.__renderReactComponentRoot = root;
    if(_.isFunction(component)) {
        component = component({root, container});
    }
    root.render(component);
    return root;
}
