import React from 'react';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { PathPermissionDialog } from 'components/bng/permission/PermissionDialog';
import useTranslation from 'components/hooks/useTranslation';
import { MODALS } from 'components/ui/redux/Actions';
import useBimContext from 'components/hooks/useBimContext';

export default function PermissionMenuItem({ path, clearAccordion }) {
  const context = useBimContext();
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();

  return (
    <RightMenuItem
      onClick={() => {
        clearAccordion();
        dispatch(
          MODALS.open(PathPermissionDialog, {
            projectId: context.project.id,
            path,
            useParentPermission: true,
          })
        );
      }}
      className="PermissionMenuItem"
      title={t('permission')}
      icon="lock"
    />
  );
}
