export default {
  PAGE: {
    showFilterBar: true
  },
  COCKPIT: {},
  MOBILE: {},
  PRIVATE_PUBLISHER: {},
  PUBLIC_PUBLISHER: {},
  PRESENTATION: {
    showFilterBar: false
  }
};
