import './BngNewKpiPage.css';
import KPI_TITLE_VALUE from './assets/KPI_TITLE_VALUE.png';
import KPI_VALUE_ONLY from './assets/KPI_VALUE_ONLY.png';
import KPI_VALUE_TITLE_IMAGE from './assets/KPI_VALUE_TITLE_IMAGE.png';
import KPI_VALUE_BACKGROUND from './assets/KPI_VALUE_BACKGROUND.png';
import KPI_JUST_GAGE from './assets/KPI_JUST_GAGE.png';
import KPI_GAUGE from './assets/KPI_GAUGE.png';
import KPI_METER_GAUGE from './assets/KPI_METER_GAUGE.png';
import KPI_PIZZA from './assets/KPI_PIZZA.png';
import KPI_CIRCLE from './assets/KPI_CIRCLE.png';
import KPI_PROGRESS_BAR from './assets/KPI_PROGRESS_BAR.png';

import React, { useState } from 'react';

import AssistedObjectPage, { assistedObjectService } from 'components/bng/pages/common/AssistedObjectPage';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import { bngYup } from 'components/bng/form/yup/BngYup';
import { assistedAnalysisBaseSchema } from 'components/bng/pages/newAnalysis/BngAssistedAnalysisForm';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import BngNewKpiHelp from 'components/bng/pages/kpi/BngNewKpiHelp';

const Images = {
  KPI_TITLE_VALUE,
  KPI_VALUE_ONLY,
  KPI_VALUE_TITLE_IMAGE,
  KPI_VALUE_BACKGROUND,
  KPI_JUST_GAGE,
  KPI_GAUGE,
  KPI_METER_GAUGE,
  KPI_PIZZA,
  KPI_CIRCLE,
  KPI_PROGRESS_BAR,
};

const baseKpiSchema = bngYup((yup) =>
  yup.object({
    folder: yup
      .string()
      .required()
      .transform((val) => (_.isNil(val) ? '' : val)),
    name: yup.string().required().min(1).max(100).trim().validObjectName().default(''),
    color: yup.string().required(),
    measure: yup.string().required(),
    summarizeValues: yup.boolean().required().default(false),
  })
);

const imageSchemaProps = bngYup((yup) => {
  return yup.object({
    type: yup.string().required().default('ICON'),
    value: yup.string().required().default(''),
  });
});

const ValidationSchemas = {
  KPI_TITLE_VALUE: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: baseKpiSchema.shape({
        title: yup.string().ensure().default(''),
        legend: yup.string().ensure().default(''),
      }),
    })
  ),
  KPI_VALUE_ONLY: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: baseKpiSchema.shape({
        legend: yup.string().ensure().default(''),
      }),
    })
  ),
  KPI_VALUE_TITLE_IMAGE: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: baseKpiSchema.shape({
        title: yup.string().ensure().default(''),
        legend: yup.string().ensure().default(''),
        image: imageSchemaProps,
      }),
    })
  ),
  KPI_VALUE_BACKGROUND: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: baseKpiSchema.shape({
        title: yup.string().ensure().default(''),
        legend: yup.string().ensure().default(''),
        image: imageSchemaProps,
      }),
    })
  ),
  KPI_METER_GAUGE: bngYup((yup) => {
    const valueSchema = yup.number().when((val, schema, context) => {
      const parentValues = context.from.find((parentData) => {
        return parentData.value?.hasOwnProperty('bands') ?? false;
      });
      const bands = parentValues?.value.bands ?? [];
      const sourceType = bands[2]?.sourceType ?? '';
      if (sourceType === 'MEASURE') {
        if (context.index === 1) {
          schema = schema.min(0).max(999).required();
        }
      } else {
        schema = schema.nullable();
        if (context.index === 0) {
          const nextVal = bands[1]?.minimumValue;
          if (nextVal && nextVal > 0) {
            schema = schema.max(nextVal);
          }
        } else if (context.index === 1) {
          if (context.path.endsWith('.minimumValue')) {
            const nextVal = bands[1]?.maximumValue;
            if (nextVal && nextVal > 0) {
              schema = schema.max(nextVal);
            }
          } else {
            const nextVal = bands[2]?.maximumValue;
            if (nextVal && nextVal > 0) {
              schema = schema.max(nextVal);
            }
          }
        }
      }
      return schema;
    });
    return assistedAnalysisBaseSchema.shape({
      params: baseKpiSchema.shape({
        title: yup.string().ensure().default(''),
        legend: yup.string().ensure().default(''),
        targetType: yup.string().required(),
        measure: yup.string().when('targetType', (targetType, schema) => {
          return targetType === 'MEASURE' ? schema.required() : schema.nullable();
        }),
        bands: yup.array(
          yup.object({
            order: yup.number().integer().required(),
            minimumValue: valueSchema,
            maximumValue: valueSchema,
            color: yup.string().required(),
          })
        ),
      }),
    });
  }),
  KPI_PIZZA: bngYup((yup) =>
    assistedAnalysisBaseSchema.shape({
      params: baseKpiSchema.shape({
        title: yup.string().ensure().default(''),
        legend: yup.string().ensure().default(''),
        targetType: yup.string().required(),
        targetValue: yup.string().required(),
        targetColor: yup.string().required(),
      }),
    })
  ),
};

['KPI_JUST_GAGE', 'KPI_GAUGE', 'KPI_CIRCLE', 'KPI_PROGRESS_BAR'].forEach(
  (key) => (ValidationSchemas[key] = ValidationSchemas.KPI_PIZZA)
);

// TODO see better way to do this through formik
let editAfterCreate = false;

const KpiPageService = {
  ...assistedObjectService,
  findAssistedTypes: Api.Kpi.assistedTypes,
  createObject: async (values, { embeddedOpts }) => {
    const newObject = await Api.Kpi.createAssistedKpi(values);

    // Embedded on Dashboard
    if (embeddedOpts) {
      await embeddedOpts.afterCreate({
        paths: [newObject.path],
        editAfterCreate,
        newObject,
      });
    } else {
      const queryParams = {
        content: newObject.path,
      };
      if (editAfterCreate) {
        queryParams.edit = editAfterCreate;
      }
      const uri = Api.buildUrl('/load.iface', queryParams);
      window.location.replace(uri);
    }
  },
  updateObject: _.noop,
  findPreviewImages: (selectedType) => Images[selectedType.type],
  findValidationSchema: (selectedType) => {
    return ValidationSchemas[selectedType.type];
  },
  findParams: (selectedType) => selectedType.params,
};

export default function BngNewKpiPage({ className = '', embeddedOpts }) {
  const { msg } = useBimContext();

  const [showIntro, setShowIntro] = useState(null);

  const afterFetch = async () => {
    const watchedNewKpiParam = await Api.UserParam.findKey(Api.UserParam.Keys.Kpi.WatchedNewKpi);
    const showIntro = !watchedNewKpiParam || watchedNewKpiParam.value !== 'true';
    setShowIntro(showIntro);
  };

  return (
    <AssistedObjectPage
      className={`BngNewKpiPage ${className}`}
      headerIcon="speed"
      headerTitle={msg.t('kpi')}
      headerMessage={msg.t('BngNewKpiPage.header.message')}
      service={KpiPageService}
      headerDocLink={msg.t('create.kpi.link')}
      createObjectLabel={msg.t('create.kpi')}
      formBottomSlot={({ formikProps }) => {
        return (
          <div className="flex-center-items jc-center mt-3">
            <BngButton
              variant={Variant.textButton}
              onClick={async () => {
                try {
                  editAfterCreate = true;
                  await formikProps.submitForm();
                } finally {
                  editAfterCreate = false;
                }
              }}
            >
              {msg.t('project.config.advanced.config')}
            </BngButton>
          </div>
        );
      }}
      afterFetch={embeddedOpts ? undefined : afterFetch}
      afterCardsComponent={
        embeddedOpts
          ? undefined
          : () => {
              if (_.isNil(showIntro)) {
                return null;
              }
              return <BngNewKpiHelp showIntro={showIntro} onFinishIntro={() => setShowIntro(false)} />;
            }
      }
      embeddedOpts={embeddedOpts}
    />
  );
}
