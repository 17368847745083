import { Axios } from 'commonUtils';
import bimEventBus from 'BimEventBus';

class MarketplaceApi {
  billingUrl = 'https://billing.bimachine.com';

  constructor() {
    bimEventBus.on('CeDataInitialized', ({ ceData }) => {
      this.billingUrl = ceData.context?.billingConf?.url ?? this.billingUrl;
    });
  }

  fetchMarketplaceInfo = async (accountId, salesObjId, salesObjType) => {
    return await Axios.getData(`/spr/marketplace/${accountId}`, { params: { salesObjId, salesObjType } });
  };

  activateMarketplaceItem = async ({ accountId, items, plan, billingFormat, projectId }) => {
    await Axios.post(`/spr/marketplace/activate`, {
      accountId,
      items,
      billingFormat,
      projectId,
      plan,
    });
  };

  changeItemStatus = async (salesItemKey, salesItemType, projectId, enable, plan, salesItem) => {
    return await Axios.postData(`/spr/marketplace/addons`, {
      salesItemKey,
      salesItemType,
      projectId,
      enable,
      plan,
      salesItem,
    });
  };

  fetchAll = async (accountId) => {
    return await Axios.getData(`/spr/marketplace/${accountId}/all`);
  };

  marketplaceImageUrl = (ref = '') => {
    if (!ref) {
      return '';
    }
    return Axios.getUri({
      url: `${this.billingUrl}/api/uploads/marketplaceImage`,
      params: {
        ref,
      },
    });
  };

  fetchFeaturesForPlan = async (accountId) => {
    return await Axios.getData(`/spr/marketplace/${accountId}/features/plan`);
  };

  changeAccountPlan = async (accountId, plan, isRequest = true) => {
    return await Axios.postData(`/spr/marketplace/${accountId}/changePlan`, { plan, isRequest });
  };
}

export default MarketplaceApi;
