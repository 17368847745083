import React from 'react';

import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import useTranslation from 'components/hooks/useTranslation';
import UiMsg from 'components/ui/UiMsg';

export default function DashboardItemMenuHighlight({ item }) {
  const { t } = useTranslation();

  const addChange = useDashboardPageCtx.cached(({ addChange }) => addChange);
  return (
    <li
      onClick={async () => {
        try {
          await addChange({ type: 'TOGGLE_ITEM_HIGHLIGHT', data: { id: item.id } });
        } catch (e) {
          console.error('Error on itemHighlight()', e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      <DashboardItemMenuTitle
        title={item.highlight ? t('remove.highlight.dashboard.item') : t('highlight.dashboard.item')}
        icon={item.highlight ? 'star' : 'star_border'}
        more={false}
      />
    </li>
  );
}
