import styles from './KpiEditMenu.module.css';

import React, { useEffect, useMemo, useRef } from 'react';
import { Field, Formik } from 'formik';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import useBimContext from 'components/hooks/useBimContext';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import BngButton, { Variant } from 'components/bng/ui/BngButton';
import BngSelectSearch from 'components/bng/form/BngSelectSearch';
import BngField from 'components/bng/form/BngField';
import NewAnalysisService from 'components/bng/pages/newAnalysis/NewAnalysisService';
import UiMsg from 'components/ui/UiMsg';
import BngForm from 'components/bng/form/BngForm';
import { MemberFilterListComponent } from 'components/bng/pages/newAnalysis/paramTypes/MemberFilterList';
import BngLineInputColor from 'components/bng/form/BngLineInputColor';
import BngInputSpinner from 'components/bng/form/BngInputSpinner';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import BngSwitch from 'components/bng/form/BngSwitch';
import BngRadio from 'components/bng/form/BngRadio';
import { BngDoubleField, BngMaskedField, Mask } from 'components/bng/form/BngMaskedField';
import bngYup from 'components/bng/form/yup/BngYup';
import BngInputColor from 'components/bng/form/BngInputColor';
import KpiBandConfig from 'components/bng/pages/newAnalysis/paramTypes/KpiBandConfig';
import { KpiImageComponent } from 'components/bng/pages/newAnalysis/paramTypes/KpiImage';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import KpiMdxPreview from 'components/bng/pages/kpi/KpiMdxPreview';
import BngIconButton from 'components/bng/ui/BngIconButton';
import Kpi, { kpiShouldChangeColor } from 'components/ui/kpi/Kpi';
import useDebounce from 'components/hooks/useDebounce';
import UiBlocker from 'components/bng/ui/UiBlocker';
import BimEventBus from 'BimEventBus';
import DashboardItemInformation from 'components/ui/dashboard/components/DashboardItemInformation';
import { SnackbarVariant } from 'components/ui/common/Snackbar';
import { RIGHT_MENU_CLOSE_SUBMENU } from 'components/ui/right-menu/RightMenu';

export const KPI_EDIT_MENU_EVENT = 'KPI_EDIT_MENU_EVENT';

const FormSchema = bngYup((yup) => {
  const valueSchema = yup.number().when((val, schema, context) => {
    if (context.index === 2 && !_.isNil(context.from?.[1])) {
      const validator = {
        major: false,
        newValue: val,
      };
      context.from[1]?.value?.bands?.forEach((band) => {
        const bandValue = (band.minimumValue || 0) > (band.maximumValue || 0) ? band.minimumValue : band.maximumValue;
        if (bandValue > validator.newValue && bandValue !== 0 && validator.newValue !== 0) {
          validator.major = true;
          validator.newValue = bandValue;
        }
      });
      if (validator.major) {
        schema = schema.min(validator.newValue).required();
      }
      return schema;
    }
    const parentValues = context.from.find((parentData) => {
      return parentData.value?.hasOwnProperty('bands') ?? false;
    });
    const bands = parentValues?.value.bands ?? [];
    const sourceType = bands[2]?.sourceType ?? '';

    if (sourceType === 'MEASURE') {
      if (context.index === 1) {
        schema = schema.min(0).max(999).required();
      }
    } else {
      schema = schema.nullable();
      if (context.index === 0) {
        const nextVal = bands[1]?.minimumValue;
        if (nextVal && nextVal > 0) {
          schema = schema.max(nextVal);
        }
      } else if (context.index === 1) {
        if (context.path.endsWith('.minimumValue')) {
          const nextVal = bands[1]?.maximumValue;
          if (nextVal && nextVal > 0) {
            schema = schema.max(nextVal);
          }
        } else {
          const nextVal = bands[2]?.maximumValue;
          if (nextVal && nextVal > 0) {
            schema = schema.max(nextVal);
          }
        }
      }
    }

    return schema;
  });

  return yup.object({
    datasource: yup.string().required().ensure().default(''),
    measure: yup
      .string()
      .when('customMdx', (val, schema) => {
        return _.isEmpty(val) ? schema.required() : schema.nullable().optional();
      })
      .ensure()
      .default(''),
    customMdx: yup.string().nullable().optional().ensure().default(''),
    model: yup.string().required().ensure().default('DEFAULT'),
    mdxFilters: yup
      .array(
        yup.object({
          dimension: yup.string().ensure().default(''),
          members: yup.array(yup.string().ensure().default('')).ensure().default([]),
        })
      )
      .ensure()
      .default([]),
    title: yup.string().ensure().default(''),
    legend: yup.string().ensure().default(''),
    prefix: yup.string().ensure().default(''),
    suffix: yup.string().ensure().default(''),
    format: yup.string().required().ensure().default('NUMERIC'),
    decimals: yup.number().required().default(0),
    decimalCasesIndicator: yup.number().required().default(0),
    summarizeValues: yup.boolean().required().default(false),
    percentvaluevariation: yup.boolean().required().default(false),

    dynamic: yup.boolean().required().default(false),
    dimension: yup
      .string()
      .when('dynamic', (val, schema) => {
        return val ? schema.required() : schema.nullable().optional();
      })
      .ensure()
      .default(''),
    periodicity: yup
      .string()
      .when('dynamic', (val, schema) => {
        return val ? schema.required() : schema.nullable().optional();
      })
      .ensure()
      .default(''),
    comparePeriodicity: yup.string().nullable().optional().ensure().default(''),
    greaterThanColor: yup
      .string()
      .when('comparePeriodicity', (val, schema) => {
        return val ? schema.required() : schema.nullable().optional();
      })
      .ensure()
      .default('green'),
    lowerThanColor: yup
      .string()
      .when('comparePeriodicity', (val, schema) => {
        return val ? schema.required() : schema.nullable().optional();
      })
      .ensure()
      .default('red'),

    type: yup.string().required().ensure().default('ASCENDING'),
    useBands: yup.boolean().required().default(false),
    targetLabel: yup.string().nullable().optional().ensure().default(''),
    targetColor: yup.string().ensure().default('#00A355'),

    bands: yup
      .array(
        yup.object({
          order: yup.number().integer().required(),
          minimumValue: valueSchema,
          maximumValue: valueSchema,
          color: yup.string().required().ensure().default(''),
          sourceType: yup.string().ensure().default('FIXED'),
          targetMeasure: yup
            .string()
            .ensure()
            .default('')
            .when((_, schema, context) => {
              const parentValues = context.from.find((parentData) => {
                return parentData.value?.hasOwnProperty('bands') ?? false;
              });
              const sourceType = parentValues?.value.bands?.[2]?.sourceType ?? '';
              return sourceType === 'MEASURE' && context.index === 2 ? schema.required() : schema.nullable();
            }),
        })
      )
      .ensure()
      .default(KpiBandConfig.defaultVal()),
    viewBands: yup.boolean().required().default(true),
    targetIsPercent: yup.boolean().required().default(false),

    image: yup
      .object({
        type: yup.string().required().ensure().default('ICON'),
        value: yup.string().nullable().optional().ensure().default(''),
      })
      .default({
        type: 'ICON',
        value: '',
      }),
  });
});

const DEFAULT_FORM_VALUE = Object.freeze(FormSchema.default());

function mapKpiToForm(kpi) {
  const mdxFilters = Object.entries(_.groupBy(kpi.dataOptions.filters, 'dimension')).map(([dimension, filters]) => {
    let filterDimension = dimension;
    if (!filterDimension.startsWith('BIMF')) {
      filterDimension = `BIMF${dimension}`;
    }
    return {
      dimension: filterDimension,
      members: filters.map((f) => {
        if (!f.member.includes(filterDimension)) {
          return f.member.replace(`[${dimension}`, `[${filterDimension}`);
        }
        return f.member;
      }),
    };
  });

  let dimension = kpi.dataOptions.dimension;
  if (dimension && !dimension.startsWith('BIMF')) {
    dimension = `BIMF${dimension}`;
  }

  return _.merge({}, DEFAULT_FORM_VALUE, {
    datasource: kpi.dataOptions.datasourceName,
    measure: kpi.dataOptions.measure,
    customMdx: kpi.customMDX,

    model: kpi.renderOptions.model,
    mdxFilters,
    title: kpi.renderOptions.title,
    legend: kpi.renderOptions.label,
    prefix: kpi.renderOptions.prefix,
    suffix: kpi.renderOptions.suffix,
    format: kpi.dataOptions.valueType,
    decimals: kpi.renderOptions.decimalCases,
    decimalCasesIndicator: kpi.renderOptions.decimalCasesIndicator,
    summarizeValues: kpi.renderOptions.summarizeValues,
    percentvaluevariation: kpi.renderOptions.percentvaluevariation,

    dynamic: kpi.dataOptions.dynamic,
    dimension,
    periodicity: kpi.dataOptions.periodicity,
    comparePeriodicity: kpi.dataOptions.comparePeriodicity,
    greaterThanColor: kpi.renderOptions.greaterThanColor,
    lowerThanColor: kpi.renderOptions.lowerThanColor,

    type: kpi.type,
    useBands: kpi.renderOptions.useBands,
    bands: _.cloneDeep(kpi.renderOptions.bands),
    targetLabel: kpi.renderOptions.targetLabel,
    targetColor: kpi.renderOptions.targetcolor,
    viewBands: kpi.renderOptions.viewBands,
    targetIsPercent: kpi.renderOptions.targetIsPercent,

    image: {
      type: kpi.renderOptions.imageType,
      value: kpi.renderOptions.imageValue,
    },
  });
}

function showImageInput(values = {}) {
  return ['DEFAULT', 'SQUARE'].includes(values.model);
}

function showCompareWith(values = {}) {
  return !['ONLY_VALUE', 'SIMPLE_VALUE', 'PROGRESS_BAR', 'SQUARE'].includes(values.model);
}

function showNegativeVariation(values = {}, modelGroups = []) {
  if (values.format !== 'PERCENTAGE') {
    const match = modelGroups.flatMap((group) => group.models).find((modelData) => modelData.model === values.model);
    if (match) {
      return match.showNegativeVariationOption;
    }
  }
  return false;
}

function showDecimalCasesIndicator(values = {}) {
  if (
    values.format === 'NUMERIC' &&
    !['SIMPLE_VALUE', 'ONLY_VALUE', 'DEFAULT', 'METER_GAUGE', 'SQUARE'].includes(values.model)
  ) {
    return true;
  } else if (values.format === 'PERCENTAGE') {
    return false;
  } else {
    return false;
  }
}

function showDecimal(values = {}) {
  if (values.format === 'NUMERIC') {
    return true;
  } else {
    return false;
  }
}

function showPrefixAndSuffixField(values) {
  if (values.model === 'PROGRESS_BAR') {
    return false;
  }
  return !(
    values.format === 'PERCENTAGE' && ['GAUGE', 'JUST_GAGE', 'METER_GAUGE', 'PIE', 'PIZZA'].includes(values.model)
  );
}

export default function KpiEditMenu({ path = '', showClose = false, dashboardStyle, mutations }) {
  const { msg, project } = useBimContext();
  const dispatch = useReduxDispatch();

  const $formik = useRef();

  const $kpi = useFetchData(
    async ([path]) => {
      if (!path) {
        return null;
      }

      try {
        const kpi = await (mutations ? Api.Kpi.update(path, mutations, true) : Api.Kpi.findOne(path));
        return Object.assign({}, kpi, mutations ?? {});
      } catch (e) {
        UiMsg.ajaxError(null, e);
        throw e;
      }
    },
    [path]
  );

  const $models = useFetchData(async () => (await Api.Kpi.findModels()).groups);

  const $sources = useFetchData(
    async ([projectId]) => {
      const reqParams = { projectId };
      try {
        return await NewAnalysisService.findSources(reqParams);
      } catch (e) {
        UiMsg.ajaxError(null, e);
        throw e;
      }
    },
    [project.id]
  );

  const $periodicities = useFetchData(() => NewAnalysisService.dynamicPeriodicities());

  useEffect(() => {
    const kpi = $kpi.data;
    if (!kpi) {
      return;
    }
    const formValues = mapKpiToForm(kpi);
    $formik.current.setValues(formValues);
  }, [$kpi.data]);

  return (
    <Formik
      validationSchema={FormSchema}
      initialValues={DEFAULT_FORM_VALUE}
      onSubmit={async (values, formikHelpers) => {
        try {
          if (showClose) {
            const kpi = await Api.Kpi.update(path, values);
            bimEventBus.emit(Api.Kpi.KPI_UPDATED_EVENT, kpi);
            $kpi.setData(kpi);
            UiMsg.ok(msg.t('object.save.success'));
          } else {
            // On kpi2.xhtml page just emit event, save is done on KpiRightMenu
            BimEventBus.emit(Api.Kpi.KPI_EDIT_MENU_APPLY_EVENT, {
              mutations: _.cloneDeep(values),
            });
            UiMsg.ok(msg.t('changes.applied.success'), '', { position: 'bottom-center', autoClose: 2000 });
          }
        } catch (e) {
          console.error('Error on KpiEditMenu.submit', { path, values }, e);
          UiMsg.ajaxError(null, e);
        }
      }}
      innerRef={$formik}
    >
      {(formikProps) => {
        const containError = (fields = []) => {
          if (_.isEmpty(fields)) {
            return false;
          }

          return fields.some((fieldName) => {
            const touched = _.get(formikProps.touched, fieldName, false);
            return (touched || formikProps.submitCount > 0) && !_.isNil(_.get(formikProps.errors, fieldName));
          });
        };

        const $sourceFields = useFetchData(
          async ([projectId, sourceName]) => {
            if (_.isEmpty(sourceName)) {
              return [];
            }

            const reqParams = { projectId, sourceName };
            try {
              return await NewAnalysisService.sourceFields(reqParams);
            } catch (e) {
              UiMsg.ajaxError(null, e);
              throw e;
            }
          },
          [project.id, formikProps.values.datasource]
        );

        const measureOpts = useMemo(() => ($sourceFields.data ?? []).filter((f) => f.visible && f.type === 'Measure'), [
          $sourceFields.data,
        ]);

        const timeDimensionOpts = useMemo(
          () =>
            ($sourceFields.data ?? [])
              .filter((f) => !f.visible && f.type === 'TimeDimension')
              .map((f) => ({ ...f, label: f.label.substring(0, f.label.lastIndexOf(' (')) })),
          [$sourceFields.data]
        );
        const [previewData, setPreviewData] = useDebounce(formikProps.values, 1000);
        const isFixedValue = formikProps.values.bands[2].sourceType === 'FIXED';
        const containCustomMdx = !_.isEmpty(formikProps.values.customMdx);
        const showDecimalCasesIndicatorInput = showDecimalCasesIndicator(formikProps.values);

        return (
          <BngForm className="KpiEditMenu">
            <AccordionList
              className="ObjectRightMenuAccordion"
              actionButton={
                <div className="flex-center-items gap-5">
                  {showClose && (
                    <BngButton
                      className="w-100"
                      variant={Variant.textButton}
                      onClick={() => bimEventBus.emit(RIGHT_MENU_CLOSE_SUBMENU)}
                    >
                      {msg.t('close')}
                    </BngButton>
                  )}
                  <BngButton
                    className="w-100 fw-500"
                    onClick={formikProps.submitForm}
                  >
                    {msg.t(showClose ? 'save' : 'apply')}
                  </BngButton>
                </div>
              }
              loading={formikProps.isSubmitting || $kpi.isLoading}
            >
              <Accordion
                id="KpiEditMenuPreview"
                title={msg.t('preview')}
                startOpen={false}
                onOpen={() => {
                  setPreviewData(formikProps.values);
                }}
                sticky
              >
                {({ open }) => {
                  const height = 140;
                  const shouldChangeColor = formikProps.isValid && kpiShouldChangeColor(dashboardStyle);
                  return (
                    <UiBlocker
                      className={`bg-white rounded-lg hide-scrollbar KpiPreviewContainer ${
                        shouldChangeColor ? `DashboardTheme-${dashboardStyle.backgroundTheme}` : ''
                      }`}
                      style={{ height }}
                      block={$kpi.isLoading || _.isNil($kpi.data) || !_.isEqual(formikProps.values, previewData)}
                      renderChildrenWhileBlocking={false}
                    >
                      {open &&
                        (formikProps.isValid ? (
                          <Kpi
                            path={path}
                            mutations={previewData}
                            height={height - 20}
                            dashboardStyle={dashboardStyle}
                          />
                        ) : (
                          <DashboardItemInformation
                            className={styles.previewInvalidData}
                            message={msg.t('kpi.preview.invalid.data')}
                            snackbarVariant={SnackbarVariant.Unloaded}
                            showPlaceholder={false}
                          />
                        ))}
                    </UiBlocker>
                  );
                }}
              </Accordion>

              <Accordion
                id="KpiEditMenuStyle"
                title={msg.t('kpi.style')}
                startOpen={true}
                containError={containError(['model'])}
              >
                <UiBlocker
                  className="bg-white rounded-lg p-3 d-flex flex-direction-column gap-3"
                  block={$models.isLoading}
                  style={{ height: 375 }}
                  renderChildrenWhileBlocking={false}
                >
                  {$models.data?.map((group) => {
                    return (
                      <div key={group.name}>
                        <div className="fw-500">{msg.t(group.name)}</div>
                        <div className="flex-center-items gap-2 mt-2">
                          {group.models.map((model, idx) => {
                            const title = msg.t(model.title);
                            return (
                              <div
                                key={model.type}
                                title={title}
                                className={`${styles.type} ${
                                  model.model === formikProps.values.model ? styles.selected : ''
                                } flex-center-items jc-center pointer`}
                                onClick={() => {
                                  formikProps.setFieldValue('model', model.model);
                                }}
                              >
                                <img src={model.image} alt={`${title} image`} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </UiBlocker>
              </Accordion>

              <Accordion
                id="KpiEditMenuDataConnection"
                title={msg.t('kpi_label_data')}
                startOpen={false}
                containError={containError(['datasource', 'measure', 'mdxFilters', 'customMdx'])}
              >
                <UiBlocker block={$sources.isLoading} style={{ minHeight: 170 }} renderChildrenWhileBlocking={false}>
                  <>
                    <Field
                      name="datasource"
                      label={msg.t('header_menu_in_memory')}
                      component={BngField}
                      inputComponent={BngSelectSearch}
                      options={$sources.data ?? []}
                      defaultPreviewIcon="insert_chart"
                      groupedOpts={true}
                    />

                    {!containCustomMdx && (
                      <>
                        <Field
                          name="measure"
                          label={msg.t('measure')}
                          component={BngField}
                          inputComponent={BngSelectSearch}
                          options={measureOpts}
                          disabled={_.isEmpty(formikProps.values.datasource)}
                          defaultPreviewIcon="123"
                        />

                        <MemberFilterListComponent
                          className="mb-4"
                          name="mdxFilters"
                          label={msg.t('filters')}
                          sourceFields={$sourceFields.data}
                          formikProps={formikProps}
                        />
                      </>
                    )}

                    <div className="flex-center-items gap-3">
                      <BngButton
                        className="w-100"
                        variant={containCustomMdx ? Variant.outline : Variant.textButton}
                        onClick={() => {
                          dispatch(
                            MODALS.open(KpiMdxPreview, {
                              path,
                              values: formikProps.values,
                              onChange: (customMdx) => {
                                formikProps.setFieldValue('customMdx', customMdx ?? '');
                              },
                            })
                          );
                        }}
                      >
                        {containCustomMdx ? `${msg.t('edit')} ${msg.t('mdx')}` : msg.t('see.mdx')}
                      </BngButton>
                      {containCustomMdx && (
                        <BngIconButton
                          icon="close"
                          title={msg.t('restore.default.mdx.kpi')}
                          onClick={() => {
                            formikProps.setFieldValue('customMdx', '');
                          }}
                        />
                      )}
                    </div>
                  </>
                </UiBlocker>
              </Accordion>

              <Accordion
                id="KpiEditMenuConfigs"
                title={msg.t('kpi.configs')}
                startOpen={false}
                containError={containError([
                  'title',
                  'legend',
                  'bands[2].color',
                  'image',
                  'prefix',
                  'suffix',
                  'format',
                  'decimals',
                  'decimalCasesIndicator',
                  'summarizeValues',
                  'percentvaluevariation',
                ])}
              >
                <Field name="title" label={msg.t('title')} component={BngField} />

                <Field name="legend" label={msg.t('legend')} component={BngField} />

                <Field
                  name="bands[2].color"
                  label={msg.t('color')}
                  component={BngField}
                  inputComponent={BngLineInputColor}
                  addTransparentOptions={false}
                  showCustom
                  fetchProjectColors
                />

                {showImageInput(formikProps.values) && (
                  <Field
                    name="image"
                    component={BngField}
                    inputComponent={KpiImageComponent}
                    formikProps={formikProps}
                  />
                )}

                {showPrefixAndSuffixField(formikProps.values) && (
                  <div className="flex-center-items gap-3">
                    <Field name="prefix" label={msg.t('prefix')} component={BngField} rootClassName="w-50" />
                    <Field name="suffix" label={msg.t('suffix')} component={BngField} rootClassName="w-50" />
                  </div>
                )}

                <div className="flex-center-items gap-3">
                  <Field
                    name="format"
                    label={msg.t('format')}
                    component={BngField}
                    inputComponent={BngSelectSearch}
                    options={[
                      { value: 'NUMERIC', label: msg.t('fieldType.numeric') },
                      { value: 'PERCENTAGE', label: msg.t('formatOptions.percent') },
                    ]}
                    rootClassName="w-50"
                    emptyOption={false}
                    clearable={false}
                  />

                  <Field
                    name={showDecimal(formikProps.values) ? 'decimals' : 'decimalCasesIndicator'}
                    label={msg.t(showDecimal(formikProps.values) ? 'decimals' : 'indicator.decimal')}
                    component={BngField}
                    rootClassName="w-50"
                    inputComponent={BngInputSpinner}
                    min={0}
                    max={10}
                    allowNegative={false}
                    disabled={showDecimalCasesIndicatorInput && formikProps.values.summarizeValues}
                  />
                </div>

                <div className="flex-center-items gap-3">
                  <div className="w-50"></div>
                  {showDecimalCasesIndicatorInput && (
                    <Field
                      name="decimalCasesIndicator"
                      label={msg.t('indicator.decimal')}
                      component={BngField}
                      rootClassName="w-50"
                      inputComponent={BngInputSpinner}
                      min={0}
                      max={10}
                      allowNegative={false}
                    />
                  )}
                </div>

                <Field
                  name="summarizeValues"
                  component={BngField}
                  inputComponent={BngCheckbox}
                  withLabel={false}
                  asProps={{
                    label: msg.t('summarize.values'),
                  }}
                  title={msg.t('summarize.values.hint')}
                />

                {showNegativeVariation(formikProps.values, $models.data) && (
                  <Field
                    name="percentvaluevariation"
                    component={BngField}
                    inputComponent={BngCheckbox}
                    withLabel={false}
                    asProps={{
                      label: msg.t('enable.percent.variation'),
                    }}
                  />
                )}
              </Accordion>

              <Accordion
                id="KpiEditMenuDynamicPeriod"
                title={msg.t('dynamic.period')}
                startOpen={false}
                disabled={!formikProps.values.dynamic}
                afterTitle={<Field name="dynamic" component={BngSwitch} className="ml-auto" />}
                containError={containError([
                  'periodicity',
                  'dimension',
                  'comparePeriodicity',
                  'lowerThanColor',
                  'greaterThanColor',
                ])}
              >
                <UiBlocker block={$periodicities.isLoading} renderChildrenWhileBlocking={false}>
                  <>
                    <Field
                      name="periodicity"
                      label={msg.t('in.the.period')}
                      component={BngField}
                      inputComponent={BngSelectSearch}
                      options={$periodicities.data}
                      groupedOpts={true}
                    />

                    <Field
                      name="dimension"
                      label={msg.t('over.the.dimension.of')}
                      component={BngField}
                      inputComponent={BngSelectSearch}
                      options={timeDimensionOpts}
                      defaultPreviewIcon="event"
                    />

                    {showCompareWith(formikProps.values) && (
                      <>
                        <Field
                          name="comparePeriodicity"
                          label={msg.t('compare.periodicity')}
                          component={BngField}
                          inputComponent={BngSelectSearch}
                          options={[
                            {
                              value: '',
                              label: msg.t('dont.compare'),
                            },
                          ].concat($periodicities.data)}
                          groupedOpts={true}
                        />

                        {formikProps.values.comparePeriodicity && (
                          <div className="bg-white rounded-lg p-3 d-flex flex-direction-column gap-3">
                            {[
                              {
                                name: 'lowerThanColor',
                                label: msg.t('lower.than.color.label'),
                              },
                              {
                                name: 'greaterThanColor',
                                label: msg.t('greater.tha.color.label'),
                              },
                            ].map((item) => (
                              <div key={item.name} className="flex-center-items gap-2">
                                <Field name={item.name} component={BngInputColor} addTransparentOptions={false} />
                                <label className="m-0">{item.label}</label>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </>
                </UiBlocker>
              </Accordion>

              <Accordion
                id="KpiEditMenuGoal"
                title={msg.t('goal')}
                startOpen={false}
                containError={containError([
                  'type',
                  'bands[2].sourceType',
                  'bands[2].maximumValue',
                  'targetIsPercent',
                  'bands[2].targetMeasure',
                  'useBands',
                  'targetLabel',
                  'bands[0].color',
                  'bands[1].color',
                  'bands[1].minimumValue',
                  'bands[2].color',
                  'bands[1].maximumValue',
                  'targetColor',
                  'viewBands',
                ])}
              >
                <Field
                  name="type"
                  label={msg.t('kpi.type')}
                  component={BngField}
                  inputComponent={BngSelectSearch}
                  options={[
                    { value: 'ASCENDING', label: msg.t('kpi.type.ASCENDING') },
                    { value: 'DESCENDING', label: msg.t('kpi.type.DESCENDING') },
                  ]}
                  emptyOption={false}
                  clearable={false}
                />

                <div className="flex-center-items gap-5 mb-2 position-relative">
                  {[
                    { value: 'FIXED', label: msg.t('FIXED') },
                    { value: 'MEASURE', label: msg.t('MEASURE') },
                  ].map((opt, idx) => {
                    return (
                      <Field
                        key={opt.value}
                        name="bands[2].sourceType"
                        component={BngRadio}
                        value={opt.value}
                        label={opt.label}
                        onClick={() =>
                          opt.value === 'MEASURE' ? $formik.current.setFieldValue('targetIsPercent', false) : null
                        }
                      />
                    );
                  })}
                </div>

                {isFixedValue ? (
                  <div className="flex-center-items gap-3">
                    <Field
                      name="bands[2].maximumValue"
                      label={msg.t('target.value')}
                      component={BngField}
                      inputComponent={BngDoubleField}
                      rootClassName="w-100"
                      asNumber
                    />
                    <Field
                      name="targetIsPercent"
                      component={BngField}
                      rootClassName="no-wrap"
                      inputComponent={BngCheckbox}
                      label={msg.t('is.percent')}
                    />
                  </div>
                ) : !containCustomMdx ? (
                  <Field
                    name="bands[2].targetMeasure"
                    label={msg.t('measure')}
                    component={BngField}
                    inputComponent={BngSelectSearch}
                    options={measureOpts}
                    disabled={_.isEmpty(formikProps.values.datasource) || containCustomMdx}
                    hint={
                      containCustomMdx
                        ? `${msg.t('title.alert.custom.mdx.kpi')} ${msg.t('kpi.custom.mdx.measure.selection.message')}`
                        : undefined
                    }
                    defaultPreviewIcon="123"
                  />
                ) : null}

                <Field
                  name="useBands"
                  label={msg.t('goal.type')}
                  component={BngField}
                  inputComponent={BngSelectSearch}
                  options={[
                    { value: false, label: msg.t('kpi.target') },
                    { value: true, label: msg.t('bands') },
                  ]}
                  emptyOption={false}
                  clearable={false}
                />

                <Field name="targetLabel" label={msg.t('target.label')} component={BngField} />

                <label>{msg.t('color')}</label>
                <div className="bg-white rounded-lg p-3 d-flex flex-direction-column gap-3">
                  {formikProps.values.useBands ? (
                    <>
                      <div className="flex-center-items gap-2">
                        <Field name={`bands[0].color`} component={BngInputColor} addTransparentOptions={false} />
                        {isFixedValue && (
                          <Field
                            name="bands[0].minimumValue"
                            rootClassName="mb-0 w-100"
                            component={BngField}
                            inputComponent={BngDoubleField}
                            showErrors={false}
                            withLabel={false}
                            asNumber
                          />
                        )}
                      </div>
                      <div className="flex-center-items gap-2">
                        <Field name="bands[1].color" component={BngInputColor} addTransparentOptions={false} />
                        <Field
                          name={`bands[1].minimumValue`}
                          rootClassName="mb-0 w-100"
                          component={BngField}
                          inputComponent={BngMaskedField}
                          showErrors={false}
                          withLabel={false}
                          mask={
                            isFixedValue
                              ? Mask.DOUBLE
                              : {
                                  ...Mask.MEASURE_PERCENT,
                                  suffix: `% ${msg.t('the')} ${msg.t('measure').toLowerCase()}`,
                                  placeholder: `X% ${msg.t('the')} ${msg.t('measure').toLowerCase()}`,
                                }
                          }
                          asNumber
                        />
                      </div>
                      <div className="flex-center-items gap-2">
                        <Field name="bands[2].color" component={BngInputColor} addTransparentOptions={false} />
                        {isFixedValue && (
                          <Field
                            name={`bands[1].maximumValue`}
                            rootClassName="mb-0 w-100"
                            component={BngField}
                            inputComponent={BngDoubleField}
                            showErrors={false}
                            withLabel={false}
                            asNumber
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {[
                        {
                          name: 'bands[2].color',
                          label: msg.t('goal.not.achieved'),
                        },
                        { name: 'targetColor', label: msg.t('goal.achieved') },
                      ].map((item) => (
                        <div key={item.name} className="flex-center-items gap-2">
                          <Field
                            name={item.name}
                            component={BngInputColor}
                            addTransparentOptions={false}
                            {...(item.colorProps ?? {})}
                          />
                          <label className="m-0">{item.label}</label>
                        </div>
                      ))}
                    </>
                  )}
                </div>

                <Field
                  name="viewBands"
                  component={BngField}
                  inputComponent={BngCheckbox}
                  rootClassName="mt-3"
                  withLabel={false}
                  asProps={{
                    label: msg.t('kpi.show.target'),
                  }}
                />
              </Accordion>
            </AccordionList>
          </BngForm>
        );
      }}
    </Formik>
  );
}
