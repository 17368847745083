import React from 'react';
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import useTranslation from 'components/hooks/useTranslation';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

export default function DashboardItemMenuClearItem({ item }) {
  const { t } = useTranslation();
  return (
    <li className={'clear-item'}>
      <DashboardItemMenuTitle
        title={t('restore.default.view')}
        onClick={() => {
          useDashboardPageCtx.getState().updateItemVisualization({
            action: 'CLEAR',
            item,
          });
        }}
        icon="icon-eraser"
      />
    </li>
  );
}
