import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';

import ContextEnhancer from 'components/ContextEnhancer';

import { TYPES } from 'components/ui/cockpit/Cockpit';

import BngPanelItemsButton from 'components/bng/pages/cockpit/duplicated/BngPanelItemsButton';
import BngGlobalFilterButton from 'components/bng/pages/cockpit/duplicated/BngGlobalFilterButton';
import NotesButton from 'components/ui/cockpit/buttons/NotesButton';
import BngPresentationButton from 'components/bng/pages/cockpit/duplicated/BngPresentationButton';
import HideHeaderToolBarItem, { HideHeaderPageOpts } from 'components/ui/breadcrumb-toolbar/HideHeaderToolbarItem';
import ExportButton from 'components/ui/cockpit/buttons/ExportButton';
import BookButton from 'components/ui/cockpit/buttons/BookButton';
import bimEventBus from 'BimEventBus';
import Utils from 'components/Utils';
import Api from 'components/Api';

class BngCockpitButtons extends Component {
  static propTypes = {
    cockpit: PropTypes.object,
    currentPanel: PropTypes.object,
    filters: PropTypes.object,
    onPanelChange: PropTypes.func,
    onFilterChanged: PropTypes.func,
  };

  state = {
    disableExportToPdf: false,
    exportState: undefined,
  };

  componentDidMount() {
    bimEventBus.on('BngCockpitView:panelChanged', this.onPanelChange);
    bimEventBus.on('BigTableApp:onLoadData', this.onBigTableLoadData);
    application.cockpit.scrollBar(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    application.cockpit.scrollBar(true);
  }

  componentWillUnmount() {
    bimEventBus.off('BngCockpitView:panelChanged', this.onPanelChange);
    bimEventBus.off('BigTableApp:onLoadData', this.onBigTableLoadData);
  }

  onBigTableLoadData = ({ disableExportToPdf, ...exportState }) => {
    this.setState({
      disableExportToPdf,
      exportState
    });
  };

  onPanelChange = () => {
    this.setState({ disableExportToPdf: false });
  };

  buildExportFilters = memoizeOne((activeFilters) => {
    return activeFilters.map((filter) => {
      return {
        id: filter.id,
        members: filter.selectedMembers.map((m) => {
          return m.value;
        }),
      };
    });
  });

  render() {
    const { currentPanel, cockpit, onPanelChange } = this.props;

    const exportFilters = this.buildExportFilters(this.props.filters.active);

    return (
      <div id="cockpit-action-buttons" className="cockpit-action-buttons">
        <BngPanelItemsButton panels={cockpit.panels} currentPanelId={currentPanel.id} onPanelChange={onPanelChange} />

        <ExportButton
          activePanel={currentPanel}
          cockpitId={cockpit.id}
          path={currentPanel.path ?? ''}
          filters={exportFilters}
          disableExportToPdf={this.state.disableExportToPdf}
          exportState={this.state.exportState}
          onExcelExport={(e) => {
            e.preventDefault();
            bimEventBus.emit('BngCockpitButtons:ExportToExcel');
          }}
          onPdfExport={
            Utils.Object.isAnalysis(currentPanel.path) || Utils.Object.isDashboard(currentPanel.path)
              ? (e) => {
                  e.preventDefault();
                  bimEventBus.emit('BngCockpitButtons:ExportToPdf');
                }
              : Utils.Object.isOrgmap(currentPanel.path)
              ? (e) => {
                  e.preventDefault();
                  Api.Export.exportCockpitObject({ content: currentPanel.path });
                }
              : undefined
          }
        />

        <HideHeaderToolBarItem location={HideHeaderPageOpts.COCKPIT_TABS} />

        {currentPanel.type === TYPES.PATH_AWARE_ITEM && (
          <React.Fragment>
            <BngGlobalFilterButton
              activeFilters={this.props.filters.active}
              inactiveFilters={this.props.filters.inactive}
              isFiltered={this.props.filters.isFiltered}
              onChangeFilter={this.props.onFilterChanged}
            />
            <NotesButton path={currentPanel.path} />
          </React.Fragment>
        )}

        {this.props.context.permissions.canCreatePresentations() && (
          <BngPresentationButton slides={cockpit.attributes.panelsAsSlides} />
        )}

        <BookButton id={cockpit.id} name={cockpit.name} context={this.props.context} dispatch={this.props.dispatch} />
      </div>
    );
  }
}

export default ContextEnhancer(connect()(BngCockpitButtons));
