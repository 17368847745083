import React from 'react';
import UiMsg from 'components/ui/UiMsg';
import OpConfirmation from 'components/ui/OpConfirmation';
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import useTranslation from 'components/hooks/useTranslation';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

export default function DashboardItemMenuRemove({ item }) {
  const { t } = useTranslation();
  const addChange = useDashboardPageCtx.cached((state) => state.addChange);

  return (
    <li
      onClick={() => {
        OpConfirmation({
          title: t('dashBoard_delete_object'),
          message: t('confirm.delete.dashboard.item'),
          onConfirm: async () => {
            try {
              await addChange({
                type: 'REMOVE_ITEM',
                data: {
                  id: item.id,
                },
              });
            } catch (e) {
              console.error('Error on DashboardItemMenuRemove.remove');
              UiMsg.ajaxError(null, e);
            }
          },
          level: 'warning',
        });
      }}
    >
      <DashboardItemMenuTitle title={t('dashboard_remove_item')} icon="clear" more={false} />
    </li>
  );
}
