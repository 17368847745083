import React from 'react';
import ObjectRightMenu from 'components/ui/right-menu/ObjectRightMenu';
import { ConfigureSchema } from 'components/ui/common/ConfigureAccordion';
import bngYup from 'components/bng/form/yup/BngYup';

export const ValidationSchema = bngYup((yup) => {
  return yup.object().shape({
    objectConfig: ConfigureSchema,
  });
});

export default function DashboardPageRightMenu({
  dashData,
  open = true,
  onSave = _.noop,
  onSaveAs = _.noop,
  onRemove = _.noop,
  onFormikRef = _.noop,
  dirtyCheckFunction = () => false,
}) {
  return (
    <ObjectRightMenu
      open={open}
      path={dashData.dashboardPath}
      folder={dashData.folder}
      description={dashData.description}
      name={dashData.caption}
      validationSchema={ValidationSchema}
      initialValues={dashData.dashboard}
      save={onSave}
      saveAs={onSaveAs}
      remove={onRemove}
      dirtyCheckFunction={dirtyCheckFunction}
      onFormikRef={onFormikRef}
    />
  );
}
