import React, {Component} from "react";
import PropTypes from 'prop-types';

import BiSourceError from "components/ui/map/BiSourceError";
import {NewMapSource} from "components/ui/map/NewMapSource";
import Api from 'components/Api'
import LoadingPlaceholder from "components/ui/loading/LoadingPlaceholder";
import DashboardItemInformation from "components/ui/dashboard/components/DashboardItemInformation";
import ContextEnhancer from "components/ContextEnhancer";
import FilterService from "components/filter/FilterService";
import UiMsg from "components/ui/UiMsg";
import LeafletMap from 'components/ui/map/LeafletMap';

const adaptLegacyMap = (data) => {
    return {
        center: (data.map.positionStart || '0,0').split(',').map(p => parseInt(p)),
        zoomStart: parseInt(data.map.zoomStart),
        description: data.map.description,
        provider: data.map.mapProvider,
        geoJSON: data.geoJSON,
        useCircles: data.map.useCircles,
        legend: data.legend.map(l => ({
            icon: l.icon_path,
            description: l.description,
        }))
    };
};

class BiSourceInternal extends Component {

    static propTypes = {
        onResult: PropTypes.func,
    };

    static defaultProps = {
        mapPath: '',
        filters: [],
        mdxFilter: '',
        mapStyleOverride: {},
        fromCockpit: undefined,
        onResult: _.noop,
    };

    state = {
        loading: true
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.reload(this.props);
    }

    reload = async (props = this.props) => {
        this.setState({loading: true, error: null});
        try {
            await this.fetchData(props);
        } catch (e) {
            let error = e.responseJSON || e.message || e
            if (e.response?.status === 412) {
                error = 'STRUCTURE_NOT_LOADED';
            }
            console.error('Error while trying to fetch map data', props, e);
            this.setState({error});
        }
        this.setState({loading: false});
    };

    componentDidUpdate(prevProps) {
        const picks = ['mapPath','dashboardPath', 'fromCockpit', 'query', 'style', 'mdxFilter', 'filters'];

        const prev = _.pick(prevProps, picks);
        const current = _.pick(this.props, picks);

        if (_.isEqual(prev, current))  {
            return;
        }

        this.reload(this.props);
    }

    isNewMap = () => this.props.mapPath.includes('.newmap');

    render() {
        const {loading, geoJSON, error, mapQuery, mapStyle} = this.state;

        const isNewMap = this.isNewMap();
        const isEmpty = !error && geoJSON && geoJSON.features.length === 0;
        const canRenderMap = !loading && !error && !isEmpty;

        let content = null;

        if (loading) {
            content = (
                <LoadingPlaceholder path={this.props.mapPath} height={this.props.height} width={this.props.width}/>
            );
        } else if (error) {
            if (error.status === 'NO_CONTENT' && error.periodicity) {
                content = (
                    <DashboardItemInformation path={this.props.mapPath}
                                              dashItemId={this.props.dashItemId}
                                              width={this.props.width}
                                              height={this.props.height}
                    />
                );
            } else if (error === 'STRUCTURE_NOT_LOADED') {
                content = (
                    <DashboardItemInformation
                        path={this.props.mapPath}
                        message={this.props.context.msg.t("dashboard.item.not.loaded.message")}
                        width={this.props.width}
                        height={this.props.height}
                        snackbarType='not-loaded'
                        showErrorDialog={null}
                        snackbarIcon='cached'
                    />
                )
            } else {
                content = (
                    <BiSourceError path={this.props.mapPath}
                               reload={this.reload}
                               height={this.props.height}
                                   width={this.props.width}
                               error={error}
                />
                );
            }
        } else if (isEmpty) {
            content = (
                <DashboardItemInformation path={this.props.mapPath} height={this.props.height}
                                          width={this.props.width} dashItemId={this.props.dashItemId}/>
            );
        } else if (canRenderMap) {
            if (isNewMap) {
                content = (
                    <NewMapSource path={this.props.mapPath}
                                  query={mapQuery}
                                  style={{...(mapStyle ?? {}), ...this.props.mapStyleOverride}}
                                  geoJSON={geoJSON}
                                  mobile={this.props.mobile}
                                  isPresentation={this.props.isPresentation}
                                  exportView={this.props.exportView}
                                  height={this.props.height}
                                  width={this.props.width}
                    />
                );
            } else {
                content = (
                  <LeafletMap {...this.state}/>
                );
            }
        }

        return (
            <div className={`map-loader ${loading ? 'updating' : error ? 'map-error' : ''}`}>
                {content}
            </div>
        );
    }

    async fetchData(props) {
        if (this.isNewMap()) {
            let split = props.mapPath.split('/');
            split = split[split.length - 1].split('.')[0];
            const map = await Api.NewMap.findOne({
                id: split,
                path: props.mapPath,
                publisherContent: props.dashboardPath,
                fromCockpit: props.fromCockpit
            });
            const geoJSON = await Api.NewMap.query({
                ...map.query,
                ...FilterService.createFilterParam(this.props.mdxFilter),
                path: map.path,
                publisherContent: props.dashboardPath,
                fromCockpit: props.fromCockpit
            });

            this.setState({
                mapQuery: map.query,
                mapStyle: map.style,
                geoJSON,
                error: geoJSON.error
            }, () => {
                this.props.onResult(geoJSON);
            });

            if (geoJSON.error?.status === 'NO_CONTENT'
                && geoJSON.error?.periodicity
                && !props.dashboardPath) {
                UiMsg.warn(
                    `${this.props.context.msg.t('attention')}!`,
                    this.props.context.msg.t(
                        'invalid.date.filter.alert',
                        [this.props.context.msg.t(geoJSON.error?.periodicity)]
                    )
                );
            }
        } else {
            const data = await jQuery.getJSON('/spr/maps/open', jQuery.param({
                content: props.mapPath,
                filter: props.filters,
                publisherContent: props.dashboardPath,
                fromCockpit: props.fromCockpit
            }, true));

            this.setState(adaptLegacyMap(data));
        }
    }
}

const BiSource = ContextEnhancer(BiSourceInternal);
BiSource.adaptLegacyMap = adaptLegacyMap;

export default BiSource;
