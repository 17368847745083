import './BngAnalysisDrillDownBar.css';

import React, { useEffect, useMemo, useState } from 'react';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import { Axios } from 'commonUtils';
import Api from 'components/Api';
import useEventBus from 'components/hooks/useEventBus';
import bimEventBus from 'BimEventBus';
import BngPortal from 'components/bng/ui/BngPortal';
import UiMsg from 'components/ui/UiMsg';
import useBimContext from 'components/hooks/useBimContext';
import { PUBLISHER_FULL_INTERACTION_EVENT } from 'components/service/bng/PublisherApi';

export const CHART_CLICK_EVENT_NAME = 'Analysis:ChartElementClicked';
export const DEFAULT_DRILL_STATE = Object.freeze({ drills: [] });
export const DEFAULT_DRILL_RESPONSE_STATE = Object.freeze({ drillMembers: [] });
export const CLEAR_FILTER_EVENT = 'FilterBar:Clear';

const HierarchyMemberHandler = {
  Completo: (d) => {
    return `${d} : ${d}`;
  },
  Semestre: (d) => {
    d = d.replaceAll('[', '').replaceAll(']', '');
    const year = Number(d.substring(0, 4));
    const semester = Number(d.substring(d.length - 2));

    const member = `[${year}].[S${semester}]`;
    return `${member} : ${member}`;
  },
  Trimestre: (d) => {
    d = d.replaceAll('[', '').replaceAll(']', '');
    const year = Number(d.substring(0, 4));
    const quarter = Number(d.substring(d.length - 2));
    const semester = quarter > 2 ? 2 : 1;

    const member = `[${year}].[S${semester}].[Q${quarter}]`;
    return `${member} : ${member}`;
  },
  Mes: (d) => {
    d = d.replaceAll('[', '').replaceAll(']', '');
    const year = Number(d.substring(0, 4));
    const month = Number(d.substring(d.length - 2));
    const quarter = Math.ceil(month / 3);
    const semester = month > 6 ? 2 : 1;

    const member = `[${year}].[S${semester}].[Q${quarter}].[M${month}]`;
    return `${member} : ${member}`;
  },
  Semana: (d) => {
    d = d.replaceAll('[', '').replaceAll(']', '');
    const year = Number(d.substring(0, 4));
    const week = Number(d.substring(d.length - 2));
    const date = moment().year(year).isoWeek(week).startOf('isoWeek');

    const buildMember = (date) => {
      const month = date.month() + 1;
      const quarter = date.quarter();
      const semester = month > 6 ? 2 : 1;

      return `[${year}].[S${semester}].[Q${quarter}].[M${month}].[W${week}]`;
    };

    const startMember = buildMember(date);
    const endMember = buildMember(date.endOf('isoWeek'));

    return `${startMember} : ${endMember}`;
  },
  Dia: (d) => {
    return `[Dia].${d} : [Dia].${d}`;
  },
};

export const extractMemberLastPart = (uniqueName = '') => {
  if (!uniqueName.includes('.[')) return uniqueName;

  const lastMemberIdx = uniqueName.indexOf('.[') + 1;
  const memberName = uniqueName.substring(lastMemberIdx);
  if (uniqueName.includes('.(') && uniqueName.includes(')]')) {
    const hierarchy = uniqueName.substring(uniqueName.indexOf('.(') + 2, uniqueName.indexOf(')]'));
    const fn = HierarchyMemberHandler[hierarchy];
    if (fn) {
      return fn(memberName);
    }
  }
  return memberName;
};

/**
 * @typedef {Object} ChartItemMembers
 * @property {string[]} row
 * @property {string[]} col
 */

/**
 * @param encodedData base64 encoded json
 * @return {ChartItemMembers}
 */
export function parseChartItemClickedData(encodedData) {
  if (_.isEmpty(encodedData)) return { row: [], col: [] };

  const decodedData = decodeURIComponent(encodedData);
  return JSON.parse(decodedData);
}

/**
 * @param {MouseEvent} event
 * @param {string} encodedData base64 encoded json
 */
export function chartElementClicked(event, encodedData) {
  const parsedData = parseChartItemClickedData(encodedData);
  bimEventBus.emit(CHART_CLICK_EVENT_NAME, {
    event,
    data: parsedData,
  });
}

const DEFAULT_DRILL_HANDLER = async (drills) => {
  const { data } = await Axios.post('/spr/ui/analysis/interactiveChart', {
    drills,
  });
  await Api.updateJsf();
  return data;
};

const buildMemberData = (dimData, member) => {
  return {
    dimension: dimData.dimension,
    filterId: dimData.filterId,
    member: member ?? '',
  };
};

const copyImgMapAttrs = (svgEl, mapEl, showDrillDownButton, selectedLastLevel, canSelectLastLevel) => {
  svgEl.setAttribute('title', mapEl.getAttribute('title'));
  svgEl.dataset.info = mapEl.dataset.info ?? '';
  const parsedChartData = parseChartItemClickedData(svgEl.dataset.info);
  if (canSelectLastLevel) {
    svgEl.setAttribute('onclick', mapEl.getAttribute('onclick'));
  }

  // Add class to the selected element
  if (selectedLastLevel && parsedChartData.row[0] === selectedLastLevel) {
    svgEl.classList.add('SelectedMember');
  }
};

// See: https://stackoverflow.com/a/17490923
function pointIsInPolygon(point, polygon) {
  let isInside = false;
  let minX = polygon[0].x,
    maxX = polygon[0].x;
  let minY = polygon[0].y,
    maxY = polygon[0].y;
  for (let n = 1; n < polygon.length; n++) {
    const q = polygon[n];
    minX = Math.min(q.x, minX);
    maxX = Math.max(q.x, maxX);
    minY = Math.min(q.y, minY);
    maxY = Math.max(q.y, maxY);
  }

  if (point.x < minX || point.x > maxX || point.y < minY || point.y > maxY) {
    return false;
  }

  let i = 0,
    j = polygon.length - 1;
  for (i, j; i < polygon.length; j = i++) {
    if (
      polygon[i].y > point.y != polygon[j].y > point.y &&
      point.x <
        ((polygon[j].x - polygon[i].x) * (point.y - polygon[i].y)) / (polygon[j].y - polygon[i].y) + polygon[i].x
    ) {
      isInside = !isInside;
    }
  }

  return isInside;
}

const parseAndRoundValue = (value) => Math.floor(parseFloat(value));

const processImageMap = (refElement, showDrillDownButton, selectedLastLevel, canSelectLastLevel, chartType) => {
  let parentElement = refElement?.parentElement;
  for (let i = 0; i < 16; i++) {
    if (!parentElement || parentElement.classList.contains('BngAnalysisDrillDownContainer')) {
      break;
    }
    parentElement = parentElement.parentElement;
  }

  if (!parentElement) {
    return;
  }

  parentElement.querySelector('.FakeImg')?.remove();
  const imageMapEl = parentElement.querySelector('map');
  if (!imageMapEl) {
    return;
  }

  const svgElement = parentElement.querySelector('svg');

  parentElement.classList[selectedLastLevel ? 'add' : 'remove']('onLastLevel');

  let svgChildren;
  Array.from(imageMapEl.childNodes)
    .filter((el) => el.hasAttribute?.('title') || el.hasAttribute?.('onclick'))
    .forEach((areaEl) => {
      const shape = areaEl.getAttribute('shape');
      if (shape !== 'poly') {
        return;
      }

      if (!svgChildren) {
        svgChildren = Array.from(svgElement.querySelectorAll('path')).filter((el) => {
          if (el.parentElement?.tagName?.toLowerCase() === 'g') {
            const rect = el.getBoundingClientRect();
            if (rect.width > 1 && rect.height > 1) {
              return true;
            }
          }
          return false;
        });
      }

      const polygon = areaEl
        .getAttribute('coords')
        .split(',')
        .map((v) => parseAndRoundValue(v))
        .reduce((acc, el, idx) => {
          if ((idx + 1) % 2 === 0) {
            acc[acc.length - 1].y = el;
          } else {
            acc.push({ x: el });
          }
          return acc;
        }, []);

      const { x, y } = polygon[0];

      let closestSvgEl = null;
      let match = false;
      for (const svgEl of svgChildren) {
        const d = svgEl.getAttribute('d');
        const dParts = d.split(' ');
        dParts.shift(); // dicard 'M'

        const point = {
          x: parseAndRoundValue(dParts.shift()),
          y: parseAndRoundValue(dParts.shift()),
        };

        if ((x === point.x && y === point.y) || pointIsInPolygon(point, polygon)) {
          copyImgMapAttrs(svgEl, areaEl, showDrillDownButton, selectedLastLevel, canSelectLastLevel);
          break;
        }

        dParts.shift(); // discard next command
        if (chartType === 'COLUMNS') {
          // Loop throught next x,y pairs until match or got an invalid input (next command)
          while (dParts.length !== 0) {
            point.x = parseAndRoundValue(dParts.shift());
            if (!Number.isFinite(point.x)) {
              break;
            }
            point.y = parseAndRoundValue(dParts.shift());
            if ((x === point.x && y === point.y) || pointIsInPolygon(point, polygon)) {
              match = true;
              copyImgMapAttrs(svgEl, areaEl, showDrillDownButton, selectedLastLevel, canSelectLastLevel);
              break;
            }
          }
          if (match) {
            break;
          }
        } else if (chartType === 'BARS') {
          if (!closestSvgEl) {
            closestSvgEl = {
              svgEl,
              point: { ...point },
            };
          } else {
            const yOldDelta = Math.abs(y - closestSvgEl.point.y);
            const yNewDelta = Math.abs(y - point.y);

            if (yNewDelta < yOldDelta) {
              closestSvgEl = {
                svgEl,
                point: { ...point },
              };
            }
          }
        }
      }

      if (!match && closestSvgEl) {
        copyImgMapAttrs(closestSvgEl.svgEl, areaEl, showDrillDownButton, selectedLastLevel, canSelectLastLevel);
      }
    });

  imageMapEl.remove();
};

export default function BngAnalysisDrillDownBar({
  className = '',
  assisted,
  drillState = DEFAULT_DRILL_STATE,
  drillResponse = DEFAULT_DRILL_RESPONSE_STATE,
  drillHandler = DEFAULT_DRILL_HANDLER,
  chartClickEventFilterRef,
  drillButtonsContainer,
  reprocessImgMapTime = Date.now(),
  analystMenuOpen = false,
  onLoading = _.noop,
  itemId,
}) {
  const { msg } = useBimContext();
  const [loading, setLoading] = useState(false);
  const [$elRef, set$elRef] = useState();

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  const drillHandlerWrapper = useMemo(() => {
    return async (drills, prevDrills, opts = { fromFilterEvent: false, userAction: true }) => {
      try {
        opts = Object.assign({}, { fromFilterEvent: false, userAction: true }, opts ?? {});
        await drillHandler(drills, prevDrills, opts);

        if (opts.userAction) {
          bimEventBus.emit(PUBLISHER_FULL_INTERACTION_EVENT, {
            source: 'BngAnalysisDrillDownBar',
          });
        }
      } catch (e) {
        console.error('Error on drillHandler', { drills, prevDrills }, e);
        UiMsg.ajaxError(msg.t('interactive.chart.operation.error'), e);
      }
    };
  }, [drillHandler]);

  const drills = drillState?.drills ?? [];

  const {
    assistedData: {
      params: { chartType, dimensions },
    },
  } = assisted;

  const showDrillDownButton = dimensions.length > 1 && drills.length < dimensions.length - 1;
  const selectedMemberOnLastLevel = drills.length === dimensions.length ? drills[drills.length - 1].member : null;
  const showDrillUpButton = drills.length > 0;
  const canSelectLastLevel = showDrillDownButton || dimensions[dimensions.length - 1].filterId !== '';

  useEffect(() => {
    processImageMap($elRef, showDrillDownButton, selectedMemberOnLastLevel, canSelectLastLevel, chartType);
  }, [
    reprocessImgMapTime,
    drillResponse,
    $elRef,
    selectedMemberOnLastLevel,
    chartType,
    showDrillDownButton,
    canSelectLastLevel,
  ]);

  useEventBus(
    CHART_CLICK_EVENT_NAME,
    async ({ event, data, itemId: eventItemId }) => {
      const filterLastDimension = dimensions.length - 1 <= drills.length;
      if ((!showDrillDownButton && !filterLastDimension) || loading) return;

      if (eventItemId && itemId) {
        if (eventItemId !== itemId) {
          return;
        }
      } else if (chartClickEventFilterRef) {
        // If contain chartClickEventFilterRef runs the check to handle only events that contain the ref on the
        // composedPath of the event
        const eventPath = event.composedPath ? event.composedPath() : event.path;
        const editingContainer = eventPath.some((el) => {
          if (!el.classList) return false;
          return el.classList.contains('OnContainerCreation') || el.classList.contains('OnGridCreation');
        });

        if (!eventPath.includes(chartClickEventFilterRef) || editingContainer) {
          return;
        }
      }

      setLoading(true);
      try {
        const memberAlreadySelected = selectedMemberOnLastLevel === data.row[data.row.length - 1];

        if (memberAlreadySelected) {
          await drillUp();
        } else {
          const copy = drills.slice();
          // this is for when you have a dimension selected and select another so that it doesn't drill down further
          if (dimensions.length === drills.length) {
            copy.pop();
          }

          const dimData = dimensions[copy.length];
          const member = data.row[data.row.length - 1];
          const prevValue = drills.slice();
          const memberData = buildMemberData(dimData, member);
          copy.push(memberData);
          await drillHandlerWrapper(copy, prevValue);
        }
      } finally {
        setLoading(false);
      }
    },
    [drills, chartClickEventFilterRef, showDrillDownButton, loading, dimensions]
  );

  useEventBus(
    CLEAR_FILTER_EVENT,
    async ({ filters, fromIcon = false }) => {
      const filterIds = filters.map((filter) => filter.id);
      const lastLevelId = drills[drills.length - 1]?.filterId;
      const lastLevelHasBeenCleared = filterIds.includes(lastLevelId);

      setLoading(true);
      try {
        const drillsCopy = fromIcon ? [] : _.cloneDeep(drills);
        for (const filter of filters) {
          const drillMatch = drillsCopy.find((drill) => drill.filterId === filter.id);
          if (drillMatch) {
            drillMatch.member = filter.selectedMembers?.[0]?.value ?? '';
          }
        }

        const opts = {
          fromFilterEvent: !fromIcon,
          userAction: false,
        };
        await drillHandlerWrapper(drillsCopy, fromIcon ? [] : drills, opts);

        if (selectedMemberOnLastLevel && lastLevelHasBeenCleared && !fromIcon) {
          await drillUp(opts);
        }
      } finally {
        setLoading(false);
      }
    },
    [drills]
  );

  const drillUp = async (opts = {}) => {
    const prevValue = drills.slice();
    const copy = drills.slice();
    copy.pop();
    await drillHandlerWrapper(copy, prevValue, opts);
  };

  return (
    <>
      <div className={`BngAnalysisDrillDownBar DrillMembers ${className}`} ref={(ref) => set$elRef(ref)}>
        {drillResponse?.drillMembers?.length > 0 && (
          <div className="Members flex-center-items">
            {drillResponse.drillMembers.map((member, idx) => {
              if (idx + 1 > dimensions.length) return;

              const isLastElement = idx + 1 === drillResponse.drillMembers.length || idx + 1 === dimensions.length;
              return (
                <div key={idx} className="DrillMember">
                  <span>{member.caption}</span>
                  {!isLastElement && <div className="Sep">></div>}
                </div>
              );
            })}
            <BngIconButton
              icon="close"
              size="xs"
              onClick={async () => {
                const prevValue = drills.slice();
                const copy = [];
                bimEventBus.emit(CLEAR_FILTER_EVENT, {
                  filters: [],
                });
                await drillHandlerWrapper(copy, prevValue);
              }}
            />
          </div>
        )}
      </div>

      <BngPortal container={drillButtonsContainer}>
        <div
          className={`BngAnalysisDrillDownBar DrillButtons flex-center-items ${className} ${
            analystMenuOpen ? 'analystMenuOpen' : ''
          }`}
          style={{ gap: '5px' }}
        >
          {showDrillUpButton && (
            <BngIconButton
              icon="arrow_upward"
              onClick={async () => {
                if (loading) return;

                setLoading(true);
                try {
                  await drillUp();
                } finally {
                  setLoading(false);
                }
              }}
            />
          )}
          {showDrillDownButton && (
            <BngIconButton
              icon="arrow_downward"
              onClick={async () => {
                if (loading) return;

                setLoading(true);
                try {
                  const prevValue = drills.slice();
                  const copy = drills.slice();
                  const dimData = dimensions[drills.length];
                  const memberData = buildMemberData(dimData, '');
                  copy.push(memberData);

                  await drillHandlerWrapper(copy, prevValue);
                } finally {
                  setLoading(false);
                }
              }}
            />
          )}
        </div>
      </BngPortal>
    </>
  );
}
