import './FilterBarWrapper.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UiMsg from 'components/ui/UiMsg';
import ContextEnhancer from 'components/ContextEnhancer';
import Icon from 'components/ui/common/Icon';

class FilterBarWrapper extends Component {
    static propTypes = {
        filterPosition: PropTypes.object,
        canSave: PropTypes.bool,
        isPublisher: PropTypes.bool,
        saveFilters: PropTypes.func,
        applyScrollbar: PropTypes.func,
        onChangeVisibility: PropTypes.func,
        onSaveFilters: PropTypes.func,
    };

    static defaultProps = {
        onSaveFilters: _.noop,
    };

    state = {
        contracted: "expand",
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setState({
            contracted: window.__FILTER_ITEMS_WRAPPER_CONTRACTED
                ? window.__FILTER_ITEMS_WRAPPER_CONTRACTED
                : this.props.filterPosition.contracted,
        });
    }

    componentDidUpdate(prevProps) {
        if(prevProps.filterPosition?.contracted !== this.props.filterPosition?.contracted
          && this.props.filterPosition?.contracted !== this.state.contracted) {
            this.setState({
                contracted: this.props.filterPosition?.contracted,
            });
        }
    }

    componentWillUnmount() {
        delete window.__FILTER_ITEMS_WRAPPER_CONTRACTED;
    }

    saveFilters = async () => {
        try {
            await this.props.onSaveFilters()
            UiMsg.ok(this.props.context.msg.t("filters.save.success"));
        } catch (e) {
            console.error("error on save filters", e);
            UiMsg.error(this.props.context.msg.t("filters.save.error"), e);
        }
    };

    getFilterPosition = () => {
        return `${this.props.filterPosition.vertical}-${this.props.filterPosition.horizontal}`;
    };

    changeVisibility = () => {
        const contracted = this.isContracted() ? "expand" : "contracted";
        window.__FILTER_ITEMS_WRAPPER_CONTRACTED = contracted;
        this.props.onChangeVisibility(contracted);
        this.setState({contracted: contracted});
    };

    isContracted = () => {
        return (
            this.state.contracted === "contracted" &&
            this.props.filterPosition.vertical !== "fixed"
        );
    };

    render() {
        const {isPublisher, filterPosition} = this.props;
        const canSave = this.props.canSave && !this.props.context.cockpitEnabled;
        const boxClasses = `dashboard-filter-box filter-${this.getFilterPosition()} ${this.props.context.cockpitEnabled ? 'cockpitEnabled' : ''} ${isPublisher ? 'on-publisher' : ''}`

        return (
            <div className={`FilterBarWrapper ${canSave ? "WithSaveBtn" : ''}`}>
                <div
                    className={`${boxClasses} contracted`}
                    style={{display: this.isContracted() ? "" : "none"}}
                >
                    <div className={`filter-container filter-contracted`}>
                        <div className="dashboard-filter-icon-expand">
                            <i className={`icon icon-bim-menucontracted`}
                               onClick={this.changeVisibility}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${boxClasses} expanded`}
                     style={{display: this.isContracted() ? "none" : ""}}
                >
                    <div className={`filter-container filter-expand ${this.props.onEditorMode} filter-container-position-${filterPosition.horizontal}`}>
                        <div className="dashboard-filter-icon-expand">
                            <i className={`icon icon-bim-menuexpand`}
                               onClick={this.changeVisibility}
                            />
                        </div>
                        {canSave && (
                            <div className="dashboard-filter-save">
                                <button className="btn btn-save-filter btn-white"
                                        title={this.props.context.msg.t(
                                            "save.filters.as.default.title"
                                        )}
                                        onClick={this.saveFilters}
                                >
                                    <Icon icon="save" />
                                </button>
                            </div>
                        )}
                        <div
                            className={`dashboard-filter-container-persistence dashboard-filter-container-persistence-save-${
                                canSave ? "enable" : "disabled"
                            }`}
                        >
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContextEnhancer(FilterBarWrapper);
