import React, { useEffect, useRef } from 'react';

import BngMarkdownEditor from 'components/bng/form/markdown/BngMarkdownEditor';
import { renderReactComponent } from 'bng/utils/ReactUtils';

const MarkdownViewer = ({ className = '', value, onRef = _.noop }) => {
  const tuiRef = useRef();
  useEffect(() => {
    tuiRef.current?.setMarkdown(value);
  }, [value]);

  return (
    <BngMarkdownEditor
      className={`MarkdownViewer ${className}`}
      value={value}
      onTuiRef={(ref) => {
        tuiRef.current = ref;
        onRef(ref);
      }}
      viewer
      height={'auto'}
    />
  );
};

export const processMarkdown = (markdown) => {
  return new Promise((resolve) => {
    const container = document.createElement('div');
    const root = renderReactComponent(
      <MarkdownViewer
        value={markdown}
        onRef={(ref) => {
          const html = ref?.preview?.getHTML();
          root.unmount();
          resolve(html);
        }}
      />,
      container
    );
  });
};

export default MarkdownViewer;
