import './FilterAccordion.css';

import React, { useEffect, useMemo } from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';

import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import ActionList, { ActionListEmpty, ActionTagItem } from 'components/ui/dashboard/components/ActionList';
import BngField from 'components/bng/form/BngField';
import BngSelect from 'components/bng/form/BngSelect';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import bngYup from 'components/bng/form/yup/BngYup';
import ConfigureFilterAccordion, { ConfigureFilterSchema } from 'components/ui/dashboard/ConfigureFilterAccordion';
import Button from 'components/ui/Button';
import Icon from 'components/ui/common/Icon';
import HelpIcon from 'components/ui/common/HelpIcon';
import BngDropdownCheckbox from 'components/bng/ui/BngDropdownCheckbox';
import BngIconButton from 'components/bng/ui/BngIconButton';
import FormikListener from 'components/bng/form/formik/FormikListener';
import useBimContext from 'components/hooks/useBimContext';
import useTranslation from 'components/hooks/useTranslation';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import useProjectFilters from 'components/ui/dashboard/hooks/useProjectFilters';
import UiMsg from 'components/ui/UiMsg';

const filterPositionType = [
  {
    label: 'filter.position.fixed',
    positions: [
      { value: 'filter.top.fixed', label: 'filter.top' },
      { value: 'filter.bottom.fixed', label: 'filter.bottom' },
    ],
  },
  {
    label: 'filter.position.float',
    positions: [
      { value: 'filter.top.left', label: 'filter.top.left' },
      { value: 'filter.top.right', label: 'filter.top.right' },
      { value: 'filter.bottom.left', label: 'filter.bottom.left' },
      { value: 'filter.bottom.right', label: 'filter.bottom.right' },
    ],
  },
];

const filterPositionBoxOpts = (t) => {
  const opts = [];
  for (const type of filterPositionType) {
    for (const position of type.positions) {
      opts.push({
        value: position.value,
        label: `${t(type.label)} - ${t(position.label)}`,
      });
    }
  }
  return opts;
};

const FilterSchema = bngYup((yup) => {
  return yup.object({
    position: yup.string().required().default('filter.top.fixed'),
    startRetracted: yup.boolean().required().default(false),
    filters: yup.array().default([]),
    customFilters: yup
      .array(
        yup.object({
          itemId: yup.string().default(''),
          filters: yup
            .array()
            .of(
              yup.object({
                id: yup.number(),
              })
            )
            .default([]),
        })
      )
      .default([]),
  });
});

function LayoutAccordion({ startOpen = false }) {
  const { values } = useFormikContext();
  const { t } = useTranslation();
  return (
    <>
      <FormikListener
        onChange={(formik) => {
          if (formik.values.position?.includes('fixed') && formik.values.startRetracted) {
            formik.setFieldValue('startRetracted', false);
          }
        }}
      />
      <Accordion title={t('layout')} startOpen={startOpen}>
        <Field
          name="position"
          label={t('position')}
          component={BngField}
          inputComponent={BngSelect}
          options={filterPositionBoxOpts(t)}
          emptyOption={false}
        />
        <Field
          name="startRetracted"
          component={BngField}
          withLabel={false}
          inputComponent={BngCheckbox}
          asProps={{
            label: t('filter.start.retracted'),
            disabled: values.position?.includes('fixed'),
          }}
        />
      </Accordion>
    </>
  );
}

function ListAccordion({ onConfigureFilter = _.noop, onToggleFilter = _.noop, startOpen = false, dataSources = [] }) {
  const { t } = useTranslation();

  const {
    values: { filters = [] },
    setFieldValue,
  } = useFormikContext();

  const { data: projectFilters = [] } = useProjectFilters();

  const buildItem = ({ filter, isSelected, otherActions = [] }) => {
    const projectFilter = projectFilters.find((pf) => pf.id === filter.id);
    let actions = [];
    if (projectFilter && _.intersection(dataSources, projectFilter.datasources).length === 0) {
      actions.push({
        icon: 'warning',
        onClick: _.noop,
        className: `InvalidDatasourceFilter`,
        title: t('filter.without.dashboard.relation'),
      });
    }
    actions = actions.concat(otherActions).concat([
      {
        icon: isSelected ? 'check_box' : 'check_box_outline_blank',
        onClick: () => onToggleFilter(filter),
        className: `CheckboxFilter-${isSelected ? 'Selected' : 'Unselected'}`,
      },
    ]);

    return {
      key: filter.id,
      filterProps: { ...filter },
      description: projectFilter?.caption || projectFilter?.name || filter.id,
      className: !isSelected && 'HideDraggable',
      id: `FilterItem-${filter.id}`,
      actions: actions,
    };
  };

  const buildItems = () => {
    let items = [];

    filters.forEach((filter) => {
      const item = buildItem({
        filter,
        isSelected: true,
        otherActions: [
          {
            icon: 'settings',
            onClick: () => onConfigureFilter(filter.id),
            className: 'ConfigureFilter',
            title: t('filter.configuration.title'),
          },
        ],
      });
      items.push(item);
    });

    for (let filter of _.differenceBy(projectFilters, filters, 'id')) {
      const item = buildItem({
        filter,
        isSelected: false,
      });
      items.push(item);
    }

    return items;
  };

  const onChangeOrder = async (itemsOrdered) => {
    const filtersOrdered = itemsOrdered.map((i) => filters.find((df) => df.id === Number(i.key))).filter((i) => !!i);
    setFieldValue('filters', filtersOrdered);
  };

  return (
    <Accordion id="FilterListAccordion" title={t('filters')} startOpen={startOpen}>
      <ActionList
        className="FilterList LastChildBorderless"
        items={buildItems()}
        draggable={true}
        onChangeOrder={onChangeOrder}
      />
    </Accordion>
  );
}

function CustomFiltersAccordion({
  itemsData = {},
  onRemoveFilter = _.noop,
  onRemoveCustomFilter = _.noop,
  customFilterAdd = null,
}) {
  const context = useBimContext();

  const {
    values: { filters = [], customFilters = [] },
    setFieldValue,
  } = useFormikContext();

  const { data: projectFilters = [] } = useProjectFilters();

  const boundariesElement = useMemo(() => document.querySelector('#page-content, .page-content, .BngAppContent'), []);

  useEffect(() => {
    if (!customFilterAdd) {
      return;
    }

    addCustomItem([customFilterAdd]);
    window.requestAnimationFrame(() => {
      const accordion = document.querySelector('.CustomFiltersAccordion');
      if (accordion) {
        accordion.classList.toggle('AccordionOpen', true);
        accordion.classList.toggle('AccordionClose', false);
      }
    });
  }, [customFilterAdd]);

  const addCustomFilter = (item = {}, customItemFilters = []) => {
    if (customItemFilters.length === 0) return;
    const _customFilters = customFilters.map((cf) => {
      if (cf.itemId !== item.id) return cf;
      return {
        ...cf,
        filters: cf.filters.concat(
          customItemFilters.map((cif) => {
            return { id: cif.key };
          })
        ),
      };
    });
    setFieldValue('customFilters', _customFilters);
  };

  const addCustomItem = (selectedItems = []) => {
    if (selectedItems.length === 0) return;
    const newFilters = selectedItems
      .filter((i) => !customFilters.find((cf) => cf.itemId === (i.key || i.id)))
      .map((i) => {
        return { itemId: i.key || i.id, filters: [] };
      });
    setFieldValue('customFilters', customFilters.concat(newFilters));
  };

  const buildItems = () => {
    let items = [];
    for (let customFilter of customFilters) {
      const item = itemsData[customFilter.itemId];
      if (!item) continue;
      const itemDescription = item.additionalProps.title || item.additionalProps.label || item.additionalProps.value;
      const availableFilters = _.differenceBy(filters, customFilter.filters || [], 'id').map((f) => {
        const projectFilter = projectFilters?.find((pf) => pf.id === f.id);
        return {
          key: f.id,
          label: projectFilter?.caption || projectFilter?.name || f.id,
        };
      });

      items.push({
        key: customFilter.itemId,
        icon: item.icon,
        description: item.caption || itemDescription || '',
        id: `CustomFilters-${customFilter.itemId}`,
        className: `${customFilterAdd && customFilterAdd.id === customFilter.itemId ? 'quick-highlight' : ''}`,
        actions: () => (
          <>
            <BngDropdownCheckbox
              boundariesElement={boundariesElement}
              customButton={({ openDropdown }) => (
                <BngIconButton
                  icon="add"
                  type="button"
                  className="ActionListAdd"
                  title={context.msg.t('add.custom.filter')}
                  onClick={openDropdown}
                />
              )}
              onApply={(data) => addCustomFilter(item, data)}
              items={availableFilters}
            />
            <BngIconButton
              icon="close"
              type="button"
              className="ActionListRemove"
              title="Remover Filtro"
              onClick={() => onRemoveCustomFilter(item)}
            />
          </>
        ),
        tags: customFilter.filters
          .map((f) => projectFilters?.find((ff) => f.id === ff.id))
          .filter((f) => !!f)
          .map((filter) => {
            return {
              onClose: () => onRemoveFilter({ item, filter }),
              className: 'CustomFiltersTag',
              description: filter.caption || filter.name,
              icon: 'icon-bim-filter',
            };
          }),
      });
    }
    return items;
  };

  const availableItems = Object.values(itemsData)
    .filter((i) => !i.isText && !customFilters.find((cf) => i.id === cf.itemId))
    .map((item) => ({ key: item.id, label: item.caption, title: item.additionalProps?.title }));

  return (
    <Accordion
      className="CustomFiltersAccordion"
      startOpen={!!customFilterAdd}
      customTitle={(toggleAccordion) => (
        <div className="AccordionTitle AccordionCustomTitle">
          <Icon className="AccordionIcon" icon="arrow_drop_up" onClick={toggleAccordion} />
          <span className="AccordionDescription" onClick={toggleAccordion}>
            {context.msg.t('custom.filters')}
          </span>
          <a href={context.msg.t('custom.filters.help.link')} target="_blank" rel="noreferrer">
            <HelpIcon title={context.msg.t('custom.filters.help.description')} />
          </a>
        </div>
      )}
    >
      <ActionList
        items={buildItems()}
        className="ActionTagList"
        emptyComponent={() => (
          <ActionListEmpty
            description={context.msg.t('no.custom.filter.added')}
            link={context.msg.t('custom.filters.help.link')}
            linkLabel={context.msg.t('know.more')}
          />
        )}
        customAction={() => (
          <BngDropdownCheckbox
            boundariesElement={boundariesElement}
            customButton={({ openDropdown }) => <BngIconButton icon="add" onClick={openDropdown} />}
            items={availableItems}
            onApply={addCustomItem}
          />
        )}
        ItemComponent={ActionTagItem}
      />
    </Accordion>
  );
}

export default function FilterAccordion({ editFilterId, customFilterAdd, initialFormValues, openAccordion }) {
  const { t } = useTranslation();
  const { dash, addChange } = useDashboardPageCtx.cached(({ dash, addChange }) => ({ dash, addChange }));

  const initialValues = useMemo(() => {
    const { dashboard } = dash;
    const persistedValues = {
      position: dashboard.filterPosition || 'filter.top.fixed',
      startRetracted: dashboard.filterStartRetracted || false,
      filters: dashboard.filters
        .map((filterId) => dashboard.requiredFilters.find((rf) => rf.filterId === filterId))
        .filter((rf) => !!rf)
        .map((rf) => {
          return {
            id: rf.filterId,
            filterLink: rf.filterLink,
            hide: rf.hide,
            fixed: rf.fixedFilter,
            required: rf.enabled,
            periodRestriction: rf.timeFilterRestriction,
            restrict: {
              restrictionType: rf.restrictionType,
              members: (rf.members || []).slice(),
              enabled: rf.restrictMembers,
            },
          };
        }),
      customFilters: dashboard.dashboardItems
        .filter((di) => di.restrictFilters)
        .map((di) => ({
          itemId: di.id,
          filters: di.filters.map((filterId) => ({
            id: filterId,
          })),
        })),
    };

    return _.cloneDeep(initialFormValues ?? persistedValues);
  }, [dash]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FilterSchema}
      onSubmit={async (values, formikHelpers) => {
        try {
          await addChange({
            type: 'UPDATE_FILTERS',
            data: values,
          });
        } catch (e) {
          console.error(e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      {({ values, setFieldValue, isSubmitting, submitForm }) => {
        useEffect(() => {
          if (editFilterId) {
            const idx = values.filters.findIndex((f) => f.id === editFilterId);
            if (idx !== -1) {
              onConfigureFilter(editFilterId);
            }
          }
        }, [editFilterId]);

        const onConfigureFilter = async (filterId) => {
          const filterIdx = values.filters.findIndex((f) => f.id === filterId);
          openAccordion(ConfigureFilterAccordion, FilterAccordion.MENU_KEY, {
            initialValues: _.cloneDeep(values.filters[filterIdx]),
            onGoBack: () => {
              openAccordion(FilterAccordion, FilterAccordion.MENU_KEY, {
                initialFormValues: values,
              });
            },
            onApply: async (newFilter) => {
              values.filters[filterIdx] = newFilter;
              await submitForm();
            },
          });
        };

        return (
          <Form>
            <AccordionList className="ObjectRightMenuAccordion FilterAccordion HasFixedButton" loading={isSubmitting}>
              <LayoutAccordion startOpen={!customFilterAdd} />
              <ListAccordion
                onToggleFilter={(filter = {}) => {
                  const partialFilters = values.filters.filter((f) => f.id !== filter.id);
                  if (partialFilters.length === values.filters.length) {
                    filter = _.merge(ConfigureFilterSchema.default(), filter);
                    partialFilters.push(filter);
                  }
                  setFieldValue('filters', partialFilters);
                }}
                onConfigureFilter={onConfigureFilter}
                dataSources={dash?.relatedDatasources}
                startOpen={!customFilterAdd}
              />
              <CustomFiltersAccordion
                onRemoveFilter={({ item = {}, filter = {} }) => {
                  const customFilters = values.customFilters.map((cf) => {
                    if (cf.itemId !== item.id) return cf;
                    return {
                      ...cf,
                      filters: cf.filters.filter((cf) => cf.id !== filter.id),
                    };
                  });
                  setFieldValue('customFilters', customFilters);
                }}
                onRemoveCustomFilter={(item = {}) => {
                  const customFilters = values.customFilters.filter((cf) => cf.itemId !== item.id);
                  setFieldValue('customFilters', customFilters);
                }}
                itemsData={dash?.gridData?.itemsData ?? {}}
                customFilterAdd={customFilterAdd}
              />
            </AccordionList>

            <Accordion className="ApplyFilterConfig AccordionFixedButton" customTitle={() => null}>
              <Button className="bng-button save m-0" loading={isSubmitting} onClick={submitForm}>
                {t('apply')}
              </Button>
            </Accordion>
          </Form>
        );
      }}
    </Formik>
  );
}

FilterAccordion.MENU_KEY = 'FiltersMenuItem';
