import "./CreateObjectMenuButton.css";

import React from "react";
import {connect} from "react-redux";

import {MODALS} from "components/ui/redux/Actions";
import ContextEnhancer from "components/ContextEnhancer";
import MenuButton from "components/ui/navbar/MenuButton";
import CreateObjectMenuLink from "components/ui/navbar/CreateObjectMenuLink";
import SelectOriginDialog from "components/ui/common/SelectOriginDialog";
import Utils from 'components/Utils'
import Api from 'components/Api';

const openLink = async (context, link) => {
  if (context.cockpitEnabled) {
    await Api.Bng.loadProjectInfo(context.project.name, true);
  }
  window.location.replace(`${Api.baseUrl()}${link}`);
};

const menuOpts = (context, dispatch) => {
    const itemsLeft = [];
    const itemsRight = [];
    const onProjectDiscover = context.isAccessingProject && !context.cockpitEnabled;
    const perm = context.permissions;
    if (onProjectDiscover) {
        if (perm.canCreateObjects()) {
            itemsLeft.push({
              icon: Utils.Object.getObjectIcon('cockpit'),
              iconOutlined: true,
              title: context.msg.t('create.cockpit'),
              type: 'cockpit',
              label: context.msg.t('description.cockpit'),
              linkhelp: context.msg.t('help.link.cockpits'),
              linkhelpinformation: context.msg.t('create.object.link.help'),
              onClick: () => openLink(context, '/spr/bng/project/cockpits'),
            });

            itemsLeft.push({
              icon: Utils.Object.getObjectIcon('dashboard'),
              iconOutlined: true,
              title: context.msg.t('create.dashboard'),
              type: 'dash',
              label: context.msg.t('description.dashboard'),
              linkhelp: context.msg.t('help.link.dashboard'),
              linkhelpinformation: context.msg.t('create.object.link.help'),
              onClick: async () => {
                  await openLink(context, '/spr/bng/dashboard/new');
              },
            });

            itemsLeft.push({
                icon: Utils.Object.getObjectIcon('analysis', 'showChart'),
                title: context.msg.t('create.analysis'),
                type: 'analysis',
                label: context.msg.t('description.analysis'),
                linkhelp: context.msg.t('help.link.analysis'),
                linkhelpinformation: context.msg.t('create.object.link.help'),
                onClick: () => openLink(context, '/newanalysis.iface'),
                isNew: true
            });

            itemsRight.push({
                icon: Utils.Object.getObjectIcon('kpi'),
                title: context.msg.t('create.kpi'),
                type: 'kpi',
                label: context.msg.t('description.kpi'),
                linkhelp: context.msg.t('help.link.kpi'),
                linkhelpinformation: context.msg.t('create.object.link.help'),
                onClick: () => openLink(context, '/pages/kpi/new-kpi.iface')
            });

            itemsRight.push({
                icon: Utils.Object.getObjectIcon('newmap'),
                iconOutlined: true,
                title: context.msg.t('create.map.discover'),
                type: 'map',
                label: context.msg.t('description.map.discover'),
                linkhelp: context.msg.t('help.link.maps'),
                linkhelpinformation: context.msg.t('create.object.link.help'),
                beta: false,
                onClick: () => {
                    dispatch(
                        MODALS.open(SelectOriginDialog, {
                            async onSubmit(data) {
                                window.location = `${context.contextPath}/spr/bng/newmap?${j.param(data)}`;
                            }
                        })
                    );
                    MenuButton.closeAllMenus();
                }
            });

            itemsRight.push({
                icon: Utils.Object.getObjectIcon('bigtable'),
                title: context.msg.t('create.bigTable'),
                type: 'bigtable',
                label: context.msg.t('description.bigTable'),
                linkhelp: context.msg.t('big.table.support.link'),
                linkhelpinformation: context.msg.t('create.object.link.help'),
                onClick: () => openLink(context, '/newanalysis.iface?analysisType=BIG_TABLE'),
                isNew: true,
                isNewLabel: context.msg.t('updated'),
            });
        }
    }
    return {itemsLeft, itemsRight}
};

class CreateObjectMenuButton extends React.Component {

    render() {
        let userMenu = menuOpts(this.props.context, this.props.dispatch);

        return (
            <MenuButton className={'CreateObjectMenuButton'}
                        icon="add_circle"
                        title={this.props.context.msg.t('object.create.title')}>

                <li className="create-object-itens-container">
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <div className="create-object-itens-container-div">
                                    {userMenu.itemsLeft.map((menuItem, i) => {
                                        return <CreateObjectMenuLink key={i} {...menuItem}/>
                                    })}
                                </div>
                            </td>
                            <td>
                                <div className="create-object-itens-container-div">
                                    {userMenu.itemsRight.map((menuItem, i) => {
                                        return <CreateObjectMenuLink key={i} {...menuItem}/>
                                    })}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </li>
            </MenuButton>
        );
    }
}

export default ContextEnhancer(
    connect(
        (state) => ({}),
        dispatch => ({dispatch})
    )(CreateObjectMenuButton)
)
