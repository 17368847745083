const MATCHER = /{(.*?)}/g;

export default class I18n {
  initialized = false;

  constructor(translations) {
    this.translations = translations;
  }

  initialize() {
    if (this.translations.hasOwnProperty('yup.mixed.required')) {
      this.initialized = true;
      return;
    }
    const { bngYupLang } = require('components/bng/form/yup/BngYup');
    bngYupLang((lang) => {
      for (const rootKey of Object.keys(lang)) {
        const root = lang[rootKey];
        for (const key of Object.keys(root)) {
          const val = root[key];
          if (_.isString(val)) {
            this.translations[`yup.${rootKey}.${key}`] = root[key];
          }
        }
      }
    });
    this.initialized = true;
  }

  t(key, params) {
    if (!this.initialized) {
      this.initialize();
    }
    if (this.containKey(key)) {
      let msg = this.translations[key];
      if (!_.isNil(params)) {
        let result;
        const isArray = _.isArray(params);
        while ((result = MATCHER.exec(msg)) !== null) {
          let idx = parseInt(result[1]);
          msg = msg.replace(result[0], isArray ? params[idx] : params);
        }
      }
      return msg;
    }
    return key;
  }

  containKey(key) {
    return this.translations.hasOwnProperty(key);
  }

  translateIfHasKey(key, params) {
    return this.containKey(key) ? this.t(key, params) : null;
  }
}
