import './DashInputImage.css';
import React from 'react';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import useTranslation from 'components/hooks/useTranslation';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import UUID from 'bng/utils/UUID';

export default function DashInputImage({ item = { order: 0 }, startUpload = _.noop, finishUpload = _.noop, ...props }) {
  const { t } = useTranslation();
  const addChange = useDashboardPageCtx.cached((state) => state.addChange);

  const save = async ({ file, width, height }) => {
    try {
      const form = {
        id: item.id || UUID.generate(),
        hashCode: item.order,
        width: width,
        height: height,
        screen: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      };
      startUpload();
      const uploadResult = await Api.Upload.upload(file);
      form.image = uploadResult[0].path;
      await addChange({
        type: 'IMAGE',
        data: form,
      });
      UiMsg.ok(t('changes.applied.successfully'));
    } catch (e) {
      console.error('Error on save()', e);
      UiMsg.ajaxError(null, e);
    } finally {
      finishUpload();
    }
  };

  const handleFile = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (!file.type.includes('image')) {
      UiMsg.error(t('only.image.support'));
      return;
    }
    const img = new Image();
    img.onload = function () {
      save({ file, width: this.width, height: this.height });
    };
    img.src = (window.URL || window.webkitURL).createObjectURL(file);
  };

  return <input id={`add-dashboard-image ${item.order}`} type="file" accept="image/*" onChange={handleFile} />;
}
