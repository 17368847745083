import React from 'react';
import useTranslation from 'components/hooks/useTranslation';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import UiMsg from 'components/ui/UiMsg';

export default function DashboardItemMenuBackgroundTransparency({ item }) {
  const { t } = useTranslation();
  const addChange = useDashboardPageCtx.cached(({ addChange }) => addChange);
  return (
    <li
      className="sub-container-dash-item-newmenu-title"
      onClick={async () => {
        try {
          await addChange({
            type: 'CHANGE_ITEM_TRANSPARENCY',
            data: {
              id: item.id,
              transparency: !item.transparentbackground,
            },
          });
        } catch (e) {
          console.error(e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      <label>
        <input type="checkbox" checked={item.transparentbackground} className="ace" />
        <span className="lbl">{t('background.transparency')}</span>
      </label>
    </li>
  );
}
