import './GlobalLoader.css';

import { create } from 'zustand';

import LoadingPulse from 'components/ui/loading/LoadingPulse';
import UUID from 'bng/utils/UUID';

export const useGlobalLoader = create((set, get) => {
  return {
    queue: {},
    isLoading() {
      return Object.keys(get().queue).length > 0;
    },
    async runTask(task = _.noop) {
      const id = UUID.generate();

      set((state) => {
        return {
          queue: {
            ...state.queue,
            [id]: task,
          },
        };
      });

      try {
        return await task();
      } finally {
        set((state) => {
          const queue = { ...state.queue };
          delete queue[id];
          return { queue };
        });
      }
    },
  };
});

export default function GlobalLoader() {
  const $store = useGlobalLoader();

  if (!$store.isLoading()) {
    return null;
  }

  return <LoadingPulse className="GlobalLoader" />;
}
