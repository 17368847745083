import './LoadingPulse.css';
import React from 'react';

export default function LoadingPulse({ className = '', loaderType = 'pulse' }) {
  let loader;
  switch (loaderType) {
    case 'circle': {
      loader = (
        <svg className="spinner" width="100px" height="100px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="1.5" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
      );
      break;
    }
    default: {
      loader = <div className="loading-pulse"></div>;
      break;
    }
  }

  return <div className={`loading-pulse-box Type-${loaderType} ${className}`}>{loader}</div>;
}
