import './MobileMenu.css';

import React, { useEffect, useState } from 'react';

import Api from 'components/Api';
import Accordion from 'components/ui/Accordion';
import ActionList from 'components/ui/dashboard/components/ActionList';
import UiMsg from 'components/ui/UiMsg';
import MobileOrientationSwitch from 'components/ui/dashboard/components/MobileOrientationSwitch';
import BngSwitch from 'components/bng/form/BngSwitch';
import AccordionList from 'components/ui/AccordionList';
import { selectItem } from 'components/ui/dashboard/components/common';
import BngMobileDrawerHelp from 'components/bng/pages/dashboard/mobileDrawer/BngMobileDrawerHelp';
import useBimContext from 'components/hooks/useBimContext';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import useAsyncEffect from 'components/hooks/useAsyncEffect';
import OpConfirmation from 'components/ui/OpConfirmation';
import BngButton from 'components/bng/ui/BngButton';

export default function MobileMenu({
  itemsData = {},
  layout = [],
  breakpointView = 'MOBILE',
  selectedItem = '',
  onLayoutChange = _.noop,
  layouts = {},
}) {
  const context = useBimContext();
  const [loading, setLoading] = useState(false);
  const { addChange, availableOnMobile, viewType } = useDashboardPageCtx.cached(({ addChange, dash, viewType }) => ({
    addChange,
    availableOnMobile: dash?.dashboard?.availableOnMobile ?? false,
    viewType,
  }));

  const [mobileItems, setMobileItems] = useState([]);
  const [showIntro, setShowIntro] = useState(null);

  const horizontalLikeVertical = !layouts.hasOwnProperty('MOBILE_HORIZONTAL');

  useAsyncEffect({
    onMount: async () => {
      try {
        const watchedMobileDrawerParam = await Api.UserParam.findKey(Api.UserParam.Keys.Analysis.WatchedMobileDrawer);
        setShowIntro(_.isEmpty(watchedMobileDrawerParam));
      } catch (e) {
        console.error(e);
        UiMsg.ajaxError(null, e);
      }
    },
  });

  useEffect(() => {
    let items = [];

    for (const key in itemsData) {
      const item = itemsData[key];
      const itemLayout = layout.find((item) => item.i === key);
      if (notCurrentBreakpointItem(item)) {
        continue;
      }
      const itemText = item.additionalProps.title || item.additionalProps.label || item.additionalProps.value;
      items.push({
        key,
        availableOnMobile: item.availableOnMobile,
        y: itemLayout ? itemLayout.y : null,
        x: itemLayout ? itemLayout.x : null,
        description: item.caption || `${itemText ? itemText + ' - ' : ''} ${context.msg.t(item.viewType)}`,
        onClick: () => item.availableOnMobile && selectItem(item.id),
        id: `MobileItem-${key}`,
        className: `${item.availableOnMobile ? 'active' : 'inactive'} ${
          selectedItem === item.id ? 'ItemSelected' : ''
        }`,
        actions: [
          {
            icon: 'remove_red_eye',
            onClick: async () => {
              try {
                await addChange({
                  type: 'ITEM_AVAILABLE_ON_MOBILE',
                  data: {
                    id: item.id,
                    enabled: !item.availableOnMobile,
                    breakpoint: viewType(),
                  },
                });
              } catch (e) {
                console.error('Error on toggleMobileViewItem()', e);
                UiMsg.error(null, e);
              }
            },
            className: 'MobileToggleItem',
            title: context.msg.t(item.availableOnMobile ? 'monitor.disable' : 'monitor.enable'),
          },
        ],
      });
    }

    //Remove container items
    for (const key in itemsData) {
      const item = itemsData[key];
      if (notCurrentBreakpointItem(item) || item.viewType.toLowerCase() !== 'container') {
        continue;
      }
      for (const containerItem of item.additionalProps.items) {
        _.remove(items, (i) => i.key === containerItem.i);
      }
    }
    setMobileItems(_.orderBy(items, ['availableOnMobile', 'y', 'x'], ['desc', 'asc', 'asc']));
  }, [itemsData, layout]);

  const notCurrentBreakpointItem = (item) => {
    const isContainer = item.additionalProps && !!item.additionalProps.breakpoint;
    if (isContainer) {
      if (
        breakpointView === 'MOBILE_HORIZONTAL' &&
        item.additionalProps.breakpoint === 'MOBILE' &&
        horizontalLikeVertical
      ) {
        return false;
      }
      return item.additionalProps.breakpoint !== breakpointView;
    }
    return false;
  };

  if (availableOnMobile === null) {
    return null;
  }

  return (
    <AccordionList className="MobileMenu ObjectRightMenuAccordion" loading={loading}>
      <Accordion
        className="MobileMenuAccordion"
        startOpen={availableOnMobile}
        customTitle={(toggleAccordion) => {
          return (
            <div className="AccordionTitle AccordionCustomTitle">
              <span className="AccordionDescription">{context.msg.t('mobile.menu.item')}</span>
              <BngSwitch
                id="availableMobile"
                checked={availableOnMobile}
                onChange={async (event) => {
                  setLoading(true);
                  try {
                    const enabled = !availableOnMobile;
                    await addChange({
                      type: 'AVAILABLE_ON_MOBILE',
                      data: {
                        enabled,
                      },
                    });
                    await toggleAccordion();
                    UiMsg.ok(context.msg.t(`mobile.view.${enabled ? 'enabled' : 'disabled'}`));
                  } catch (e) {
                    console.error('Error while toggling availableOnMobile', e);
                    UiMsg.ajaxError(null, e);
                  } finally {
                    setLoading(false);
                  }
                }}
              />
            </div>
          );
        }}
      >
        <div className="MobileMenu">
          <MobileOrientationSwitch
            itemsData={itemsData}
            horizontalLikeVertical={horizontalLikeVertical}
            layout={layout}
            breakpointView={breakpointView}
          />
          <div className="MobileMenuItemView">
            <div className="MobileMenuTitle flex-center-items">
              <div>{context.msg.t('objects')}</div>
            </div>
            <ActionList
              className="MobileMenuList"
              onChangeOrder={async (itemsOrdered, { fromIdx, toIdx }) => {
                const forward = Math.min(fromIdx, toIdx) === fromIdx;

                let movedItemLayout = _.find(layout, { i: itemsOrdered[toIdx].props.item.key });
                const oldItemLayout = _.find(layout, {
                  i: itemsOrdered[forward ? toIdx - 1 : toIdx + 1].props.item.key,
                });

                if (!movedItemLayout || !oldItemLayout) return;

                let ll = [];
                movedItemLayout.y = oldItemLayout.y - (forward ? movedItemLayout.h - oldItemLayout.h : 0);
                ll.push(movedItemLayout);

                const start = forward ? fromIdx : toIdx + 1;
                const end = forward ? toIdx - 1 : fromIdx;

                for (let i = start; i <= end; i++) {
                  let currentItemLayout = _.find(layout, { i: itemsOrdered[i].props.item.key });
                  if (forward) {
                    currentItemLayout.y -= movedItemLayout.h;
                  } else {
                    currentItemLayout.y += movedItemLayout.h;
                  }
                  ll.push(currentItemLayout);
                }
                const mergedLayouts = layout.map((l) => {
                  const itemL = ll.find((layoutItem) => layoutItem === l.i);
                  return itemL ? itemL : l;
                });
                await onLayoutChange(mergedLayouts, true);
              }}
              items={mobileItems}
              draggable={true}
            />

            <BngButton
              onClick={() => {
                OpConfirmation({
                  title: context.msg.t('restore.default.view'),
                  html: context.msg.t('restore.default.view.message'),
                  onConfirm: async () => {
                    setLoading(true);
                    try {
                      await addChange({
                        type: 'RESET_MOBILE_LAYOUT',
                        data: {
                          breakpoint: breakpointView,
                        },
                      });
                    } catch (e) {
                      console.error('Error on resetMobileLayout()', e);
                      UiMsg.ajaxError(null, e);
                    } finally {
                      setLoading(false);
                    }
                  },
                  level: 'warning',
                });
              }}
            >
              {context.msg.t('restore.default.view')}
            </BngButton>
          </div>
        </div>
      </Accordion>
      {showIntro && <BngMobileDrawerHelp showIntro={showIntro} onFinishIntro={() => setShowIntro(false)} />}
    </AccordionList>
  );
}
