import './MoveObject.css';
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Snackbar from 'components/ui/common/Snackbar';
import ContextEnhancer from 'components/ContextEnhancer';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

class MoveObject extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    dashGrid: PropTypes.any,
    itemId: PropTypes.string,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    itemId: '',
    onClose: _.noop,
    onSave: _.noop,
  };

  state = {
    targetItemId: '',
    loading: true,
  };

  async componentDidMount() {
    application.dashboards.adjustHeightForFixedFilter(false);
    j('.page-content').css('overflow', 'hidden');

    useDashboardPageCtx.setState(() => ({
      dashGridCustomSelect: {
        onSelect: this.selectionHandler,
        itemClass: this.getItemClass,
      },
    }));

    this.setState({ loading: false });
  }

  componentWillUnmount() {
    application.dashboards.adjustHeightForFixedFilter(true);
    j('.page-content').css('overflow', 'auto');

    useDashboardPageCtx.setState(() => ({
      dashGridCustomSelect: null,
    }));
  }

  selectionHandler = (selectedItemProps) => {
    selectedItemProps = Array.isArray(selectedItemProps) ? selectedItemProps[0] : selectedItemProps;
    const itemId = selectedItemProps?.layout?.i;
    if (itemId === this.props.itemId) {
      return;
    }

    const targetItemId = itemId === this.state.targetItemId ? '' : itemId;
    this.setState(
      {
        targetItemId: targetItemId,
      },
      () => {
        const selectedItems = [this.props.itemId];
        if (targetItemId) {
          selectedItems.push(targetItemId);
        }
        useDashboardPageCtx.setState(() => ({
          selectedItems,
        }));
      }
    );
  };

  getItemClass = ({ i }) => {
    if (i === this.state.targetItemId || i === this.props.itemId) {
      return 'selected-by-container select-item-to-move';
    }
    return '';
  };

  close = () => {
    this.props.onClose();
  };

  save = async () => {
    this.setState({ loading: true });
    try {
      await this.props.onSave({
        fromId: this.props.itemId,
        toId: this.state.targetItemId,
      });
      this.close();
    } catch (e) {
      console.error('Error while moving item', e);
      this.setState({ loading: false });
    }
  };

  render() {
    const validSelection = !!this.state.targetItemId;

    return (
      <React.Fragment>
        {ReactDOM.createPortal(<div className="MoveObjectOverlay" onClick={this.close} />, document.body)}

        <div className="MoveObjectParent">
          <Snackbar
            className={`save-snackbar softComing ${validSelection ? 'Valid' : ''}`}
            icon={validSelection ? 'check' : 'touch_app'}
            onClick={validSelection && !this.state.loading ? this.save : null}
            message={this.props.context.msg.t(validSelection ? 'click.to.move.items' : 'select.items.to.move')}
          >
            <span
              className="material-icons cancel-move-object notranslate"
              onClick={this.close}
              title={this.props.context.msg.t('cancel')}
            >
              close
            </span>
          </Snackbar>
        </div>
      </React.Fragment>
    );
  }
}

export default ContextEnhancer(MoveObject);
