import React from 'react';
import PropTypes from 'prop-types';
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import ContextEnhancer from 'components/ContextEnhancer';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

class DashboardItemMenuAnalysisViewChange extends React.Component {
  static propTypes = {
    item: PropTypes.object,
  };

  static defaultProps = {
    item: {},
  };

  renderAnalysisInPlace = () => {
    let { viewType } = this.props.item;
    if (viewType === 'html') {
      viewType = 'image';
    } else {
      viewType = 'html';
    }

    const item = _.cloneDeep(this.props.item);
    item.viewType = viewType;
    useDashboardPageCtx.getState().updateItemVisualization({
      action: 'OVERRIDE',
      item: item,
    });
  };

  render() {
    const { item } = this.props;
    let title;
    let icon;

    if (item.additionalProps.isShowChart && !(item.viewType === 'image')) {
      title = this.props.context.msg.t('visualize.chart');
      icon = 'icon-bar-chart';
    } else if (item.additionalProps.isShowTable && !(item.viewType === 'html')) {
      title = this.props.context.msg.t('visualize.table');
      icon = 'icon-table';
    }

    return (
      <li onClick={this.renderAnalysisInPlace}>
        <DashboardItemMenuTitle title={title} icon={icon} more={false} />
      </li>
    );
  }
}
export default ContextEnhancer(DashboardItemMenuAnalysisViewChange);
