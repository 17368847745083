import DeepEqualComponent from 'components/DeepEqualComponent';

export function isEditingLayout() {
  return document.body.classList.contains(`react-draggable-transparent-selection`);
}

export class DashSkipOnResizeComponent extends DeepEqualComponent {
  shouldComponentUpdate(nextProps, nextState) {
    if (isEditingLayout()) {
      return false;
    }

    return super.shouldComponentUpdate(nextProps, nextState);
  }
}
