import React, { useMemo, useState } from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import AccordionList from 'components/ui/AccordionList';
import Accordion from 'components/ui/Accordion';
import BngCheckbox from 'components/bng/form/BngCheckbox';
import { BngField } from 'components/bng/form/BngField';
import { BngSelect } from 'components/bng/form/BngSelect';
import { bngYup } from 'components/bng/form/yup/BngYup';
import ActionList from 'components/ui/dashboard/components/ActionList';
import UiMsg from 'components/ui/UiMsg';
import Api from 'components/Api';
import Icon from 'components/ui/common/Icon';
import BngSwitch from 'components/bng/form/BngSwitch';
import { LabelHelper } from 'components/ui/dashboard/DashLayoutAccordion';
import HelpIcon from 'components/ui/common/HelpIcon';
import BngDropdownCheckbox from 'components/bng/ui/BngDropdownCheckbox';
import { BngIconButton } from 'components/bng/ui/BngIconButton';
import Button from 'components/ui/Button';
import BngNavHeader from 'components/bng/ui/BngNavHeader';
import useTranslation from 'components/hooks/useTranslation';
import useProjectFilters from 'components/ui/dashboard/hooks/useProjectFilters';

const filterMembersValues = ['REMOVE', 'DISABLE', 'NONE'];
const restrictionTypeValues = ['SHOW_SELECTED', 'HIDE_SELECTED'];
const periodRestrictionValues = ['NONE', 'DAY_LEVEL'];

const filterMembers = (t, filter) => {
  let opts = [];
  for (const value of filterMembersValues) {
    opts.push({
      value: value,
      label: t(`data.link.${value}`),
    });
  }
  if (filter?.timeFilter) {
    opts = opts.filter((opt) => opt.value === 'NONE');
  }
  return opts;
};

const restrictionTypes = (t) => {
  let opts = [];
  for (const value of restrictionTypeValues) {
    opts.push({
      value: value,
      label: t(`RequiredRestrictionType.${value}`),
    });
  }
  return opts;
};

const periodRestriction = (t) => {
  let opts = [];
  for (const value of periodRestrictionValues) {
    opts.push({
      value: value,
      label: t(`TimeFilterRestriction.${value}`),
    });
  }
  return opts;
};

export const ConfigureFilterSchema = bngYup((yup) => {
  return yup.object({
    id: yup.number().default(0),
    filterLink: yup.string().required().oneOf(filterMembersValues).default('REMOVE'),
    hide: yup.boolean().required().default(false),
    fixed: yup.boolean().required().default(false),
    required: yup.boolean().required().default(false),
    periodRestriction: yup.string().default('NONE'),
    restrict: yup.object({
      enabled: yup.boolean().required().default(false),
      restrictionType: yup.string().required().oneOf(restrictionTypeValues).default('SHOW_SELECTED'),
      members: yup.array(yup.string()).default([]),
    }),
  });
});

function DataAccordion({ projectFilter }) {
  const { t } = useTranslation();
  return (
    <Accordion id="DataAccordion" title={t('link.data')}>
      <Field
        name="filterLink"
        label={t('filter.members')}
        component={BngField}
        inputComponent={BngSelect}
        options={filterMembers(t, projectFilter)}
        emptyOption={false}
        disabled={projectFilter?.timeFilter}
      />
      {projectFilter?.timeFilter && (
        <Field
          name="periodRestriction"
          label={t('periods.visualization')}
          component={BngField}
          inputComponent={BngSelect}
          options={periodRestriction(t)}
          emptyOption={false}
        />
      )}
      <Field
        name="hide"
        withLabel={false}
        asProps={{
          label: <LabelHelper helpLabel={t('hide.filter.hint')} label={t('hide.filter')} />,
        }}
        component={BngField}
        inputComponent={BngCheckbox}
      />
      <Field
        name="fixed"
        withLabel={false}
        asProps={{
          label: <LabelHelper helpLabel={t('fixed.filters.title')} label={t('fixed.filter')} />,
        }}
        component={BngField}
        inputComponent={BngCheckbox}
      />
      <Field
        name="required"
        withLabel={false}
        asProps={{
          label: <LabelHelper helpLabel={t('required.filter.hint')} label={t('required.filter')} />,
        }}
        component={BngField}
        inputComponent={BngCheckbox}
      />
    </Accordion>
  );
}

function RestrictMembersAccordion() {
  const { t } = useTranslation();
  const {
    values: { id: filterId, restrict },
    setFieldValue,
  } = useFormikContext();

  const [members, setMembers] = useState(null);
  const boundariesElement = useMemo(() => document.querySelector('#page-content, .page-content, .BngAppContent'), []);

  const removeMember = (member = '') => {
    const newRestrictMembers = restrict.members.filter((m) => m !== member);
    setFieldValue(`restrict.members`, newRestrictMembers);
    setMembers(buildSelectedMembers(newRestrictMembers));
  };

  const buildItems = () => {
    let items = [];
    for (let member of restrict.members || []) {
      items.push({
        'data-test': `FilterItem-${member}`,
        description: member,
        actions: [
          {
            icon: 'close',
            onClick: () => removeMember(member),
            className: 'RemoveFilterMember',
            title: t('remove'),
          },
        ],
      });
    }
    return items;
  };

  const addFilterMember = (selectedMembers = []) => {
    const selectedKeys = selectedMembers.map((m) => m.key);
    setFieldValue(`restrict.members`, selectedKeys);

    setMembers(buildSelectedMembers(selectedKeys));
  };

  const fetchMembers = async () => {
    try {
      const filterMembersData = await Api.Filter.findMembers(filterId);
      setMembers(
        filterMembersData.members.map((m) => {
          return {
            key: m.value,
            label: m.label,
            value: restrict.members.includes(m.value),
          };
        })
      );
    } catch (e) {
      console.error('Error on fetchMembers()', { filterId }, e);
      UiMsg.ajaxError(null, e);
    }
  };

  const buildSelectedMembers = (selectedKeys, currentMembers = members) => {
    return currentMembers?.map((member) => {
      return { ...member, value: selectedKeys.includes(member.key) };
    });
  };

  return (
    <Accordion
      id="RestrictMembersAccordion"
      customTitle={(toggleAccordion) => {
        return (
          <div className="AccordionTitle AccordionCustomTitle">
            <Icon className="AccordionIcon" icon="arrow_drop_up" onClick={toggleAccordion} />
            <span className="AccordionDescription" onClick={toggleAccordion}>
              {t('restrict.members')}
            </span>
            <HelpIcon title={t('restrict.members.hint')} />
            <Field name="restrict.enabled" value={restrict.enabled} component={BngSwitch} />
          </div>
        );
      }}
    >
      <Field
        name="restrict.restrictionType"
        label={t('filter.members')}
        component={BngField}
        inputComponent={BngSelect}
        options={restrictionTypes(t)}
        emptyOption={false}
      />
      <ActionList
        items={buildItems()}
        customAction={() => {
          return (
            <BngDropdownCheckbox
              boundariesElement={boundariesElement}
              customButton={({ openDropdown }) => <BngIconButton icon="add" onClick={openDropdown} />}
              beforeOpen={fetchMembers}
              onApply={addFilterMember}
              items={members || []}
            />
          );
        }}
      />
    </Accordion>
  );
}

export default function ConfigureFilterAccordion({ initialValues = {}, onGoBack = _.noop, onApply = _.noop }) {
  const { t } = useTranslation();
  const { data: projectFilters = [], isLoading } = useProjectFilters();
  const projectFilter = projectFilters.find((pf) => pf.id === initialValues.id);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ConfigureFilterSchema}
      onSubmit={async (values, formikHelpers) => {
        try {
          await onApply(values);
        } catch (e) {
          console.error('Error while applying filter values', e);
          UiMsg.ajaxError(null, e);
          formikHelpers.setSubmitting(false);
        }
      }}
    >
      {({ values, isSubmitting, submitForm }) => {
        return (
          <Form>
            <AccordionList
              className="ObjectRightMenuAccordion ConfigureFilterAccordion HasFixedButton"
              loading={isLoading || isSubmitting}
            >
              <BngNavHeader
                icon="icon-bim-filter"
                className="ConfigureFilterHeader"
                stack={[{ label: t('filters') }, { label: projectFilter?.caption }]}
                onGoBack={onGoBack}
              />
              <DataAccordion projectFilter={projectFilter} />
              {!projectFilter?.timeFilter && <RestrictMembersAccordion />}

              <Accordion className="ApplyFilterConfig AccordionFixedButton" customTitle={() => null}>
                <Button className="bng-button save m-0" type="submit" loading={isSubmitting} onClick={submitForm}>
                  {t('apply')}
                </Button>
              </Accordion>
            </AccordionList>
          </Form>
        );
      }}
    </Formik>
  );
}
