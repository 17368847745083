import React from "react";

const DashGridContext = React.createContext({
    dashboardPath: null,
    editMode: false,
    filters: [],
    isFromPublisher: false,
    privateVisibility: false,
    isPresentation: false,
    fromCockpit: undefined,
});

export default DashGridContext;
