import { connect } from 'react-redux';
import Breadcrumb from './Breadcrumb';
import Api from 'components/Api';

const removedUrls = ['/bng/marketplace'];

const mapStateToProps = (state) => {
  const { breadcrumb } = state.context;

  const cockpitUrls = [Api.Cockpit.findCockpitUri()];
  const fromCockpit = breadcrumb
    ? breadcrumb.contents.find((c) => cockpitUrls.some((url) => c.content.includes(url))) != null &&
      !cockpitUrls.some((url) => breadcrumb.current.content.includes(url))
    : false;

  const isRemovedUrl = breadcrumb ? removedUrls.some((url) => breadcrumb.current.content.includes(url)) : false;
  const rendered = state.context.isAccessingProject && (!state.context.cockpitEnabled || fromCockpit) && !isRemovedUrl;

  let obj = {
    expanded: state.menu.expanded,
    rendered,
    fromCockpit,
  };

  if (rendered) {
    const { theme, project } = state.context;
    Object.assign(obj, {
      favIcon: theme.favIcon,
      projectName: project ? project.displayName : '',
      currentPage: breadcrumb?.current,
      pages: breadcrumb?.contents,
    });
  }
  return obj;
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
