import "./Cockpit.css";

import React, {Component} from "react";
import PropTypes from 'prop-types';

import ContextEnhancer from "components/ContextEnhancer";
import Api from "components/Api";
import Icon from "components/ui/common/Icon";
import IndexPage from "./IndexPage";
import CockpitTabs from "./CockpitTabs";
import CockpitBody from "./CockpitBody";
import Utils from "components/Utils";

import MaterialImports from 'components/MaterialImports';
import FilterService from "components/filter/FilterService";
import MessageComponents from 'components/ui/ModalInformation';
import HtmlPage from 'components/ui/cockpit/HtmlPage';

export const TYPES = {
    INDEX_PAGE_ITEM: 'INDEX_PAGE_ITEM',
    HTML_ITEM: 'HTML_ITEM',
    PATH_AWARE_ITEM: 'PATH_AWARE_ITEM',
    COVER_PAGE_ITEM: 'COVER_PAGE_ITEM'
};


class Cockpit extends Component {

    static propTypes = {
        withIndex: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        withIndex: true
    };

    state = {
        currentPanel: 0,
        showBackButton: false,
        showHeader: true,
        loading: true,
    };

    constructor(props) {
        super(props);
        let firstPanel = _.head(this.props.cockpit.panels);
        this.state.currentPanel = firstPanel ? firstPanel.id : 0;
    }

    sendEmailToMaster = async (path) => {
        try {
            const request = await Api.Cockpit.sendEmailToMaster(path);
            return request.data === 'OK';
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    openDashNotConfigure = ({path}) => {
        const {msg} = this.props.context;
        MessageComponents.ModalInformation({
            text: `
                <p style="font-size: 17px;"><strong>${msg.t('dashboard.not.configure.mobile.title')}</strong></p>
                <p>
                    ${msg.t('dashboard.not.configure.mobile.title')}
                    <a href="#" onclick="event.preventDefault(); 
                                         application.Api.ReactNative.openLink('${this.props.context.msg.t('support.configure.dashboard.mobile')}')"
                    >${msg.t('know.more')}</a>
                </p>
            `,
            buttonText: msg.t('dashboard.not.configure.mobile.button.notify'),
            onConfirmFunction: async () => {
                return await this.sendEmailToMaster(path)
            },
            executeFunction: true,
            successTitle: msg.t('dashboard.not.configure.mobile.success.title'),
            successMessage: msg.t('dashboard.not.configure.mobile.success'),
            errorTitle: msg.t('dashboard.not.configure.mobile.error.title'),
            errorMessage: msg.t('dashboard.not.configure.mobile.error')
        });
    }

    openPanel = async (p) => {
        let path = !!p.path ? p.path : '';
        if (Utils.Object.isDashboard(path) && application.page.isMobile()) {
            let configureLayoutForMobile = await Api.Cockpit.mobileDashboardLayoutConfig(path);
            if (!configureLayoutForMobile.data) {
                this.openDashNotConfigure({path});
                return;
            }
        }
        this.setState({currentPanel: p.id})
    };

    loadPanel = (panel) => {
        const d = document.querySelector('.mdl-layout');
        d.MaterialLayout.toggleDrawer();
        const title = document.querySelector('.item-name-cockpit-mobile.label-cockpit-mobile').textContent;
        if (this.state.currentPanel === panel.id && title !== panel.name) {
            const $iframe = document.getElementById('iframeCockpitMobile');
            $iframe.src = $iframe.src;
        } else {
            this.openPanel(panel);
        }
    }

    findIndexPage() {
        return _.find(this.props.cockpit.panels, {type: TYPES.INDEX_PAGE_ITEM});
    }

    objectUri = (panel) => {
        let ext = panel.path.split('.');
        ext = "/" + ext[ext.length - 1];
        let uri = window.location.pathname.replace('/cockpit', ext);

        let params = {
            content: panel.path,
            removeFrame: true,
            fromCockpit: this.props.cockpit.id,
            chart: panel.viewType === 'CHART'
        };
        let qs = Api.parseQueryString();
        Object.keys(qs)
            .filter(key => !(key in params))
            .forEach(key => params[key] = decodeURIComponent(qs[key]));

        if (application.page.isMobile()) {
            const cockpitHeaderSize = 52;
            params.width = application.page.viewport()[0];
            params.height = application.page.viewport()[1] - cockpitHeaderSize;
        }

        if (!params.basicAuthQp && (window.__AUTHORIZATION && window.__AUTHORIZATION.length > 0)) {
            params.basicAuthQp = window.__AUTHORIZATION;
        }

        FilterService.addFilterToParams(params, window.__FILTER_CACHE_ON_RESIZE);
        return `${uri}?${jQuery.param(params)}`;
    };

    lastLayout = null;

    //Ajuste para fazer reload do iframe do mobile quando faço a rotação da tela em objetos que estão fora do dashboard (Navegação entre objetos / Navegação por icone)
    updateOnResize = () => {
        setTimeout(() => {
            const iFrame = document.getElementById('iframeCockpitMobile');
            if (!!iFrame && iFrame.contentWindow.location.href.includes('/dashboard?')) {
                return;
            }
            const layout = [window.innerWidth, window.innerHeight];
            if (this.lastLayout === null || layout[0] !== this.lastLayout[0] && layout[1] !== this.lastLayout[1]) {
                this.lastLayout = layout;
                const iframeLocation = iFrame.contentWindow.location;
                window.__FILTER_CACHE_ON_RESIZE = window.__FILTER_CHANGE_TEMP_CACHE;
                const params = new URLSearchParams(iframeLocation.search);
                params.set('width', window.innerWidth + '');
                params.set('height', window.innerHeight + '');
                iFrame.contentWindow.location.replace(`${iframeLocation.origin}${iframeLocation.pathname}?${params.toString()}`)
            }
        }, 500);
    };

    componentDidMount() {
        this.clearFilterCache();
        this.updateElementsToDisplay();

        const isMobile = application.page.isMobile();
        if (isMobile) {
            let layout = document.getElementById('cockpit-menu-mobile');
            MaterialImports.componentHandler.upgradeElement(layout);
            window.addEventListener('resize', () => this.updateOnResize(), true);
        } else {
            application.cockpit.scrollBar(true);
        }
        this.setState({loading: false});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.currentPanel !== this.state.currentPanel) {
            this.clearFilterCache();
        }
    }

    updateElementsToDisplay() {
        let params = {};
        let qs = Api.parseQueryString();
        Object.keys(qs)
            .filter(key => !(key in params))
            .forEach(key => params[key] = decodeURIComponent(qs[key]));
        if (!!qs.showBackButton) {
            this.setState({showBackButton: qs.showBackButton === 'true'});
        }
        if (!!qs.showHeader) {
            this.setState({showHeader: qs.showHeader === 'true'});
        }
    }

    clearFilterCache = () => {
        delete window.__FILTER_CACHE_ON_RESIZE;
        delete window.__FILTER_CHANGE_TEMP_CACHE;
    };

    componentWillUnmount() {
        this.clearFilterCache();
        window.removeEventListener('resize', () => this.updateOnResize());
    }

    changeTitleObject = async (e) => {
        if (!application.page.isMobile()) return;
        const params = e.target.contentWindow.location.search;
        const urlParams = new URLSearchParams(params);
        const content = urlParams.get('content');
        let objectName = '';
        try {
            objectName = await Api.Bng.getPathCaption(content);
        } catch (e) {
            console.error('Error fetching object name:', e);
            return;
        }

        const $itemName = document.getElementsByClassName("item-name-cockpit-mobile")[0];
        if (!!$itemName) {
            document.getElementsByClassName("item-name-cockpit-mobile")[0].innerHTML = objectName;
        }
        const objectIcon = Icon.iconIdentifierForPath(content);
        const $containerIcon = document.getElementsByClassName("icon-cockpit-mobile")[0];
        const $icon = $containerIcon && $containerIcon.children[0];
        if (!$icon) return;
        if (!!urlParams.get('openIconObject')) {
            $icon.className = `Icon ${objectIcon}`;
            $icon.innerHTML = '';
        } else {
            const panel = this.findCurrentPanel() || this.props.cockpit.panels[0];
            switch (Icon.parseType(panel.icon)) {
                case Icon.MATERIAL:
                    $icon.className = `Icon material-icons`;
                    $icon.innerHTML = panel.icon;
                    break;
                default:
                    $icon.className = `Icon ${panel.icon}`;
                    $icon.innerHTML = '';
            }
        }
    };

    handleGoBack = () => {
        const mobileCallback = function (data) {
            window.__FILTER_CACHE_ON_RESIZE = window.__FILTER_CHANGE_TEMP_CACHE;
            const $iframe = document.getElementById("iframeCockpitMobile");
            const currentUrl = $iframe !== null ? $iframe.contentWindow.location.search : '';
            const urlParams = new URLSearchParams(currentUrl);
            let message = data;

            if (!!urlParams.get('openIconObject')) {
                message = "voltar iframe";
            }

            if (!!window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(message);
                return;
            }
            window.postMessage(message, '*');
        };
        mobileCallback(JSON.stringify({"action": 'implementar voltar'}));
    };

    render() {
        const indexPage = this.findIndexPage();
        const panels = this.props.cockpit.panels;

        const deviceWidth = application.page.viewport()[0];

        const isMobile = application.page.isMobile();

        let columnCount = 3;
        if (isMobile) {
            columnCount = 1;
            if (deviceWidth > 399)
                columnCount = 2;
        }

        //removendo índice quando não for visível
        if (isMobile && !this.props.cockpit.generateIndexPage && indexPage) {
            panels.splice(indexPage, 1);
        }
        let panel = this.findCurrentPanel() || panels[0];

        const currentCockpitColor = panel.color + 'dd';

        const iframeUrl = (panel && panel.type === TYPES.PATH_AWARE_ITEM) ? this.objectUri(panel) : null;

        const bodyRender = (
            <CockpitBody>
              {panel && panel.type === TYPES.INDEX_PAGE_ITEM &&
                <IndexPage title={this.props.cockpit.name}
                           icon={this.props.cockpit.icon}
                           content={panel.content}
                           panels={panels}
                           columnCount={columnCount}
                           onSelect={this.openPanel}
                />
              }

                {panel && (panel.type === TYPES.HTML_ITEM || panel.type === TYPES.COVER_PAGE_ITEM) &&
                  <HtmlPage panel={panel}/>
                }

                {panel && panel.type === TYPES.PATH_AWARE_ITEM &&
                    <iframe id="iframeCockpitMobile"
                        onLoad={this.changeTitleObject}
                        className={`cockpit-object-iframe ${this.state.showHeader ? 'withHeader' : 'withoutHeader'}`}
                        src={iframeUrl} ref={this.updateSize}/>
                }
            </CockpitBody>
        );

        return (

            <div className="Cockpit">

                {!isMobile &&
                <div>
                    <CockpitTabs ctx={this.props.context}
                                 panels={panels}
                                 currentPanel={this.state.currentPanel}
                                 indexPanel={indexPage}
                                 onSelect={this.openPanel}
                                 enablePainelColors={this.props.cockpit.enablePainelColors}
                    />
                    {bodyRender}
                </div>
                }


                {isMobile &&
                <div id="cockpit-menu">
                    <div id="cockpit-menu-mobile" className="mdl-layout mdl-js-layout mdl-layout--fixed-header">
                        <header
                            className={`mdl-layout__header ${this.state.showHeader ? '' : 'disabled-display-header'}`}>
                            <div className="cockpit-header-mobile">
                                <span className="mdl-layout-title" id="cpt-item-current-title">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="cpt-item-view-more-icon-width">
                                                    {this.state.showBackButton &&
                                                    <span className="label-cockpit-mobile icon-back-mobile"
                                                          onClick={this.handleGoBack}>
                                                        <Icon icon="arrow_back"/>
                                                    </span>
                                                    }
                                                </td>
                                                <td className="cpt-item-view-more-icon-width">
                                                    <span className="icon-cockpit-mobile label-cockpit-mobile">
                                                        <Icon icon={panel.icon}/>
                                                    </span>
                                                </td>
                                                <td>
                                                    <div className="width-100">
                                                        <div style={{marginLeft: 0}}
                                                             className="item-name-cockpit-mobile label-cockpit-mobile">
                                                            {panel.name}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                                <div className="mdl-layout-spacer"></div>
                            </div>
                        </header>

                        {panels.length > 1 &&
                        <div className="mdl-layout__drawer">
                                <span className="mdl-layout-title">
                                    <div className="cpt-item-current" id="cpt-item-current">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="cpt-item-view-more-icon-width">
                                                        <span className="cpt-item-view-more-icon-publisher"
                                                              style={{color: currentCockpitColor}}>
                                                                <Icon type={Icon.parseType(panel.icon)}
                                                                      icon={panel.icon}/>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className="cpt-item-view-more-name-width">
                                                            <div
                                                                className="cpt-item-view-more-name cpt-item-view-more-name-title text-overflow-ellipsis">
                                                                {panel.name}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </span>
                            <nav className="mdl-navigation" id="cockpit-panel-menu">
                                <div>
                                    {panels.map(panel => {
                                        const cockpitColor = panel.color + 'dd';
                                        return (
                                            <a className="mdl-navigation__link cpt-item-view-more-option" href="#"
                                               key={panel.id} onClick={() => this.loadPanel(panel)}>
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td className="cpt-item-view-more-icon-width">
                                                                     <span className="cpt-item-view-more-icon-publisher"
                                                                           style={{color: cockpitColor}}>
                                                                         <Icon icon={panel.icon}/>
                                                                     </span>
                                                        </td>
                                                        <td>
                                                            <div className="cpt-item-view-more-name-width">
                                                                <div
                                                                    className="cpt-item-view-more-name-publisher cpt-item-view-more-name-title text-overflow-ellipsis">
                                                                    {panel.name}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </a>
                                        );
                                    })}
                                </div>
                            </nav>
                        </div>
                        }

                        <main className="mdl-layout__content">
                            <div className="page-content">
                                {bodyRender}
                            </div>
                        </main>

                    </div>

                </div>
                }


            </div>


        );
    }

    findCurrentPanel() {
        return _.find(this.props.cockpit.panels, {id: this.state.currentPanel});
    }


    updateSize(el) {
        if (el) {
            el.style.height = (application.page.contentHeight() - 60);
        }
    }
}

export default ContextEnhancer(Cockpit);