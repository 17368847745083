import { useEventListener } from 'components/hooks/useEventListener';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import { cloneItemAction } from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuCloneObject';
import useTranslation from 'components/hooks/useTranslation';
import useReduxDispatch from 'components/hooks/useReduxDispatch';

export default function DashItemCloner({ item, editMode = false }) {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const addChange = useDashboardPageCtx.cached(({ addChange }) => addChange);

  useEventListener(
    'keydown',
    (e) => {
      const ctrlPressed = e.ctrlKey || e.metaKey;
      const isVPressed = e.which === 86;
      if (!editMode || !item || !ctrlPressed || !isVPressed) {
        return;
      }

      cloneItemAction({ item, addChange, t, dispatch });
    },
    document
  );

  return <></>;
}
