import "./DashboardTextForm.css";

import React from "react";
import PropTypes from 'prop-types';
import memoizeOne from "memoize-one";
import {FastField, Field, Formik} from "formik";

import ContextEnhancer from "components/ContextEnhancer";
import Dialog from "components/ui/Dialog";
import {DefaultDialogActions} from "components/ui/FormUtils";
import Text from "components/ui/dashboard/components/Text";
import UiMsg from "components/ui/UiMsg";
import {BngForm} from "components/bng/form/BngForm";
import {BngField} from "components/bng/form/BngField";
import {BngSelect} from "components/bng/form/BngSelect";
import BngInputColor from "components/bng/form/BngInputColor";
import BngCheckbox from "components/bng/form/BngCheckbox";
import {bngYup} from "components/bng/form/yup/BngYup";
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import UUID from 'bng/utils/UUID';

const FONTS = [
    {value: 'font-family-roboto', label: 'Roboto'},
    {value: 'font-family-open-sans', label: 'Open Sans'},
    {value: 'font-family-heveltica-neue', label: 'Heveltica Neue'},
    {value: 'font-family-georgia', label: 'Georgia'},
    {value: 'font-family-palatino-linotype', label: 'Palatino Linotype'},
    {value: 'font-family-times-new-roman', label: 'Times New Roman'},
    {value: 'font-family-arial', label: 'Arial'},
    {value: 'font-family-arial-black', label: 'Arial Black'},
    {value: 'font-family-comic-sans', label: 'Comic Sans MS'},
    {value: 'font-family-impact', label: 'Impact'},
    {value: 'font-family-lucida-sans-unicode', label: 'Lucida Sans Unicode'},
    {value: 'font-family-tahoma', label: 'Tahoma'},
    {value: 'font-family-trebuchet', label: 'Trebuchet MS'},
    {value: 'font-family-verdana', label: 'Verdana'},
    {value: 'font-family-courier-new', label: 'Courier New'},
    {value: 'font-family-lucida-console', label: 'Lucida Console'},
];

const FONT_SIZE = _.range(8, 41).map(value => ({value, label: `${value}`}));

const ALIGN = [
    {value: 'center', label: 'label.aling.center'},
    {value: 'right', label: 'label.aling.right'},
    {value: 'left', label: 'label.aling.left'},
];


const textLimit = 50;

const TextSchema = bngYup(yup => {
    return yup.object().shape({
        hash: yup.string().default('0'),
        content: yup.string().required().min(1).max(textLimit).trim().default(''),
        color: yup.string().trim(),
        colorbackground: yup.string().trim().default('#FFFFFF'),
        font: yup.string().required().trim().default(FONTS[0].value),
        fontsize: yup.number().default(FONT_SIZE[5].value),
        autoresize: yup.boolean().default(false),
        transparentbackground: yup.boolean().default(true),
        bold: yup.boolean().default(false),
        aling: yup.string().required().trim().default(ALIGN[0].value),
    });
});

class DashboardTextForm extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        closeModal: PropTypes.func,
        item: PropTypes.object,
    };

    static defaultProps = {
        item: null,
        findStyle: () => useDashboardPageCtx.getState().dash?.dashboard?.style,
    };

    initialValues = TextSchema.default();

    constructor(props, context) {
        super(props, context);
        this.initialValues = TextSchema.default();
        this.initialValues.color = this.findDefaultColor();
        this.initialValues = _.cloneDeep(_.merge(this.initialValues, props.item?.additionalProps ?? {}));
    }

    findDefaultColor = () => {
        const backgroundTheme = this.props.findStyle()?.backgroundTheme ?? '';
        if (['CORPORATIVE', 'BLACK'].includes(backgroundTheme)) {
            return '#FFFFFF';
        } else {
            return '#333333';
        }
    }

    save = async (values, actions) => {
        try {
            await useDashboardPageCtx.getState().addChange({
              type: 'LABEL',
              data: {
                  id: this.props.item?.id ?? UUID.generate(),
                  ...values
              },
            });
            UiMsg.ok(this.props.context.msg.t('changes.applied.successfully'));
            this.props.closeModal();
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
            actions.setSubmitting(false);
        }
    };

    getAlignOptions = memoizeOne(() => {
        return ALIGN.map(opt => ({value: opt.value, label: this.props.context.msg.t(opt.label)}));
    });

    render() {
        return (
            <Formik initialValues={this.initialValues}
                    validationSchema={TextSchema}
                    onSubmit={this.save}>
                {({values, setFieldValue, isSubmitting}) => {
                    const preview = {
                        hash: 'preview-label',
                        color: values.color,
                        font: values.font,
                        content: (values.content || this.props.context.msg.t('insert.some.content')),
                        autoresize: values.autoresize,
                        colorbackground: values.colorbackground,
                        fontsize: values.fontsize,
                        aling: values.aling,
                        bold: values.bold,
                        transparentbackground: values.transparentbackground
                    };

                    const length = Math.max(_.get(values, 'content.length', 0), 0);

                    return (
                        <Dialog open={this.props.open}
                                title={this.props.context.msg.t('title')}
                                onClose={this.props.closeModal}
                                newDialogLayout
                                className="DashboardTextFormDialog"
                                loading={isSubmitting}
                        >

                            <BngForm>
                                <Dialog.Body>
                                    <div className="row-fluid">
                                        <div className="span4">
                                            <FastField
                                                name="font"
                                                component={BngField}
                                                inputComponent={BngSelect}
                                                options={FONTS}
                                                emptyOption={false}
                                            />
                                        </div>
                                        <div className="span4">
                                            <Field
                                                name="fontsize"
                                                component={BngField}
                                                label={this.props.context.msg.t('label.font.size')}
                                                inputComponent={BngSelect}
                                                options={FONT_SIZE}
                                                emptyOption={false}
                                                disabled={values.autoresize === false}
                                            />
                                        </div>
                                        <div className="span2 text-center">
                                            <FastField
                                                name="color"
                                                component={BngField}
                                                inputComponent={BngInputColor}
                                                size="lg"
                                            />
                                        </div>
                                        <div className="span2 text-center">
                                            <Field
                                                name="colorbackground"
                                                component={BngField}
                                                label={this.props.context.msg.t('label.color.background')}
                                                inputComponent={BngInputColor}
                                                disabled={values.transparentbackground === true}
                                                size="lg"
                                            />
                                        </div>
                                    </div>

                                    <div className="row-fluid">
                                        <div className="span4">
                                            <FastField
                                                name="autoresize"
                                                component={BngField}
                                                withLabel={false}
                                                inputComponent={BngCheckbox}
                                                asProps={{
                                                    label: this.props.context.msg.t('label.font.autoresize')
                                                }}
                                            />
                                        </div>
                                        <div className="span4">
                                            <FastField
                                                name="bold"
                                                component={BngField}
                                                withLabel={false}
                                                inputComponent={BngCheckbox}
                                                asProps={{
                                                    label: this.props.context.msg.t('label.font.bold')
                                                }}
                                            />
                                        </div>
                                        <div className="span4">
                                            <FastField
                                                name="transparentbackground"
                                                component={BngField}
                                                withLabel={false}
                                                inputComponent={BngCheckbox}
                                                asProps={{
                                                    label: this.props.context.msg.t('label.background.transparentbackground')
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row-fluid">
                                        <div className="span9">
                                            <label className="textLength">
                                                <span className="textLengthSpan">{`${length}/${textLimit}`}</span>
                                            </label>

                                            <FastField
                                                name="content"
                                                component={BngField}
                                                withLabel={false}
                                                placeholder={this.props.context.msg.t('content')}
                                                maxLength={textLimit}
                                            />
                                        </div>
                                        <div className="span3">
                                            <FastField
                                                name="aling"
                                                component={BngField}
                                                withLabel={false}
                                                inputComponent={BngSelect}
                                                options={this.getAlignOptions()}
                                                emptyOption={false}
                                            />
                                        </div>
                                    </div>

                                    <div className="row-fluid">


                                        <label style={{marginTop: 10}}>
                                            <i className="fa fa-eye"/> {this.props.context.msg.t('preview')}
                                        </label>

                                        <div
                                            style={{backgroundColor: values.transparentbackground ? 'transparent' : values.colorbackground}}
                                            className="text-preview">
                                            <Text {...preview} value={preview.content ?? ''}/>
                                        </div>

                                    </div>

                                </Dialog.Body>

                                <Dialog.Footer>
                                    <DefaultDialogActions {...this.props} />
                                </Dialog.Footer>
                            </BngForm>
                        </Dialog>
                    );
                }}
            </Formik>

        );
    }
}

export default ContextEnhancer(DashboardTextForm);