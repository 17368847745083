export const transformItemToPersist = (item) => {
  return {
    uuid: item.i ?? item.uuid,
    x: item.x,
    y: item.y,
    width: item.w ?? item.width,
    height: item.h ?? item.height,
  };
};

export const transformLayoutToPersist = (layout) => {
  return _.sortBy(layout, ['i']).map((item) => transformItemToPersist(item));
};

export const selectItem = (itemId) => {
  const $dashItem = j('.DashGridItem-' + itemId);
  if ($dashItem.length === 0) return;
  $dashItem.click(); //select object
  j('.breakpoint-MOBILE, .breakpoint-MOBILE_HORIZONTAL').stop().animate(
    {
      scrollTop: $dashItem.position().top,
    },
    1000
  );
};
