import styles from './CockpitManagement.module.css';

import React, { useEffect, useMemo, useState } from 'react';

import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';
import Icon from 'components/ui/common/Icon';
import BngDropdown from 'components/bng/ui/BngDropdown';
import CockpitFormDialog from 'components/bng/pages/cockpit/management/CockpitFormDialog';
import { MODALS } from 'components/ui/redux/Actions';
import BngFloatActionButtonDropdown from 'components/bng/ui/BngFloatActionButtonDropdown';
import UiMsg from 'components/ui/UiMsg';
import OpConfirmation from 'components/ui/OpConfirmation';
import GroupsDialog from 'components/ui/group/GroupsDialog';
import Avatar from 'components/ui/Avatar';
import GroupRender from 'components/bng/pages/admin/structures/GroupRender';
import bngYup from 'components/bng/form/yup/BngYup';
import BngDropdownTagsWrapper, { fetchAndProcessGroups } from 'components/bng/ui/BngDropdownTagsWrapper';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useBimContext from 'components/hooks/useBimContext';
import LinkedObjects from 'components/ui/LinkedObjects';
import Utils from 'components/Utils';
import { checkAddonEnabled } from 'components/bng/accounts/addons/AddonDisabledDialog';
import AddonType from 'components/bng/accounts/AddonType';
import FilterDropdown from 'components/bng/pages/common/filter/FilterDropdown';
import CrudPageLayout from 'components/bng/pages/common/layout/CrudPageLayout';
import PublisherIncorporateLink from 'components/ui/publisher/PublisherIncorporateLink';
import BngUserCockpitConfDialog from 'components/bng/pages/cockpit/BngUserCockpitConfDialog';

let __LAST_FETCHED_COCKPITS = [];

export default function CockpitManagement() {
  const { msg, project } = useBimContext();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ searchTerm: '', filterButton: {} });
  const [cockpits, setCockpits] = useState(__LAST_FETCHED_COCKPITS);
  const [tableSortMode, setTableSortMode] = useState({ name: 'ASC' });
  const [userAvatarPerCockpit, setUserAvatarPerCockpit] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [groupNameList, setGroupNameList] = useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await Api.Cockpit.findCockpitsList(project.name);
      await userPermissionAvatarBriefInfo(data);
      setCockpits(data);
      __LAST_FETCHED_COCKPITS = data;

      const groups = await Api.Group.findForProject(project.id);
      const indexedGroups = groups.reduce((acc, group) => {
        acc[group.id] = group;
        return acc;
      }, {});
      setGroupNameList(indexedGroups);
    } catch (e) {
      console.error('Error on CockpitManagement.fetchData()', e);
      UiMsg.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const userPermissionAvatarBriefInfo = async (data) => {
    const list = data.map((i) => i.id ?? i.cockpitId);
    const permissions = await Api.Cockpit.getProjectCockpitsPermissions(list);
    const memberList = [];
    permissions.forEach((item, idx) => {
      if (item === null || item?.publicAccess) {
        memberList.push({ cockpitId: list[idx] });
      } else {
        memberList.push({
          cockpitId: list[idx],
          users: item.members.sort((o1, o2) =>
            Utils.Strings.compareIgnoreCase(Utils.Users.displayName(o1), Utils.Users.displayName(o2))
          ),
        });
      }
    });
    setUserAvatarPerCockpit(memberList);
  };

  const filteredCockpits = filterCockpits(cockpits, filters, tableSortMode);

  const reorderAccessList = (startIndex, endIndex) => {
    const result = userAvatarPerCockpit.slice();
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const listToUpdate = result.map((item, index) => {
      return item;
    });
    setUserAvatarPerCockpit(listToUpdate);
  };

  const reorder = (startIndex, endIndex) => {
    const result = cockpits.slice();
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const listToUpdate = result.map((item, index) => {
      item.itemIdx = index + 1;
      return {
        cockpitId: item.id,
        orderIdx: index + 1,
      };
    });
    reorderAccessList(startIndex, endIndex);
    Api.Cockpit.updateCockpitsOrder(listToUpdate);
    setCockpits(result);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const fromIdx = result.source.index;
    const toIdx = result.destination.index;

    if (fromIdx === toIdx) return;

    reorder(fromIdx, toIdx);
  };

  const showGroupRender = (groupId) => {
    const group = groupNameList[groupId];
    if (!group) return null;

    return <GroupRender name={group.name} color={group.color} className={styles.tag} />;
  };

  const tableColumns = useMemo(() => {
    return buildTableColumns({ fetchData, setSelectedRow, showGroupRender, selectedRow, userAvatarPerCockpit });
  }, [selectedRow, userAvatarPerCockpit, showGroupRender]);

  const headerButtons = () => {
    return (
      <div className="flex-center-items">
        <CockpitFilterDropDown
          onChange={(values) => setFilters({ ...filters, filterButton: values })}
          initialValues={filters.filterButton}
        />
      </div>
    );
  };

  return (
    <div className="CockpitPage">
      <CrudPageLayout
        fetchData={fetchData}
        filters={filters}
        tableRows={filteredCockpits}
        loading={loading}
        emptyAlertMessage={msg.t('currently.no.cockpit.available')}
        pageTitle={msg.t('cockpits')}
        tableSortMode={tableSortMode}
        setFilters={setFilters}
        tableColumns={tableColumns}
        selectedRows={selectedRow}
        headerButtons={headerButtons}
        setTableSortMode={setTableSortMode}
        onDropHandler={onDragEnd}
        pageButton={() => <FloatButton fetchData={fetchData} />}
      />
    </div>
  );
}

const FloatButton = ({ fetchData }) => {
  const dispatch = useReduxDispatch();
  const { msg, project, permissions } = useBimContext();

  const customOptions = [
    {
      icon: 'icon-plus',
      className: `success ${styles.addCockpitButton}`,
      onClick: () =>
        dispatch(
          MODALS.open(CockpitFormDialog, {
            fetchData: () => fetchData(),
          })
        ),
      label: msg.t('new.cockpit'),
    },
    {
      icon: 'local_offer',
      className: `success ${styles.addCockpitButton}`,
      onClick: () => {
        dispatch(
          MODALS.open(GroupsDialog, {
            projectId: project.id,
            fetchData: fetchData,
          })
        );
      },
      label: msg.t('tags'),
    },
  ];

  if (permissions.isAdmin()) {
    customOptions.push({
      icon: 'icon-user',
      className: `success ${styles.addCockpitButton}`,
      onClick: () => {
        dispatch(MODALS.open(BngUserCockpitConfDialog));
      },
      label: msg.t('users.cockpit.config'),
    });
  }

  return <BngFloatActionButtonDropdown customOptions={customOptions} />;
};

const buildTableColumns = ({ fetchData, setSelectedRow, showGroupRender, selectedRow, userAvatarPerCockpit }) => {
  const dispatch = useReduxDispatch();
  const { msg } = useBimContext();

  return [
    {
      size: '1%',
      render: (row, idx, rowProps, dragProps) => (
        <div className={` ${styles.draggableIcon}`} {...dragProps.dragHandleProps}>
          <Icon icon="drag_indicator" />
        </div>
      ),
      rowClassName: styles.draggableTd,
    },
    {
      label: msg.t('name'),
      colClassName: styles.columnHeadName,
      onClick: (item, idx) => openCockpitSettings(item, fetchData, dispatch, setSelectedRow, idx),
      render: (item, idx, rowProps, dragProps) => (
        <div className={` ${styles.draggableIcon}`}>
          <Icon className={styles.listIcons} icon={item.icon} />
          <span className={`${styles.nameWrapper} `}>
            <div className={`${styles.cockpitName}`}>{item.name}</div>
          </span>
          {showGroupRender(item.groupId)}
        </div>
      ),
      rowClassName: styles.nameTd,
    },
    {
      label: msg.t('responsible'),
      colClassName: styles.columnHeadOwner,
      onClick: (item, idx) => openCockpitSettings(item, fetchData, dispatch, setSelectedRow, idx),
      render: (item, idx, rowProps, dragProps) => (
        <div className="div-information-cockpit">
          <span className="cockpit-item-slide-name">
            {item.ownerName.length > 0 && (
              <div className={'nameOwnerWrapper'}>
                <div>{item.ownerName}</div>
              </div>
            )}
          </span>
        </div>
      ),
      rowClassName: styles.ownerTd,
    },
    {
      label: msg.t('cockpit.management.linked.objects'),
      colClassName: styles.columnHeadLinkedObjects,
      onClick: (item, idx) => openCockpitSettings(item, fetchData, dispatch, setSelectedRow, idx),
      render: (item, idx, rowProps, dragProps) => (
        <div className="div-information-cockpit w-100">
          <span className="cockpit-item-slide-name w-100">
            <LinkedObjects
              itemWidth={50}
              items={item.panels}
              render={(linkedItem) => {
                return (
                  <div key={linkedItem.id}>
                    <Icon
                      className={`${idx === selectedRow ? styles.selectedRowBriefIcon : styles.briefIcon}`}
                      icon={linkedItem.icon}
                      title={linkedItem.name}
                    />
                  </div>
                );
              }}
            />
          </span>
        </div>
      ),
      rowClassName: styles.objectsLinked,
    },
    {
      label: msg.t('cockpit.management.access.permissions'),
      colClassName: styles.columnHeaderAccessPermissions,
      onClick: (item, idx) => openCockpitPermissions(fetchData, item, setSelectedRow, idx),
      render: (item, idx, rowProps, dragProps) => {
        const cockpitPermissions = userAvatarPerCockpit.find((i) => i.cockpitId === item.id);
        return (
          <div className="div-information-cockpit w-100">
            <span className="cockpit-item-slide-name w-100">
              <LinkedObjects
                itemWidth={50}
                items={cockpitPermissions?.users || []}
                render={(user) => {
                  return (
                    <div
                      key={`${user.container ? 'GROUP' : 'USER'}-${user.id}`}
                      className={`${styles.userAvatarOuter} ${!user.avatarLink ? styles.groupIcon : ''}`}
                      title={Utils.Users.displayName(user)}
                    >
                      {user.avatarLink ? (
                        <Avatar userId={user.id} className={`${styles.userAvatar}`} />
                      ) : (
                        <Icon icon="group" style={{ fontSize: '25px' }} />
                      )}
                    </div>
                  );
                }}
              />
            </span>
          </div>
        );
      },
      rowClassName: styles.accessPermissions,
    },
    {
      size: '1%',
      render: (item, idx, rowProps, dragProps) => (
        <Option row={item} rowIndex={idx} fetchData={fetchData} setSelectedRow={setSelectedRow} />
      ),
      rowClassName: styles.options,
    },
  ];
};

const CockpitFilterDropdownSchema = bngYup((yup) => {
  return yup.object().shape({
    tag: yup.string().nullable().default(''),
  });
});

const CockpitFilterDropDown = ContextEnhancer(({ context: { project, msg }, onChange = _.noop, initialValues }) => {
  const [groupOpts, setGroupOpts] = useState([]);
  const [loading, setLoading] = useState(false);

  const initialFormValues = useMemo(() => _.merge({}, CockpitFilterDropdownSchema.default(), initialValues), [
    initialValues,
  ]);

  const fetchGroups = async () => {
    setLoading(true);
    try {
      const groupOpts = await fetchAndProcessGroups(project.id, msg);
      setGroupOpts(groupOpts);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const dropdownFields = [
    {
      name: 'tag',
      options: groupOpts,
    },
  ];

  return (
    <FilterDropdown
      fields={dropdownFields}
      initialFormValues={initialFormValues}
      dropdownSchema={CockpitFilterDropdownSchema}
      onChange={onChange}
      loading={loading}
      onOpen={() => {
        if (_.isEmpty(groupOpts)) {
          fetchGroups();
        }
      }}
    />
  );
});

const filterCockpits = (cockpits, filters, tableSortMode) => {
  const {
    searchTerm,
    filterButton: { tag },
  } = filters;
  if (tag) {
    const tagId = _.parseInt(tag);
    if (tagId) {
      cockpits = cockpits.filter((s) => s.groupId === tagId);
    } else {
      cockpits = cockpits.filter((s) => !s.groupId);
    }
  }
  if (searchTerm) {
    cockpits = cockpits.filter((cockpits) =>
      [cockpits.name, cockpits.ownerName].some((n) => n.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }
  Object.entries(tableSortMode).forEach(([direction]) => {
    if (direction === 'NONE') return;

    cockpits = _.orderBy(cockpits, ['order'], ['asc']);
  });
  return cockpits;
};

const openCockpitSettings = (row, fetchData, dispatch, setSelectedRow, index) => {
  // Meau para manter efeito de seleção quando aberto através do menu
  window.requestAnimationFrame(() => setSelectedRow(index));
  dispatch(
    MODALS.open(CockpitFormDialog, {
      cockpitId: row.id,
      fetchData: () => fetchData(),
      setSelectedRow: setSelectedRow,
    })
  );
};

const openCockpitPermissions = (fetchData, item, setSelectedRow, index) => {
  if (index !== undefined) {
    // Meau para manter efeito de seleção quando aberto através do menu
    window.requestAnimationFrame(() => setSelectedRow(index));
  }
  ComponentFactory.default.Permissions.openCockpitPermission({
    cockpit: {
      id: item.id,
      name: item.name,
    },
    onClose: () => fetchData(),
    setSelectedRow: () => setSelectedRow(undefined),
  });
};

const applyGroup = async (groupId, rowId, fetchData, closeDropdown) => {
  try {
    await Api.Cockpit.addGroup(groupId, rowId);
    await closeDropdown();
    await fetchData();
  } catch (e) {
    console.error(e);
    UiMsg.error(null, e);
  }
};

function Option({ row, fetchData, setSelectedRow, rowIndex }) {
  const { msg } = useBimContext();
  const dispatch = useReduxDispatch();
  const options = [
    [
      {
        label: msg.t('go.to.this.cockpit'),
        icon: 'exit_to_app',
        onClick: () => Api.Cockpit.goToCockpit(row.id),
      },
    ],
    [
      {
        label: msg.t('edit'),
        icon: 'settings',
        onClick: () => {
          openCockpitSettings(row, fetchData, dispatch, setSelectedRow, rowIndex);
        },
      },
      {
        label: msg.t('permissions'),
        icon: 'lock',
        onClick: () => {
          openCockpitPermissions(fetchData, row, setSelectedRow, rowIndex);
        },
      },
      {
        className: `${styles.tagsDropdownGroupOptionCockpit}`,
        closeOnSelect: false,
        render: ({ closeDropdown }) => {
          return (
            <BngDropdownTagsWrapper
              selectedGroupId={row.groupId}
              applyGroup={(item) => applyGroup(item?.group.id, row.id, fetchData, closeDropdown)}
              customButton={({ openDropdown }) => (
                <div onClick={openDropdown} className="item GroupOption" style={{ whiteSpace: 'nowrap' }}>
                  <Icon icon={`local_offer`} />
                  <span>{msg.t('add.tag')}</span>
                </div>
              )}
            />
          );
        },
      },
    ],
    [
      {
        label: msg.t('incorporate'),
        icon: 'public',
        onClick: () => {
          if (checkAddonEnabled(AddonType.PUBLISHER.key, true)) {
            openIncorporate();
          }
        },
      },
    ],
    [
      {
        label: msg.t('remove'),
        icon: 'delete_outline',
        onClick: () => {
          removeCockpit();
        },
      },
    ],
  ];

  const openIncorporate = async () => {
    dispatch(
      MODALS.open(PublisherIncorporateLink, {
        path: await Api.Cockpit.getCockpitPath(row.id),
        caption: row.name,
      })
    );
  };

  const removeCockpit = () => {
    OpConfirmation({
      title: row.name,
      message: msg.t('confirm.delete.cockpit'),
      msg: msg,
      onConfirm: async () => {
        const response = await Api.Cockpit.removeCockpit(row.id);
        fetchData();
        UiMsg.ok(msg.t(response));
      },
    });
  };

  return (
    <>
      <BngDropdown
        popperOpts={{ placement: 'bottom-end' }}
        popperClassName={`${styles.cockpitManagementMenuPopper} `}
        options={options}
        onOpen={() => setSelectedRow(rowIndex)}
        onClose={() => setSelectedRow(undefined)}
      />
    </>
  );
}
