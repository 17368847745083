import React from 'react';

import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import UiMsg from 'components/ui/UiMsg';
import { MODALS } from 'components/ui/redux/Actions';
import HtmlItemDialog from 'components/bng/pages/cockpit/management/HtmlItemDialog';
import useTranslation from 'components/hooks/useTranslation';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

export function useEditTextItem() {
  const { t } = useTranslation();
  const dispatch = useReduxDispatch();
  const addChange = useDashboardPageCtx.cached(({ addChange }) => addChange);

  return async ({ type = 'TEXT', itemId, previousContent = '' } = {}) => {
    try {
      dispatch(
        MODALS.open(HtmlItemDialog, {
          title: t('component.content'),
          showNameField: false,
          editorOptions:
            type === 'NEW_TEXT'
              ? {
                  controls:
                    'font size | bold italic strikethrough underline | bullets numbering outdent indent | textColorPicker bgColorPicker | undo redo',
                }
              : undefined,
          itemData: {
            content: previousContent,
          },
          onSave: async ({ content }) => {
            await addChange({
              type: 'TEXT',
              data: {
                itemId,
                type,
                content: content || '',
              },
            });
          },
        })
      );
    } catch (e) {
      console.error('Error on useEditTextItem()', e);
      UiMsg.ajaxError(null, e);
    }
  };
}

export default function DashboardItemMenuEditText({ item }) {
  const { t } = useTranslation();
  const editTextItem = useEditTextItem();
  return (
    <li
      onClick={() => {
        editTextItem({
          type: item.viewType === 'textNew' ? 'NEW_TEXT' : 'TEXT',
          itemId: item.id,
          previousContent: item.additionalProps?.content || '',
        });
      }}
    >
      <DashboardItemMenuTitle title={t('edit.content')} icon="fa-pencil-square" more={false} />
    </li>
  );
}
