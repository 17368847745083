import './MobileMenuItem.css';

import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import useTranslation from 'components/hooks/useTranslation';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

export default function MobileMenuItem({ clearAccordion = _.noop, ...props }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { viewType, changeViewType } = useDashboardPageCtx.cached(({ viewType, changeViewType }) => ({
    viewType,
    changeViewType,
  }));

  const isDesktop = viewType() === 'DESKTOP';

  return (
    <RightMenuItem
      title={t(isDesktop ? 'view.mobile' : 'view.desktop')}
      icon={isDesktop ? 'phone_android' : 'icon-desktop'}
      className="MobileMenuItem"
      onClick={async () => {
        clearAccordion();
        await changeViewType(isDesktop ? 'MOBILE' : 'DESKTOP', searchParams, setSearchParams);
      }}
      {...props}
    />
  );
}
