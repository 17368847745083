import { Field, Formik } from 'formik';

import BngForm from 'components/bng/form/BngForm';
import { BngPrimaryButton } from 'components/ui/Button';
import useTranslation from 'components/hooks/useTranslation';
import bngYup from 'components/bng/form/yup/BngYup';
import BngField from 'components/bng/form/BngField';
import BngFolderFieldInput from 'components/bng/form/BngFolderFieldInput';
import BngSwitch from 'components/bng/form/BngSwitch';
import BngTextArea from 'components/bng/form/BngTextArea';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import UiBlocker from 'components/bng/ui/UiBlocker';

const formSchema = bngYup((yup) =>
  yup.object({
    folder: yup.string().required().trim().ensure().default(''),
    name: yup.string().required().min(1).max(100).trim().ensure().validObjectName().default(''),
    description: yup.string().required().min(1).max(2048).trim().ensure().default(''),
    mobile: yup.boolean().default(true),
  })
);

export default function NewDashboardPage() {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={formSchema.default()}
      validationSchema={formSchema}
      onSubmit={async (values, formikHelpers) => {
        try {
          const dashboard = await Api.Dash.save({
            newDash: values,
            changes: [],
          });
          const url = Api.loadObjectUrl({ content: dashboard.path, edit: true });
          window.location.replace(url);
        } catch (e) {
          if (e.response?.status === 409) {
            UiMsg.warn(t('name.already.in.use.chose.another'));
          } else {
            console.error('Error while saving dashboard', { values }, e);
            UiMsg.ajaxError(null, e);
          }
          formikHelpers.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <UiBlocker
            className="NewDashboardPage position-relative d-flex jc-center RouterPage"
            block={isSubmitting}
          >
            <BngForm id="new-dashboard" className="flex-grow-1 mt-3" style={{ maxWidth: '800px' }}>
              <Field name="folder" component={BngField} inputComponent={BngFolderFieldInput} required />
              <Field name="name" component={BngField} required />
              <Field name="description" component={BngField} inputComponent={BngTextArea} rows={4} required />
              <Field name="mobile" component={BngField} inputComponent={BngSwitch} />
              <div className="flex-center-items jc-end">
                <BngPrimaryButton type="sumbit">{t('save')}</BngPrimaryButton>
              </div>
            </BngForm>
          </UiBlocker>
        );
      }}
    </Formik>
  );
}
