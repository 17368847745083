import React from 'react';
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import UiMsg from 'components/ui/UiMsg';
import useTranslation from 'components/hooks/useTranslation';
import DashboardTextForm from 'components/ui/dashboard/components/DashboardTextForm';
import { MODALS } from 'components/ui/redux/Actions';

export default function DashboardItemMenuEditLabel({ item }) {
  const { t } = useTranslation();
  return (
    <li
      onClick={async () => {
        try {
          window.ReduxStore.dispatch(
            MODALS.open(DashboardTextForm, {
              item,
            })
          );
        } catch (e) {
          console.error(e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      <DashboardItemMenuTitle title={t('edit.content')} icon="fa-pencil-square" more={false} />
    </li>
  );
}
