import { useEffect, useRef, useState } from 'react';

const DEFAULT_SELECTOR = (state) => {
  return state;
};

const DEFAULT_OPTS = {
  listenToChanges: true,
};

export default function useReduxSelector(selector = DEFAULT_SELECTOR, opts = DEFAULT_OPTS) {
  const [value, setValue] = useState();
  const ref = useRef();

  opts = { ...DEFAULT_OPTS, ...(opts ?? {}) };
  const listenToChanges = opts.listenToChanges ?? true;

  useEffect(() => {
    const unsubscribe = window.ReduxStore.subscribe(() => {
      const newValue = selector(window.ReduxStore.getState());
      ref.current = newValue;

      if (listenToChanges && !_.isEqual(newValue, value)) {
        setValue(newValue);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [listenToChanges]);

  return listenToChanges ? value : ref;
}
