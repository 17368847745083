import './SaveAsDialog.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Formik } from 'formik';

import Dialog from 'components/ui/Dialog';
import { DefaultDialogActions } from 'components/ui/FormUtils';
import ContextEnhancer from 'components/ContextEnhancer';
import Api from 'components/Api';
import UiMsg from 'components/ui/UiMsg';
import bngYup from 'components/bng/form/yup/BngYup';
import BngField from 'components/bng/form/BngField';
import BngTextArea from 'components/bng/form/BngTextArea';
import BngForm from 'components/bng/form/BngForm';
import BngFolderField from 'components/bng/form/BngFolderField';
import Utils from 'components/Utils';

const SaveAsSchema = bngYup((yup) => {
  return yup.object().shape({
    folder: yup.string().required().typeError('no.dir.object.error'),
    name: yup.string().required().min(1).max(100).trim().validObjectName().default(''),
    description: yup.string().required().default(''),
  });
});

export const optionsFromRoots = (folders, opts = [], foldersToIgnore = []) => {
  if (!!folders) {
    for (let item of folders) {
      if (item.leaf || !item.canWrite || foldersToIgnore.includes(item.path)) continue;
      const depth = item.path.split('/').length - 3;
      const label = `${_.repeat(' - ', depth)} ${item.text}`;
      opts.push({ value: item.path, label, icon: item.icon, depth });
      optionsFromRoots(item.children, opts, foldersToIgnore);
    }
  }
  return opts;
};

export const overwriteConfirmation = (msg) => {
  return new Promise((resolve) => {
    Swal.fire({
      title: msg.t('attention'),
      text: msg.t('overwrite.alert.message'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: msg.t('overwrite'),
      cancelButtonText: msg.t('cancel'),
    }).then((result) => {
      resolve(result.value);
    });
  });
};

export const validateAndConfirmPath = async ({ values, props, actions, isSimpleSave = false, muteMessages = false }) => {
  const result = {
    proceed: true,
    overwrite: false,
  };

  try {
    const { path: pathInUse, exists } = await Api.Project.nameAlreadyInUse(values);
    const isFromDifferentFolder = props.folder !== values.folder;
    const objectWasChanged = props.path !== values.path || props.name !== values.name || isFromDifferentFolder;
    const isNameDifferent = props.name.toUpperCase() !== values.name.toUpperCase();

    const isSameObject = !isNameDifferent && !isFromDifferentFolder && props.name === values.name;

    if (exists && !objectWasChanged && (pathInUse !== values.path || isSimpleSave)) {
      if(!muteMessages) {
        UiMsg.ok(props.context.msg.t('object.save.success'));
      }
    } else if (exists) {
      if (isNameDifferent || (isFromDifferentFolder && !isNameDifferent) || isSameObject) {
        result.overwrite = await overwriteConfirmation(props.context.msg);
        if (result.overwrite) {
          result.pathInUse = pathInUse;
        } else {
          result.proceed = false;
        }
      } else {
        if (!isSimpleSave) {
          if(!muteMessages) {
            UiMsg.warn(props.context.msg.t('object.name.exists'));
          }
        }
        result.proceed = false;
      }
    }
  } catch (e) {
    if (e.status === 400) {
      actions.setFieldError('name', props.context.msg.t('object.name.cannot.contain.special.chars.only'));
    } else {
      console.error('Error on validateAndConfirmPath()', e);
      UiMsg.ajaxError(null, e);
    }
    result.proceed = false;
  }
  return result;
};

class SaveAsDialog extends Component {
  static propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    path: PropTypes.string,
    folder: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  };

  static defaultProps = {
    onSubmit: () => Promise.resolve(true),
    path: '',
    folder: '',
    name: '',
    description: '',
  };

  state = {
    availableFolders: [],
  };

  constructor(props) {
    super(props);
  }

  initialFormValues = SaveAsSchema.default();

  componentDidMount() {
    Api.updateJsf();
    this.buildConfigData(this.props);
  }

  buildConfigData = (props) => {
    this.initialFormValues.folder = props.folder;
    this.initialFormValues.name = props.name;
    this.initialFormValues.description = props.description;
  };

  save = async (values, actions) => {
    values = {
      projectId: this.props.context.project.id,
      path: this.props.path,
      ...values,
    };

    const { proceed, overwrite, pathInUse } = await validateAndConfirmPath({ values, props: this.props, actions });

    if (!proceed) {
      actions.setSubmitting(false);
      return;
    }

    // Dashboard saveAs method handles the overwrite
    if (overwrite && !Utils.Object.isDashboard(pathInUse)) {
      try {
        await Api.Project.removePath({ projectId: values.projectId, path: pathInUse });
      } catch (e) {
        console.error(e);
        UiMsg.ajaxError(null, e);
        actions.setSubmitting(false);
        return;
      }
    }

    try {
      const result = await this.props.onSubmit(values);
      if (result && result.path) {
        window.location = Api.loadObjectUrl({ content: result.path });
      } else {
        this.props.closeModal();
      }
    } catch (e) {
      console.error(e);
      UiMsg.ajaxError(null, e);
      actions.setSubmitting(false);
    }
  };

  render() {
    return (
      <Formik
        initialValues={this.initialFormValues}
        innerRef={(el) => (this.$formik = el)}
        validationSchema={SaveAsSchema}
        onSubmit={this.save}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          return (
            <Dialog
              open={this.props.open}
              className="SaveAsDialog"
              title={this.props.context.msg.t('save.as.dialog.title')}
              onClose={this.props.closeModal}
              newDialogLayout={true}
              loading={isSubmitting}
            >
              <BngForm>
                <Dialog.Body>
                  <BngFolderField className="SaveAsFolderField" name="folder" initialValue={this.props.folder} />

                  <div className="row-fluid">
                    <div className="span12">
                      <Field
                        label={`${this.props.context.msg.t('name')}`}
                        name="name"
                        type="text"
                        className="folder-text"
                        component={BngField}
                      />
                    </div>
                  </div>

                  <div className="row-fluid">
                    <div className="span12">
                      <Field
                        label={`${this.props.context.msg.t('description')}`}
                        name="description"
                        rows="5"
                        rootClassName="folder-description"
                        inputComponent={BngTextArea}
                        component={BngField}
                      />
                    </div>
                  </div>
                </Dialog.Body>
                <Dialog.Footer>
                  <DefaultDialogActions {...this.props} />
                </Dialog.Footer>
              </BngForm>
            </Dialog>
          );
        }}
      </Formik>
    );
  }
}

export default ContextEnhancer(connect()(SaveAsDialog));
