import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';
import useFetchData from 'components/hooks/useFetchData';

const internalCache = {};

const findFilters = async (projectId) => {
  if (internalCache[projectId] && Date.now() - internalCache[projectId].t < 30000) {
    return internalCache[projectId].data;
  }

  internalCache[projectId] = {
    data: new Promise(async (res, rej) => {
      try {
        const mdxGlobalFilters = await Api.MdxGlobalFilter.findAll(projectId, true);
        res(
          mdxGlobalFilters.map((mgf) => {
            return {
              id: mgf.id,
              caption: mgf.displayName || mgf.name,
              name: mgf.name,
              timeFilter: mgf.type === 'TIME',
              datasources: mgf.mdxFilters.map((mf) => mf.datasource),
            };
          })
        );
      } catch (e) {
        rej(e);
      }
    }),
    t: Date.now(),
  };

  return internalCache[projectId].data;
};

export default function useProjectFilters() {
  const context = useBimContext();

  const $projectFilters = useFetchData(
    async ([projectId]) => {
      return await findFilters(projectId);
    },
    [context.project.id]
  );

  $projectFilters.data = $projectFilters.data || [];

  return $projectFilters;
}
