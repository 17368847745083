// Text.css imported on CommonCssImports.js
import React from 'react';
import PropTypes from 'prop-types';
import { ResizeSensor } from 'css-element-queries';
import UiBlocker from 'components/bng/ui/UiBlocker';
import { DashSkipOnResizeComponent, isEditingLayout } from 'components/ui/dashboard/DashShared';

class Text extends DashSkipOnResizeComponent {
  static propTypes = {
    onClick: PropTypes.func,
    labelFontSize: PropTypes.any,
    autoresize: PropTypes.any,
    fontsize: PropTypes.any,
    transparentbackground: PropTypes.any,
    colorbackground: PropTypes.any,
    labelFontStyle: PropTypes.any,
    labelColor: PropTypes.any,
    position: PropTypes.any,
    aling: PropTypes.any,
    font: PropTypes.any,
    color: PropTypes.any,
    bold: PropTypes.any,
    labelFont: PropTypes.any,
    value: PropTypes.any,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    onClick: _.noop,
    position: '',
    aling: '',
    loading: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.updateSize();
    this._sensor = new ResizeSensor(
      this.div,
      _.debounce(() => {
        this.updateSize();
      }, 500)
    );
  }

  componentDidUpdate() {
    if(isEditingLayout()) {
      return;
    }
    this.updateSize();
  }

  componentWillUnmount() {
    if (this._sensor) {
      try {
        this._sensor.detach();
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async updateSize() {
    if (!this.div) {
      return;
    }

    const childImg = this.div.querySelector('img');
    if (childImg) {
      for (let i = 0; i < 50; i++) {
        if (childImg.complete) {
          break;
        }
        await new Promise((res) => setTimeout(res, 100));
      }

      childImg.style.maxHeight = '';
      childImg.style.maxWidth = '';
    }

    this.div.style.fontSize = '';
    this.icon.style.fontSize = '';
    this.labelIcon.style.fontSize = '';

    if (this.props.labelFontSize !== 0) {
      this.labelIcon.style.fontSize = this.props.labelFontSize + 'px';
    }

    await new Promise((res) => requestAnimationFrame(res));

    if (!this.div) {
      return;
    }

    if (!Boolean(this.props.autoresize)) {
      this.div.style.fontSize = '1em';
      this.div.style.overflow = 'auto';

      let x, y;

      if (childImg) {
        if (['TOP', 'BOTTOM'].includes(this.props.position)) {
          const proportion = this.props.label ? 1.4 : 1;
          childImg.style.maxHeight = `${Math.ceil(
            this.props.labelFontSize
              ? this.div.clientHeight - this.props.labelFontSize
              : this.div.clientHeight / proportion
          )}px`;
          childImg.style.maxWidth = `${this.div.clientWidth}px`;
        } else {
          const proportion = this.props.label ? 1.6 : 1;
          childImg.style.maxWidth = `${Math.ceil(
            this.props.labelFontSize
              ? this.div.clientWidth - this.props.labelFontSize
              : this.div.clientWidth / proportion
          )}px`;
          childImg.style.maxHeight = `${this.div.clientHeight}px`;
        }
        childImg.style.width = childImg.style.maxWidth;
      }

      const textNode = this.div.childNodes[0];

      let i = this.div.parentElement.clientHeight / textNode.clientHeight || 1; //initial proportion
      do {
        i += 1;
        if (i > 99) {
          break;
        }

        if (this.props.labelFontSize === 0) {
          let labelFontSize = i * 0.02;
          this.labelIcon.style.fontSize = labelFontSize + 'em';
        }

        this.div.style.fontSize = i + 'em';

        x = this.div.scrollWidth <= this.div.clientWidth;
        y = this.div.scrollHeight <= this.div.clientHeight;
      } while (x && y);

      do {
        i -= 0.1;
        if (i < 0.1 || !isFinite(i)) {
          break;
        }

        if (this.props.labelFontSize === 0) {
          let labelFontSize = i * 0.02;
          this.labelIcon.style.fontSize = labelFontSize + 'em';
        }

        this.div.style.fontSize = i + 'em';

        x = this.div.scrollWidth > this.div.clientWidth;
        y = this.div.scrollHeight > this.div.clientHeight;
      } while (x || y);
    } else {
      this.div.style.fontSize = this.props.fontsize + 'px';
      this.icon.style.fontSize = this.props.fontsize + 'px';
      const maxHeight = this.div.parentElement.clientHeight;
      this.div.style.maxHeight = maxHeight + 'px';
    }

    this.div.style.overflow = 'hidden';
    this.labelIcon.style.fontWeight = this.props.labelFontStyle === 'bold' ? this.props.labelFontStyle : '400';
    this.labelIcon.style.fontStyle =
      this.props.labelFontStyle === 'italic' || this.props.labelFontStyle === 'normal' ? this.props.labelFontStyle : '';
    this.labelIcon.style.textDecoration = this.props.labelFontStyle === 'underline' ? this.props.labelFontStyle : '';

    if (!this.div.classList.contains('Highlight')) {
      this.labelIcon.style.color = this.props.labelColor;
    }
  }

  render() {
    const className = `dashboard-responsive-text ${this.props.className || ''} Position${this.props.position} Align${
      this.props.aling
    }`;
    let content;
    if (this.props.autoresize || this.props.autoresize === 'true') {
      content = (
        <div
          onClick={this.props.onClick}
          className={className}
          style={{ textAlign: this.props.aling }}
          ref={(el) => (this.div = el)}
        >
          <div className="inline-block">
            <div className={`${this.props.font || ''} TextValue`}>
              <label
                ref={(el) => (this.icon = el)}
                className={`dashboard-label-value`}
                style={{
                  color: this.props.color,
                  fontSize: this.props.fontsize,
                  fontWeight: this.props.bold === 'true' || this.props.bold === true ? 'bold' : '',
                }}
              >
                {this.props.value}
              </label>
            </div>
            <div
              ref={(el) => (this.labelIcon = el)}
              className={`${this.props.position} TextLabel ${this.props.labelFont || ''}`}
            >
              {this.props.label}
            </div>
          </div>
        </div>
      );
    } else {
      content = (
        <div
          onClick={this.props.onClick}
          className={className}
          style={{ color: this.props.color, textAlign: this.props.aling }}
          ref={(el) => (this.div = el)}
        >
          <div className="inline-block">
            <div
              ref={(el) => (this.icon = el)}
              style={{
                whiteSpace: 'nowrap',
                fontWeight: this.props.bold === 'true' || this.props.bold === true ? 'bold' : '',
              }}
              className={`${this.props.font || ''} TextValue`}
            >
              {this.props.value}
            </div>
            <div
              ref={(el) => (this.labelIcon = el)}
              className={`${this.props.position} TextLabel ${this.props.labelFont || ''}`}
            >
              {this.props.label}
            </div>
          </div>
        </div>
      );
    }

    const backgroundColor = this.props.transparentbackground ? 'transparent' : this.props.colorbackground;
    return (
      <UiBlocker
        className="dashboard-responsive-text-container fill-w fill-h"
        block={this.props.loading}
        style={{ backgroundColor }}
      >
        {content}
      </UiBlocker>
    );
  }
}

export default Text;
