// Imported on CommonCssImports.js import './BngEmpty.css';

import React from 'react';
import Api from 'components/Api';
import useBimContext from 'components/hooks/useBimContext';

export default function BngEmpty({
  className = '',
  showTitle = true,
  title,
  showMessage = true,
  message,
  isEmpty = false,
  children,
  img,
  showImage = true,
  ...props
}) {
  const context = useBimContext();
  if (!isEmpty) {
    return children;
  }

  if (showTitle && _.isString(title)) {
    title = context.msg.t(title);
  }

  if (showMessage && _.isString(message)) {
    message = context.msg.t(message);
  }

  return (
    <div className={`BngEmpty ${className}`} {...props}>
      <div className="Container">
        {showImage && <img className="Image" src={img || `${Api.baseUrl()}/resources/images/empty.png`} alt="Empty" />}
        {showTitle && <div className="Title">{title || context.msg.t('BngEmpty.title')}</div>}
        {showMessage && (
          <div className={`Message ${!showTitle ? 'mt-3' : ''}`}>{message || context.msg.t('BngEmpty.message')}</div>
        )}
      </div>
    </div>
  );
}
