import React from 'react';
import { Outlet } from 'react-router-dom';

import Api from 'components/Api';
import { UiMsgContainer } from 'components/ui/UiMsg';
import useBimContext from 'components/hooks/useBimContext';
import GlobalLoader from 'bng/common/GlobalLoader';

export function NavbarInner() {
  const context = useBimContext();

  const toolbarLogo = context?.theme?.toolbarLogo ?? '/resources/images/logo-new.png';
  const logoUrl = Api.buildUrl(toolbarLogo);
  return (
    <div className={`navbar-new navbar no-print`} style={{ marginBottom: 'auto' }}>
      <div className="navbar-inner">
        <div className="container-fluid" style={{ height: '0px' }}>
          <a href="#" className="brand" id="logo-project">
            <img src={logoUrl} className="navbarLogo" alt="logo" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default function PublicPageLayout() {
  return (
    <>
      <NavbarInner />
      <Outlet />
      <UiMsgContainer />
      <GlobalLoader/>
    </>
  );
}
