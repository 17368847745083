import React from 'react';

import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import UiMsg from 'components/ui/UiMsg';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import useTranslation from 'components/hooks/useTranslation';

export default function DashboardItemMenuShowMDX({ item }) {
  const { t } = useTranslation();
  const itemState = useDashboardPageCtx.cached(({ itemState }) => itemState);

  const state = itemState[item.id];

  return (
    <li
      onClick={async () => {
        try {
          let html = '';
          if (state?.geoMdx) {
            html = `<div>
                    <div>
                        <h5 class="m-0">${t('map.mdx.points')}</h5>
                        <pre>${state.geoMdx}</pre>
                    </div> 
                    <div>
                        <h5 class="m-0">${t('map.mdx.data')}</h5>
                        <pre>${state.dataMdx}</pre>
                    </div>
                </div>`;
          } else {
            html = `<pre style="max-height: 350px; overflow-y: auto;">${state?.mdx}</pre>`;
          }
          bootbox.alert(`<div style="padding: 0 5px 0 5px;">${html}</div>`);
        } catch (e) {
          console.error(e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      <DashboardItemMenuTitle title={t('see.mdx')} icon="icon-align-justify" more={false} />
    </li>
  );
}
