import React from 'react';

import UiMsg from 'components/ui/UiMsg';
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import useTranslation from 'components/hooks/useTranslation';
import Utils from 'components/Utils';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MODALS } from 'components/ui/redux/Actions';
import SaveAsDialogContainer from 'components/ui/common/SaveAsDialogContainer';

const cloneItem = async ({ addChange, t, item }, { folder = '', name = '', description = '' } = {}) => {
  if (item.viewType === 'container') {
    return;
  }

  try {
    await addChange({
      type: 'CLONE_ITEM',
      data: {
        itemId: item.id,
        folder,
        name,
        description,
      },
    });
    UiMsg.ok(t('object.clone.success'));
  } catch (e) {
    console.error('Error on DashboardItemMenuCloneObject.cloneItem()', e);
    UiMsg.ajaxError(null, e);
  }
};

export const cloneItemAction = ({ item, addChange, t, dispatch }) => {
  if (Utils.Object.isObjectPath(item.path)) {
    const folder = Utils.Object.parentFolder(item.path);
    dispatch(
      MODALS.open(SaveAsDialogContainer, {
        folder,
        onSubmit: (data) => cloneItem({ addChange, item, t }, data),
      })
    );
  } else {
    cloneItem({ addChange, item, t });
  }
};

export default function DashboardItemMenuCloneObject({ item }) {
  const dispatch = useReduxDispatch();
  const { t } = useTranslation();
  const addChange = useDashboardPageCtx.cached(({ addChange }) => addChange);

  const shorted = application.page.isMacOS() ? 'CMD+V' : 'CTRL+V';
  return (
    <li className="bt-item-new-menu-inside">
      <DashboardItemMenuTitle
        title={t('clone.object')}
        icon="reply_all"
        more={false}
        onClick={() => cloneItemAction({ addChange, t, dispatch, item })}
        shorted={shorted}
      />
    </li>
  );
}
