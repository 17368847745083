import React, { useEffect } from 'react';

import ECharts from 'components/bng/analysis/ECharts';
import Api from 'components/Api';
import useFetchData from 'components/hooks/useFetchData';
import { UiBlocker } from 'components/bng/ui/UiBlocker';

export default function AnalysisECharts({
  className = '',
  path = 'RUNTIME',
  filters,
  prefetchedData,
  renderDate,
  cockpitId,
  toolbarContainer,
  dashboardPath,
  drillState,
  onQueryResult = _.noop,
  ...props
}) {
  const $queryResult = useFetchData(async () => {
    if (prefetchedData?.queryResult) {
      return prefetchedData.queryResult;
    } else {
      return await Api.Analysis.queryResult({
        path,
        filters,
        publisherContent: dashboardPath,
        drillState,
      });
    }
  }, [path, filters, renderDate, drillState]);

  const $analysisData = useFetchData(
    async () =>
      prefetchedData?.analysis
        ? prefetchedData.analysis
        : await Api.Analysis.analysisData(path, cockpitId, dashboardPath),
    [path, renderDate]
  );

  useEffect(() => {
    if (!$queryResult.data) {
      return;
    }
    onQueryResult($queryResult.data);
  }, [$queryResult.data]);

  const isLoading = $analysisData.isLoading || $queryResult.isLoading;

  return (
    <UiBlocker
      className={`AnalysisECharts ${className} ExportAsync ${!isLoading ? 'ExportAsync-ready' : ''}`}
      block={isLoading}
    >
      <ECharts
        analysisData={$analysisData.data}
        queryResult={$queryResult.data}
        toolbarContainer={toolbarContainer}
        drillState={drillState}
        {...props}
      />
    </UiBlocker>
  );
}
