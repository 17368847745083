import React from 'react';
import PropTypes from 'prop-types';

function DefaultErrorRenderer({ error }) {
  return <pre>{error?.message ?? error?.toString()}</pre>;
}

export default class UiErrorHandler extends React.Component {
  static propTypes = {
    errorRenderer: PropTypes.func,
  };

  static defaultProps = {
    errorRenderer: DefaultErrorRenderer,
  };

  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    console.error('Error while rendering component tree', { info }, error);
  }

  render() {
    if (this.state.error) {
      return this.props.errorRenderer({ error: this.state.error });
    }

    return this.props.children;
  }
}
