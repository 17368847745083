import React from 'react';
import DashboardItemMenuTitle from 'components/ui/dashboard/components/itemMenus/DashboardItemMenuTitle';
import FilterAccordion from 'components/ui/dashboard/FilterAccordion';
import useTranslation from 'components/hooks/useTranslation';
import bimEventBus from 'BimEventBus';
import { RIGHT_MENU_TOGGLE_SUBMENU } from 'components/ui/right-menu/RightMenu';

export default function DashboardItemMenuCustomFilter({ item, closeDropdown }) {
  const { t } = useTranslation();
  return (
    <li className="bt-item-new-menu-inside">
      <DashboardItemMenuTitle
        title={t('custom.filters')}
        icon="icon-bim-filter"
        more={false}
        onClick={() => {
          bimEventBus.emit(RIGHT_MENU_TOGGLE_SUBMENU, {
            component: FilterAccordion,
            key: FilterAccordion.MENU_KEY,
            props: {
              customFilterState: true,
              customFilterAdd: item,
            },
          });
          closeDropdown();
        }}
      />
    </li>
  );
}
