import { Axios } from 'commonUtils';
import FilterService from 'components/filter/FilterService';
import Api from 'components/Api';

const $ = jQuery;

class DashApi {
  static renderItemMobile = (id, params = {}) => {
    let prefix = '/spr';
    if (window.location.pathname.indexOf('/api/') !== -1) {
      prefix = '/api';
    }
    return Promise.resolve($.postJSON(`${prefix}/stateless/ui/dashboards/render/${id}?t=${Date.now()}`, params));
  };

  static updateTable(id, params = {}) {
    return Promise.resolve($.post(`/spr/mobile/analysis/interaction`, params));
  }

  static async validateFilterMembers(dashboardPath, filters) {
    return await Axios.postData(`/spr/stateless/ui/dashboards/filters/validateMembers`, {
      dashboardPath,
      ...FilterService.createFilterParam(filters, true),
    });
  }

  // TODO centralize all on to useDashboardPageCtx???
  static async findItemMdxRef(pathOrId) {
    const cache = window.RENDERABLE_PRELOAD_CACHE ?? {};
    const match = cache[pathOrId] || Object.values(cache).find((r) => r?.additionalProps?.path === pathOrId);
    let key = '';
    if (match?.mdx) {
      const result = await Api.TemporaryStorage.put(match.mdx);
      key = result.key;
    }
    return key;
  }

  static loadDash = async ({ path, filter, changes = [], itemOverrides = {} }) => {
    return await Axios.postData(`/spr/stateless/ui/dashboards/load`, {
      path,
      filter,
      changes,
      itemOverrides,
    });
  };

  static findDashboardsUsingPath = async (path) => {
    return await Axios.getData(`/spr/dashboards/usingPath`, {
      params: {
        path,
      },
    });
  };

  static save = async (
    data = {
      newDash: {
        folder: '',
        name: '',
        description: '',
        mobile: true,
      },
      path: '',
      changes: [],
    }
  ) => {
    return await Axios.postData(`/spr/dashboards`, data);
  };

  static saveAs = async (data) => {
    return await Axios.postData(`/spr/dashboards/saveAs`, data);
  };

  static remove = async (path = '') => {
    return await Axios.delete(`/spr/dashboards`, {
      params: {
        path,
      },
    });
  };

  static validateChanges = async (data = { path: '', changes: [] }) => {
    return await Axios.postData(`/spr/dashboards/validateChanges`, data);
  };
}

export default DashApi;
