import React, { useState } from 'react';

import SelectObjectsCheckbox from 'components/ui/common/SelectObjectsCheckbox';
import SelectObjectDialogContainer from 'components/ui/common/SelectObjectDialogContainer';
import useBimContext from 'components/hooks/useBimContext';
import { BngInput } from 'components/bng/form/BngInput';
import UiMsg from 'components/ui/UiMsg';
import BngDocumentationHelp from "components/bng/ui/BngDocumentationHelp";
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

export default function DashboardItemLinksDialog({
  closeModal,
  dashItemId = '',
  currentLinks = [],
}) {
  const { msg } = useBimContext();

  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState(_.cloneDeep(currentLinks));
  const addChange = useDashboardPageCtx.cached(({addChange}) => addChange);

  return (
    <SelectObjectDialogContainer
      className={'DashboardItemLinksDialog TransparentBg RowFullWidth'}
      customDialogProps={{
        title: msg.t('select.some.links.to.this.item'),
        loading,
      }}
      closeModal={closeModal}
      onChangeListener={({ path: selectedPaths } = { path: [] }) => {
        let newLinks = _.cloneDeep(links);
        for (const p of selectedPaths) {
          const match = newLinks.find((c) => c.path === p);
          if (!match) {
            newLinks.push({
              path: p,
              caption: '',
            });
          }
        }
        newLinks = newLinks.filter((c) => selectedPaths.includes(c.path));
        setLinks(newLinks);
      }}
      customSaveHandler={async () => {
        setLoading(true);
        try {
          await addChange({
            type: 'UPDATE_ITEM_LINKS',
            data: {
              id: dashItemId,
              links,
            },
          });
          closeModal();
        } catch (e) {
          console.error('Error on DashboardItemLinksDialog while trying to save', e);
          UiMsg.ajaxError(null, e);
          setLoading(false);
        }
      }}
      typeField={(props) => {
          // Miau para prevenir o rerender dos inputs e perda de foco
        const [lastChange, setLastChange] = useState(Date.now());
        const isSelected =
          props.leaf && props.objectSelect.includes(props.href);
        const link = isSelected
          ? links.find((l) => l.path === props.href)
          : undefined;
        return (
          <div className="flex-center-items flex-grow-1">
            <SelectObjectsCheckbox {...props} />
            {(isSelected && link) && (
              <div className="ml-auto mr-2 flex-center-items" style={{gap: 10}}>
                <BngInput
                  className="mb-0"
                  style={{width: 230}}
                  value={link.caption ?? ''}
                  onChange={(event) => {
                    link.caption = event.target.value;
                    setLastChange(Date.now());
                  }}
                />

                <BngDocumentationHelp iconTitle={msg.t('DashboardItemLinksDialog.link.caption.help')}
                                      link={null}
                />
              </div>
            )}
          </div>
        );
      }}
      object={links.map((sc) => sc.path)}
    />
  );
}
