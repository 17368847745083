import React from 'react';
import { RightMenuItem } from 'components/ui/right-menu/RightMenu';
import FilterAccordion from 'components/ui/dashboard/FilterAccordion';
import useTranslation from 'components/hooks/useTranslation';

export default function FiltersMenuItem({ toggleAccordion, ...props }) {
  const { t } = useTranslation();

  return (
    <RightMenuItem
      {...props}
      title={t('configure.filters')}
      className="FiltersMenuItem"
      icon="icon-bim-filter"
      onClick={() => {
        toggleAccordion(FilterAccordion, FilterAccordion.MENU_KEY);
      }}
    />
  );
}
