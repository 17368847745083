import './MobileOrientationSwitch.css';

import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Icon from 'components/ui/common/Icon';
import OpConfirmation from 'components/ui/OpConfirmation';
import useBimContext from 'components/hooks/useBimContext';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';
import UiMsg from 'components/ui/UiMsg';

function MobileOrientationItem({ orientation, breakpointView, changeOrientation, icon }) {
  return (
    <div
      className={`MobileOrientation-${orientation} flex-center-items jc-center ${
        breakpointView === orientation ? 'active' : ''
      } `}
      onClick={() => changeOrientation(orientation)}
    >
      <Icon icon={icon} />
    </div>
  );
}

export default function MobileOrientationSwitch({ breakpointView = 'DESKTOP', horizontalLikeVertical = false }) {
  const context = useBimContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { addChange, changeViewType } = useDashboardPageCtx.cached(({ addChange, changeViewType }) => ({
    addChange,
    changeViewType,
  }));

  const changeOrientation = async (breakpoint) => {
    if (breakpointView === breakpoint) {
      return;
    }

    const executeChange = () => {
      changeViewType(breakpoint, searchParams, setSearchParams);
    };

    if (horizontalLikeVertical) {
      OpConfirmation({
        title: '',
        html: `<div>
                  <div style='margin-bottom: 10px'>${context.msg.t('mobile.orientation.switch.message')}</div>
                  <div style='margin-bottom: 10px'>${context.msg.t('mobile.orientation.switch.confirmation')}</div>
                  <div style='margin-bottom: 10px; font-size: 13px;'>
                    ${context.msg.t('mobile.orientation.switch.obs')}
                    <a target='_blank' href='${context.msg.t(
                      'mobile.orientation.switch.link'
                    )}' rel='noreferrer'>${context.msg.t('know.more')}</a>
                  </div>
                </div>`,
        level: 'warning',
        onConfirm: async () => {
          try {
            await addChange({
              type: 'RESET_MOBILE_LAYOUT',
              data: {
                breakpoint,
              },
            });
            executeChange();
          } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
          }
        },
      });
    } else {
      executeChange();
    }
  };

  return (
    <div className="MobileOrientationSwitch">
      <span className="MobileOrientationLabel">{context.msg.t('orientation')}</span>
      <div className="MobileOrientationSwitchButtons">
        <MobileOrientationItem
          icon="smartphone"
          changeOrientation={changeOrientation}
          breakpointView={breakpointView}
          orientation="MOBILE"
        />
        <MobileOrientationItem
          icon="tablet"
          changeOrientation={changeOrientation}
          breakpointView={breakpointView}
          orientation="MOBILE_HORIZONTAL"
        />
      </div>
    </div>
  );
}
