import React from 'react';
import PropTypes from 'prop-types';

import ContextEnhancer from "components/ContextEnhancer";
import UiMsg from "components/ui/UiMsg";
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import Icon from "components/ui/common/Icon";
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

class DashboardItemMenuTextAlign extends React.Component {

    static propTypes = {
        item: PropTypes.object,
    };

    static defaultProps = {
        item: {},
    };

    state = {
        open: false,
    };

    openOptions = (event) => {
        if(!this.state.open) this.props.onOpen(event);
        this.setState({open: !this.state.open});
    };

    forceClose() {
        this.setState({open: false});
    };

    align = async (align) => {
        try {
            await useDashboardPageCtx.getState().addChange({
                type: 'CHANGE_ITEM_ALIGN',
                data: {
                    id: this.props.item.id,
                    align,
                }
            });
        } catch (e) {
            console.error(e);
            UiMsg.ajaxError(null, e);
        }
    };

    render() {
        return (
            <li className={`bt-item-new-menu-inside ${this.state.open ? 'active' : ''}`} onClick={this.props.onClick}>
                <DashboardItemMenuTitle title={this.props.context.msg.t('align')}
                                        icon="icon-align-center" more={true}
                                        onClick={this.openOptions}/>

                {this.state.open &&
                <ul className="bng-dropdown container-dash-item-newmenu sub-container-dash-item-newmenu-align">
                    <li onClick={() => this.align('left')} className={`${this.props.item.align === 'left' ? 'selected' : ''}`}>
                        <Icon icon="format_align_left"/>
                        {this.props.context.msg.t('align.left')}
                    </li>
                    <li onClick={() => this.align('center')} className={`${this.props.item.align === 'center' ? 'selected' : ''}`}>
                        <Icon icon="format_align_center"/>
                        {this.props.context.msg.t('align.center')}
                    </li>
                </ul>
                }

            </li>
        )
    }
}

export default ContextEnhancer(DashboardItemMenuTextAlign);
