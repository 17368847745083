import React from 'react';

export default function HtmlPage({ panel }) {
  return (
    <iframe
      className={`cockpit-html-visualizer-${panel.id}`}
      width="100%"
      height="100%"
      style={{ border: 'none' }}
      ref={(ref) => {
        if (!ref) {
          return;
        }
        const ifrm = ref.contentWindow || ref.contentDocument || ref.contentDocument.document;
        if (ifrm) {
          ifrm.document.open();
          ifrm.document.write(panel.content);
          ifrm.document.close();
        }
      }}
    />
  );
}
