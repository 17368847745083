import React from 'react';
import PropTypes from 'prop-types';
import ContextEnhancer from 'components/ContextEnhancer';
import UiMsg from 'components/ui/UiMsg';
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import useDashboardPageCtx from 'bng/pages/dashboard/useDashboardPageCtx';

class DashboardItemMobileView extends React.Component {
  static propTypes = {
    item: PropTypes.object,
  };

  static defaultProps = {
    item: {},
  };

  state = {};

  toggleItemOnMobile = async () => {
    const { item } = this.props;
    try {
      await useDashboardPageCtx.getState().addChange({
        type: 'ITEM_AVAILABLE_ON_MOBILE',
        data: {
          id: item.id,
          enabled: !item.availableOnMobile,
        },
      });
    } catch (e) {
      console.error('Error on toggleItemOnMobile()', e);
      UiMsg.ajaxError(null, e);
    }
  };

  render() {
    return (
      <li onClick={this.toggleItemOnMobile}>
        <DashboardItemMenuTitle
          icon="remove_red_eye"
          title={this.props.context.msg.t(
            this.props.item.availableOnMobile ? 'hide.item.on.mobile' : 'show.item.on.mobile'
          )}
          more={false}
        />
      </li>
    );
  }
}

export default ContextEnhancer(DashboardItemMobileView);