'use strict';

import { Axios, buildUrl, findContextPath } from 'commonUtils';
import Api from 'components/Api';

const $ = jQuery;


class CockpitApi {

    findFilters = async (cockpitId, panelId) => {
        return await $.getJSON(`/spr/ui/cockpit/filters/${cockpitId}?${$.param({panelId})}`);
    };

    applyGlobalFilter = ({id, selectedMembers}) => {
        return Promise.resolve(
            $.postJSON(`/spr/ui/cockpit/filters/apply`, {id: id, members: selectedMembers.map(m => m.value)})
        );
    };

    getPermissions = async (id) => {
            const {data} = await Axios.get(`/spr/cockpits/${id}/permissions`);
            return data;
    };

    getProjectCockpitsPermissions = async (idList) => {
        const {data} = await Axios.post(`/spr/cockpits/permissions`, idList);
        return data;
    };

    addGroup = async (groupId, cockpitId) => {
        const {data} = await Axios.post(`/spr/cockpits/addGroup`, {groupId, cockpitId});
        return data;
    };

    savePermissions = async (id, permissions) => {
        const {data} = await Axios.post(`/spr/cockpits/${id}/permissions`, permissions);
        return data
    };

    findCockpits = async (projectName) => {
        const {data} = await Axios.get(`/spr/cockpits`, {
            params: {
                projectName
            }
        });
        return data;
    };

    findOne = async (id, processContents = false) => {
        return await $.getJSON(`/spr/cockpits/${id}?processContents=${processContents}`);
    };

    toggleFavorite = async (id) => {
        return await $.post(`/spr/cockpits/favorite/${id}`);
    };

    findBooks = async (cockpitId) => {
        return await $.getJSON(`/spr/cockpits/books/${cockpitId}`);
    };

    generateBook = async (book) => {
        return await $.postJSON(`/spr/cockpits/books/generate`, book);
    };

    deleteBook = async (bookId) => {
        return await $.deleteReq(`/spr/cockpits/books/${bookId}`);
    };

    getBookSchedule = async (bookId) => {
        return await $.getJSON(`/spr/cockpits/books/schedule/${bookId}`);
    };

    savePreferences = async (userId, preferences) => {
        return await Axios.post(`/spr/cockpits/${userId}/preferences`, preferences);
    };

    async manageCockpits() {
        await Axios.get("/spr/ui/cockpit/manageCockpits");
        window.location.replace(`${findContextPath()}/spr/bng/project/cockpits`);
    }

    async accessCockpit(redirect = true) {
        const {data} = await Axios.get("/spr/ui/cockpit/accessCockpit");
        if(redirect) {
            window.location.replace(`${findContextPath()}${data.uri}`);
        }
    }

    async accessDiscover(content) {
        if(!content && window.__BNG_COCKPIT_VIEW_CURRENT_PATH) {
            content = window.__BNG_COCKPIT_VIEW_CURRENT_PATH;
        }
        const {data} = await Axios.get("/spr/ui/cockpit/accessDiscover", {
            params: {
                content
            }
        });
        window.location.replace(`${findContextPath()}${data.uri}`);
    }

    async accessStore() {
        const {data} = await Axios.get("/spr/ui/cockpit/accessStore");
        window.location.replace(`${findContextPath()}${data.uri}`);
    }

    async accessMarketplace(projectName) {
        await Api.Bng.loadProjectInfo(projectName, true);
        window.location.replace(`${findContextPath()}/spr/bng/marketplace`);
    }

    saveIcon = async ({cockpitId, icon}) => {
        return await Axios.post(`/spr/cockpits/${cockpitId}/icon`, {icon});
    };

    mobileDashboardLayoutConfig = async (path) => {
        return await Axios.get(`/spr/ui/cockpit/mobileDashboard`, {
            params: {
                path
            }
        });
    };

    sendEmailToMaster = async (path) => {
        return await Axios.post(`/spr/ui/cockpit/sendEmail`, {}, {
            params: {
                path
            }
        });
    };

    getCockpitViews = async (cockpitId) => {
        const {data} = await Axios.get(`/spr/cockpits/${cockpitId}`);
        return data.panels;
    }

    getCockpitPath = async (cockpitId) => {
        const {data} = await Axios.get(`/spr/cockpits/findPath/${cockpitId}`);
        return data;
    }

    saveCockpit = async (cockpit) => {
        const {data} = await Axios.post(`/spr/cockpits`, cockpit);
        return data;
    }

    removeCockpit = async (cockpitId) => {
        const {data} = await Axios.delete(`/spr/cockpits/remove/${cockpitId}`);
        return data;
    }

    findCockpitUri() {
        return '/spr/bng/cockpit';
    }

    goToCockpit = (cockpitId = 0) => {
        const url = buildUrl(this.findCockpitUri(), {cockpitId});
        window.location.replace(url);
    }

    findCockpitsList = async (projectName, cockpitId) => {
        const {data} = await Axios.get(`/spr/cockpits/list`, {
            params: {
                projectName,
                cockpitId
            }
        });
        return data;
    }

    updateCockpitsOrder = async (newOrder) => {
        await Axios.post('/spr/cockpits/updateOrder', newOrder)
    }

    statelessFilterView = async (cockpitId, panelId, filters = []) => {
        const {data} = await Axios.post(`/spr/cockpits/${cockpitId}/filterView`, {
            panelId,
            filters
        });
        return data;
    };

}

export default CockpitApi;