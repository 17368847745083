import React from 'react';
import DashboardItemMenuTitle from './DashboardItemMenuTitle';
import UiMsg from 'components/ui/UiMsg';
import { MODALS } from 'components/ui/redux/Actions';
import DashboardIconForm from 'components/ui/dashboard/components/DashboardIconForm';
import useTranslation from 'components/hooks/useTranslation';

export default function DashboardItemMenuEditIcon({ item }) {
  const { t } = useTranslation();
  return (
    <li
      onClick={async () => {
        try {
          window.ReduxStore.dispatch(MODALS.open(DashboardIconForm, { item }));
        } catch (e) {
          console.error(e);
          UiMsg.ajaxError(null, e);
        }
      }}
    >
      <DashboardItemMenuTitle title={t('edit.content')} icon="fa-pencil-square" more={false} />
    </li>
  );
}
